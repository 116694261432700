import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../common'
import { Button } from '@salesforce/design-system-react'

import { PAGE_SIZE } from '../../../constants'

import { useDispatch, useSelector } from 'react-redux'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm,
  applyValidation,
  getFormValidStatus
} from '../../../helpers/form'

import {
  fetchJobCommentsListById,
  resetUnholdJobRequestResult,
  getUnholdJobRequestResult,
  requestUnholdJob
} from '../../../redux/slices/job'

import jobHoldReleaseForm from './jobHoldReleaseForm'

import useAlert from '../../../hooks/useAlert'

import { createSuccessAlert, createErrorAlert } from '../../../helpers/alert'
import { getFieldValidator } from '../../../redux/slices/global'

const JobHoldReleaseModal = ({ isOpen, modalTitle, jobId, onClose }) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState(jobHoldReleaseForm)
  const dispatch = useDispatch()
  const fireAlert = useAlert()
  const validator = useSelector(getFieldValidator)

  const unholdJobResult = useSelector(getUnholdJobRequestResult)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => updateForm(prev, name, value))
  })

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSaveHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)

      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData)
        dispatch(requestUnholdJob({ jobId: jobId, data }))
        onClose()
        setFormData(prev => clearForm(prev))
      }
    }
  }, [formData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    if (unholdJobResult.statusCode) {
      if ([200, 201].includes(unholdJobResult.statusCode)) {
        fireAlert(
          createSuccessAlert(
            'Your request to release hold has been successfuly sent.',
            1500
          )
        )
      } else {
        fireAlert(createErrorAlert(unholdJobResult.error, 1500))
      }
      dispatch(resetUnholdJobRequestResult())
      dispatch(
        fetchJobCommentsListById({ jobId: jobId, page: 1, size: PAGE_SIZE })
      )
    }
  }, [unholdJobResult])

  useEffect(() => {
    setFormData(prev => applyValidation(prev, validator?.postComment))
  }, [validator])

  useEffect(() => {
    setFormData(prev => {
      const clearedForm = clearForm(prev)
      return clearedForm
    })
  }, [isOpen])

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Submit Release Hold Request'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>
        <form ref={formRef} onSubmit={onSaveHandler}>
          {formDisplay}
        </form>
      </div>
    </StandardModal>
  )
}

JobHoldReleaseModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  jobId: PropTypes.string,
  onClose: PropTypes.func
}

export default memo(JobHoldReleaseModal)
