import React from 'react'
import {
  LoginSingleGridContainer,
  LoginGridItem,
  LoginContainer,
  LoginImageContainer
} from '../LoginPage/StyledLoginItems'
import { Image } from '../../components'
import { PasswordChangedSuccessPanel } from '../../containers'

const PasswordChangedSuccessPage = () => {
  return (
    <>
      <LoginSingleGridContainer>
        <LoginGridItem>
          <LoginContainer>
            <LoginImageContainer>
              <Image
                width='100%'
                height='100%'
                src='https://login.salesforce.com/img/logo214.svg'
                alt='Login'
                objectFit='contain'
              />
            </LoginImageContainer>
            <PasswordChangedSuccessPanel />
          </LoginContainer>
        </LoginGridItem>
      </LoginSingleGridContainer>
    </>
  )
}

export default PasswordChangedSuccessPage
