import {
  buildCurrencyColumn,
  buildDateTimeColumn,
  buildLinkColumn,
  buildSimpleColumn
} from '../../helpers/dataTable'

export default ({ sortColumn, sortDirectionMemo, onFileClick }) => [
  buildSimpleColumn('Invoice Number', 'invoiceNo', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildDateTimeColumn(
    'Invoice Date',
    'invoiceDate',
    false,
    { withTime: false },
    { sortable: true, sortColumn, sortDirectionMemo }
  ),
  buildCurrencyColumn(
    'Amount',
    'amount',
    false,
    { decimalPoint: 2, thousandSeparator: true },
    {
      sortable: true,
      sortColumn,
      sortDirectionMemo
    }
  ),
  buildSimpleColumn('Client Ref No', 'clientRefNo', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn('Payment Status', 'paymentStatus', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn('Job #', 'jobNo', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildDateTimeColumn(
    'Due Date',
    'dueDate',
    false,
    { withTime: false },
    { sortable: true, sortColumn, sortDirectionMemo }
  ),
  buildLinkColumn(
    'File',
    'invoiceDocumentId',
    false,
    { baseUrl: null, displayAsIcon: true, onClick: onFileClick },
    { sortable: true, sortColumn, sortDirectionMemo }
  )
]
