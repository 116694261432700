import React from 'react'
import PropTypes from 'prop-types'

import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getLoginStatus } from '../../redux/slices/auth'

const PublicOnlyRoute = ({ children }) => {
  const location = useLocation()
  const isLoggedIn = useSelector(getLoginStatus)

  const fromPage = location.state?.from

  return !isLoggedIn ? (
    children
  ) : (
    <Navigate to={fromPage ?? '/'} replace />
  )
}

PublicOnlyRoute.propTypes = {
  children: PropTypes.node
}

export default PublicOnlyRoute
