import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  StyledLink,
  StyledList,
  StyledMenu,
  StyledListItem
} from './StyledMenuComponents'

import { Button, Icon } from '@salesforce/design-system-react'

import { additionalNavItems, navigationItems } from '../../../routes'
import { Divider } from '../../common'
import { useDispatch, useSelector } from 'react-redux'
import { getUserRole, logout } from '../../../redux/slices/auth'
import { ROLE_ADMIN } from '../../../constants'

const SideMenu = ({
  show,
  newQuoteClickedHandler,
  newJobClickedHandler,
  onSideMenuItemClickedHandler
}) => {
  const dispatch = useDispatch()

  const userRole = useSelector(getUserRole)

  const onLogoutClickedHandler = useCallback(() => {
    dispatch(logout())
  }, [])

  return (
    <StyledMenu className={show ? 'open' : null}>
      <StyledList>
        {navigationItems.map(navItem => (
          <StyledLink
            key={navItem.pathname}
            to={navItem.pathname}
            onClick={onSideMenuItemClickedHandler}
          >
            <li>{navItem.label}</li>
          </StyledLink>
        ))}
        <StyledListItem className='button-list-item'>
          <Button
            className={'list-item-action'}
            onClick={newQuoteClickedHandler}
          >
            New Quote
          </Button>
        </StyledListItem>
        <StyledListItem className='button-list-item'>
          <Button className={'list-item-action'} onClick={newJobClickedHandler}>
            New Job
          </Button>
        </StyledListItem>
        <StyledListItem className='divider'>
          <Divider />
        </StyledListItem>
        {userRole === ROLE_ADMIN
          ? additionalNavItems.map(navItem => (
              <StyledLink
                key={navItem.pathname}
                to={navItem.pathname}
                onClick={onSideMenuItemClickedHandler}
              >
                <li>
                  <Icon
                    className='slds-m-right_small'
                    category={navItem.iconCategory}
                    name={navItem.iconName}
                    size='small'
                  />
                  {navItem.label}
                </li>
              </StyledLink>
            ))
          : []}
        {userRole === ROLE_ADMIN ? (
          <StyledListItem className='divider'>
            <Divider />
          </StyledListItem>
        ) : null}
        <StyledLink to={''} onClick={onLogoutClickedHandler}>
          <li>Logout</li>
        </StyledLink>
      </StyledList>
    </StyledMenu>
  )
}

SideMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  newQuoteClickedHandler: PropTypes.func,
  newJobClickedHandler: PropTypes.func,
  onSideMenuItemClickedHandler: PropTypes.func
}

export default SideMenu
