import React from 'react'
import PropTypes from 'prop-types'
import { Button, CardFilter } from '@salesforce/design-system-react'

import {
  SearchButtonWrapper,
  SearchBoxWrapper,
  SearchSectionWrapper
} from './StyledCardSearchHeader'

const CardSearchHeader = ({
  headerText,
  isEmpty,
  onFilterChange,
  onSearch,
  icon
}) => {
  return (
    <div className='slds-grid slds-wrap' style={{ width: '100%' }}>
      <div className='slds-col slds-size_1-of-1 slds-medium-size_1-of-2 slds-media'>
        <div>{icon}</div>
        <div className='slds-p-left_small slds-text-align_left slds-media__body slds-text-heading_small'>
          {headerText}
        </div>
      </div>
      <div className='slds-text-align_right slds-col slds-size_1-of-1 slds-medium-size_1-of-2'>
        <SearchSectionWrapper>
          <SearchBoxWrapper>
            {!isEmpty ? (
              <CardFilter className='searchBox' onChange={onFilterChange} />
            ) : null}
          </SearchBoxWrapper>
          <SearchButtonWrapper>
            <Button label='Search Items' onClick={onSearch} />
          </SearchButtonWrapper>
        </SearchSectionWrapper>
      </div>
    </div>
  )
}

CardSearchHeader.propTypes = {
  headerText: PropTypes.string,
  isEmpty: PropTypes.bool,
  isFiltering: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onSearch: PropTypes.func,
  icon: PropTypes.node
}

export default CardSearchHeader
