import { performGetRequest } from '../helpers/service'

export const getBulletinList = async (page, size) => {
  const response = await performGetRequest('home/bulletins', { page, size })

  return response
}

export const getJobList = async (jobStatus, page, size) => {
  const response = await performGetRequest('home/jobs', {
    status: jobStatus,
    page,
    size
  })

  return response
}

export const getTaskList = async (taskStatus, page, size) => {
  const response = await performGetRequest('home/outstanding-tasks', {
    status: taskStatus,
    page,
    size
  })

  return response
}
