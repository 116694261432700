import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  CreateQuoteForm,
  CreateQuoteHeader,
  CreateQuoteSummary,
  StandardFormModal
} from '../../components'
import { CustomJobQuoteModal } from '../../components/CreateQuotePanel/CustomJobQuoteModal'

import {
  applyDataToForm,
  extractForm,
  getFormValidStatus,
  updateForm,
  clearForm,
  applyValidation
} from '../../helpers/form'

import {
  getQuoteForm,
  getExistingQuoteForm,
  customJobForm,
  declineReasonForm
} from './createQuoteForm'

import styled from 'styled-components'
import { isEmpty, toLower } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'
import {
  fetchJobType,
  getJobTypeData,
  fetchSecurityTypeByJobTypeId,
  fetchQuoteDataByJobId,
  getSecurityTypeData,
  getQuoteDetail,
  resetQuotePageState,
  calculateQuotePrice,
  createCustomRequestQuote,
  getCreateCustomQuoteResult,
  declineQuote,
  getDeclineQuoteResult,
  holdQuote,
  getHoldQuoteResult
} from '../../redux/slices/quote'
import { Spinner } from '@salesforce/design-system-react'

import useAlert from '../../hooks/useAlert'
import { createSuccessAlert, createErrorAlert } from '../../helpers/alert'
import {
  getFieldValidator,
  getNewQuotePageTitle,
  getPortalName
} from '../../redux/slices/global'

import { Divider } from '../../components'
import { extractGooglePlaceInfo } from '../../helpers/googleMap'
import { setAppTitle } from '../../helpers/appState'

const ModalWrapper = styled(StandardFormModal)`
  max-width: 40rem !important;
`
const ErrorText = styled.div`
  color: red;
`
const CardWrapper = styled.div`
  .cardBox {
    margin: auto;
  }
`

const ClickableText = styled.a`
  text-decoration: underline;
  color: red;
`

const CreateQuotePanel = () => {
  const { id } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const fireAlert = useAlert()

  const googleMapAutoCompleteRef = useRef(null)

  const portalName = useSelector(getPortalName)
  const newQuotePageTitle = useSelector(getNewQuotePageTitle)

  const jobType = useSelector(getJobTypeData)
  const securityType = useSelector(getSecurityTypeData)
  const createCustomQuoteResult = useSelector(getCreateCustomQuoteResult)

  const quoteDetail = useSelector(getQuoteDetail)
  const declineQuoteResult = useSelector(getDeclineQuoteResult)
  const holdQuoteResult = useSelector(getHoldQuoteResult)

  const validator = useSelector(getFieldValidator)

  const [formData, setFormData] = useState(getQuoteForm())
  const [googlePlaceData, setGooglePlaceData] = useState(null)

  const [customJobModalOpen, setCustomJobModalOpen] = useState(false)
  const [description, setDescription] = useState(customJobForm)
  const [declineReasonFormData, setDeclineReasonFormData] =
    useState(declineReasonForm)

  const [declineModalOpen, setDeclineModalOpen] = useState(false)

  const [quoteError, setQuoteError] = useState(false)
  const [isSkipTraceJob, setIsSkipTraceJob] = useState(false)
  const [isAssetManagementJob, setIsAssetManagementJob] = useState(false)

  const onFormInputChangedHandler = useCallback(
    (value, name) => {
      setFormData(prev => updateForm(prev, name, value))

      if (name === 'jobType') {
        let jobType = value?.selection[0]
        let jobTypeName = toLower(jobType?.label)

        dispatch(fetchSecurityTypeByJobTypeId(jobType?.id))
        setIsSkipTraceJob(jobTypeName === 'skip trace')
        setIsAssetManagementJob(jobTypeName === 'asset management')
      }

      if (name === 'address') {
        setGooglePlaceData(null)
      }
    },
    [formData]
  )

  const onGetQuoteClickedHandler = useCallback(() => {
    const checkValidForm = getFormValidStatus(formData)

    if (checkValidForm.formValid) {
      const data = extractForm(formData)
      const formedData = {
        ...data,
        ...googlePlaceData,
        addressString: data.address,
        jobTypeId: data.jobType?.[0]?.id,
        flatCostPricing: data.jobType?.[0]?.flatCostPricing,
        securityTypeId: data.securityType?.[0]?.id,
        clientRef: data.clientRef
      }

      dispatch(calculateQuotePrice(formedData))
    } else {
      setFormData(checkValidForm.validatedForm)
    }
  }, [formData, googlePlaceData])

  const onAcceptQuoteClickedHandler = useCallback(() => {
    navigate('/quotes/submit', { state: { quote: quoteDetail.record } })
  }, [quoteDetail.record])

  const onDeclineQuoteClickedHandler = useCallback(() => {
    setDeclineModalOpen(true)
  }, [])

  const onHoldQuoteClickedHandler = useCallback(() => {
    dispatch(holdQuote({ quoteId: id ? id : quoteDetail?.record?.caseId }))
  }, [id, quoteDetail])

  const onCustomJobClickedHandler = useCallback(
    e => {
      e.preventDefault()

      const updatedText = `Job Type: ${
        formData.jobType?.value?.[0]?.label ?? formData.jobType?.value ?? ''
      }
Security Type: ${
        formData.securityType?.value?.[0]?.label ??
        formData.securityType?.value ??
        ''
      }
Client Ref #: ${formData.clientRef?.value ?? ''}
Address: ${formData.address?.value ?? formData.address?.value ?? ''}
Comments: `

      setDescription(prev => updateForm(prev, 'description', updatedText))
      setCustomJobModalOpen(true)
    },
    [formData]
  )

  const onCustomJobFormInputChangedHandler = useCallback((value, name) => {
    setDescription(prev => updateForm(prev, name, value))
  }, [])

  const onCustomJobFormSubmittedHandler = useCallback(data => {
    dispatch(createCustomRequestQuote(data))
    setCustomJobModalOpen(false)
  }, [])

  const onCustomJobFormClosedHandler = useCallback(() => {
    setCustomJobModalOpen(false)
  }, [])

  const onDeclineQuoteFormSubmittedHandler = useCallback(
    data => {
      dispatch(
        declineQuote({ data, quoteId: id ? id : quoteDetail?.record?.caseId })
      )
      onDeclineQuoteFormClosedHandler()
    },
    [quoteDetail.record, id]
  )

  const onDeclineQuoteFormClosedHandler = useCallback(() => {
    setDeclineModalOpen(false)
  }, [])

  const onGoogleMapLoad = useCallback(autocomplete => {
    googleMapAutoCompleteRef.current = autocomplete
  }, [])

  const onPlaceChanged = useCallback(() => {
    const result = googleMapAutoCompleteRef.current?.getPlace()
    const locationText = result?.formatted_address ?? result?.name

    const googlePlaceData = extractGooglePlaceInfo(result)

    setGooglePlaceData(googlePlaceData)

    setFormData(prev => updateForm(prev, 'address', locationText))
  }, [googleMapAutoCompleteRef.current])

  useEffect(() => {
    setFormData(prev => {
      return {
        ...prev,
        securityType: {
          ...prev.securityType,
          inputProps: {
            ...prev.securityType.inputProps,
            options: securityType.records
          },
          value: []
        }
      }
    })
  }, [securityType.records])

  useEffect(() => {
    if (!isEmpty(jobType.records)) {
      setFormData(
        applyValidation(getQuoteForm(jobType.records, []), validator?.case)
      )
    }
  }, [jobType.records])

  useEffect(() => {
    if (createCustomQuoteResult.statusCode !== null) {
      if (createCustomQuoteResult.statusCode === 201) {
        fireAlert(
          createSuccessAlert(
            'Thanks for submitting your Custom Request, One of our team members will be in contact soon.',
            3000
          )
        )
      } else {
        fireAlert(createErrorAlert(createCustomQuoteResult.error.message, 3000))
      }
      setDescription(prev => clearForm(prev))
      setFormData(prev => clearForm(prev))
      dispatch(resetQuotePageState())
      navigate('/')
    }
  }, [createCustomQuoteResult])

  useEffect(() => {
    if (quoteDetail?.record !== null) {
      setAppTitle(portalName, `Quote ${quoteDetail.record?.quoteNo ?? ''}`)
      setFormData(() => {
        const lockedForm = getExistingQuoteForm()
        const appliedForm = applyDataToForm(lockedForm, quoteDetail.record)

        return appliedForm
      })
    } else if (quoteDetail.statusCode === 404) {
      navigate('/notFound')
    } else if (quoteDetail.statusCode && quoteDetail.statusCode !== 200) {
      setFormData(prev => {
        const updatedForm = Object.entries(prev).reduce((updated, form) => {
          const [formKey, formItem] = form

          const updatedFormData = {
            ...formItem,
            inputProps: { ...formItem.inputProps, disabled: true }
          }

          return { ...updated, [formKey]: updatedFormData }
        }, {})

        return updatedForm
      })
    }
  }, [quoteDetail])

  useEffect(() => {
    if (id) {
      dispatch(fetchQuoteDataByJobId(id))
      setFormData(getExistingQuoteForm())
    } else {
      setAppTitle(portalName, 'Get Quote')
      dispatch(fetchJobType())
      setFormData(applyValidation(getQuoteForm(), validator?.case))
    }
  }, [id, validator])

  useEffect(() => {
    return () => {
      dispatch(resetQuotePageState())
      googleMapAutoCompleteRef.current = null
    }
  }, [])

  useEffect(() => {
    if (declineQuoteResult.statusCode !== null) {
      if (declineQuoteResult.statusCode === 200) {
        fireAlert(createSuccessAlert('Quote status has been rejected.', 3000))
        dispatch(resetQuotePageState())
        navigate('/')
      } else {
        fireAlert(createErrorAlert(declineQuoteResult.error.message, 3000))
      }
    }
  }, [declineQuoteResult.statusCode, declineQuoteResult.error])

  useEffect(() => {
    if (holdQuoteResult.statusCode !== null) {
      if (holdQuoteResult.statusCode === 200) {
        fireAlert(createSuccessAlert('Quote has been holded.', 3000))
        dispatch(resetQuotePageState())
        navigate('/')
      } else {
        fireAlert(createErrorAlert(holdQuoteResult.error.message, 3000))
      }
    }
  }, [holdQuoteResult.statusCode, holdQuoteResult.error])

  useEffect(() => {
    if (quoteDetail.statusCode !== null) {
      if (quoteDetail.statusCode !== 200) {
        setQuoteError(true)
      } else {
        setQuoteError(false)
      }
    }
  }, [quoteDetail.statusCode])

  useEffect(() => {
    setDeclineReasonFormData(prev => clearForm(prev))
  }, [declineModalOpen])

  useEffect(() => {
    setDescription(prev => applyValidation(prev, validator?.case))
    setDeclineReasonFormData(prev => applyValidation(prev, validator?.case))
  }, [validator])

  return (
    <>
      <CustomJobQuoteModal
        isOpen={customJobModalOpen}
        formData={description}
        onInputChangedHandler={onCustomJobFormInputChangedHandler}
        onSubmit={onCustomJobFormSubmittedHandler}
        onCancel={onCustomJobFormClosedHandler}
        quoteData={formData}
      />
      <ModalWrapper
        modalTitle={'Decline Quote'}
        modalOpen={declineModalOpen}
        onClose={onDeclineQuoteFormClosedHandler}
        onSaveButtonClicked={onDeclineQuoteFormSubmittedHandler}
        formData={declineReasonFormData}
        size={'small'}
      />
      <CreateQuoteHeader headingText={newQuotePageTitle} />

      <CreateQuoteForm
        form={formData ?? {}}
        onFormInputChanged={onFormInputChangedHandler}
        onGetQuoteClicked={onGetQuoteClickedHandler}
        onGetCustomRequestClicked={onCustomJobClickedHandler}
        existingQuote={quoteDetail.record}
        lockAddressInput={
          quoteDetail.statusCode && quoteDetail.statusCode !== 200
        }
        onGoogleMapLoad={onGoogleMapLoad}
        onPlaceChanged={onPlaceChanged}
        getQuoteSuccess={!!quoteDetail.record}
        getQuoteError={
          !!quoteDetail.error ||
          (quoteDetail.statusCode && quoteDetail.statusCode !== 200)
        }
        getQuoteLoading={quoteDetail.loading}
        isSkipTraceJob={isSkipTraceJob}
        isAssetManagementJob={isAssetManagementJob}
      />

      {quoteDetail.record ? (
        <CreateQuoteSummary
          quoteNumber={quoteDetail.record.quoteNo ?? ''}
          quoteSuccessCharge={quoteDetail.record.chargeIfSuccessful}
          quoteDeclinedCharge={quoteDetail.record.chargeIfUnsuccessful}
          onAccepted={onAcceptQuoteClickedHandler}
          onDeclined={onDeclineQuoteClickedHandler}
          onHolded={onHoldQuoteClickedHandler}
          isExpired={quoteDetail?.record?.isExpired}
          isError={quoteDetail?.error}
        />
      ) : null}

      {quoteError ? (
        <CardWrapper>
          <div className='slds-p-around_small slds-medium-size_1-of-2 cardBox'>
            <div className='slds-text-heading_large slds-p-around_x-small'>
              Quote Summary
            </div>
            <Divider />
            <ErrorText>
              Sorry, we weren&apos;t able to find valid pricing for this Quote
              Request.
              <ClickableText href='' onClick={onCustomJobClickedHandler}>
                {' '}
                Please create a custom request{' '}
              </ClickableText>
              and we&apos;ll get back to you shortly.
            </ErrorText>
          </div>
        </CardWrapper>
      ) : null}

      {quoteDetail.loading ? <Spinner /> : null}
      {declineQuoteResult?.loading ? <Spinner /> : null}
    </>
  )
}

export default CreateQuotePanel
