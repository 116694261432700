import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { getFormDisplay, updateForm, clearForm } from '../../helpers/form'
import { Checkbox, Button, Card } from '@salesforce/design-system-react'

import myAccountForm from './myAccountForm'

const CardWrapper = styled.div`
  padding-top: 2em;
  .cardBox {
    margin: auto;
  }
`

const Wrapper = styled.div`
  padding: 1em;
  & .slds-form-element {
    padding-top: 20px;
  }
  .input-header {
    padding-top: 20px;
  }
`

const SuccessText = styled.div`
  padding-top: 1rem;
  color: green;
  text-align: center;
  margin: auto;
`

const userData = {
  firstName: 'Test',
  lastName: 'TestLastName',
  contactNumber: '12345676',
  emailReceived: true
}

const MyAccountContent = () => {
  const [formData, setFormData] = useState(myAccountForm)
  const [isChecked, setIsChecked] = useState(userData.emailReceived)
  const [isSuccess, setIsSuccess] = useState(false)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => {
      return updateForm(prev, name, value)
    })
  }, [])

  const onSaveHandler = useCallback(async () => {
    await setCheckboxData()

    setIsSuccess(!isSuccess)
  }, [formData])

  const setCheckboxData = async () => {
    setFormData(prev => {
      return updateForm(prev, 'emailReceived', isChecked)
    })
  }

  const onChangeCheckboxHandler = () => {
    setIsChecked(!isChecked)
    setFormData(prev => {
      return updateForm(prev, 'emailReceived', isChecked)
    })
  }

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    setFormData(prev => {
      updateForm(prev, 'lastName', userData?.lastName)
      updateForm(prev, 'contactNumber', userData?.contactNumber)
      return updateForm(prev, 'firstName', userData?.firstName)
    })
    return () => {
      updateForm(prev => clearForm(prev))
    }
  }, [userData])

  return (
    <CardWrapper>
      <div className={'slds-p-around_small slds-large-size_1-of-2 cardBox'}>
        <Card heading={''}>
          <div className='slds-p-vertical_xx-small slds-text-align_center slds-text-heading_medium'>
            My Account
          </div>
          <Wrapper>
            <div className='slds-p-around_x-small slds-grid slds-wrap '>
              <div className='slds-col slds-size_1-of-1'>{formDisplay}</div>
              <div className='slds-text-align_center slds-col slds-size_1-of-1'>
                <Checkbox
                  assistiveText={{
                    label:
                      'Receive Email Updates when New Comments added to Jobs'
                  }}
                  name={'emailReceived'}
                  labels={{
                    label:
                      'Receive Email Updates when New Comments added to Jobs'
                  }}
                  checked={isChecked}
                  onChange={onChangeCheckboxHandler}
                />
                <Button
                  className={'slds-m-top_x-large'}
                  variant={'brand'}
                  label={'SAVE'}
                  onClick={onSaveHandler}
                />
              </div>
              <SuccessText>
                {isSuccess ? 'Your details have been updated' : null}
              </SuccessText>
            </div>
          </Wrapper>
        </Card>
      </div>
    </CardWrapper>
  )
}

export default MyAccountContent
