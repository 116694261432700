import {
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_TEXT
} from '../../../constants'

export const userForm = {
  firstName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'First Name',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  lastName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Last Name',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  email: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      label: 'Email',
      type: INPUT_TYPE_EMAIL,
      disabled: true
    },
    value: '',
    touched: false
  },
  phone: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Phone',
      type: INPUT_TYPE_TEXT
    },
    value: '',
    touched: false
  },
  status: {
    inputType: INPUT_TYPE_DROPDOWN,
    inputProps: {
      label: 'Status',
      variant: 'readonly',
      multiple: false,
      required: true
    },
    options: [
      { label: 'Active', id: 'Active' },
      { label: 'Inactive', id: 'Inactive' }
    ],
    value: [{ label: 'Active', id: 'Active' }],
    touched: false
  }
}
