import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  BulletinList,
  JobTables,
  OutstandingTaskTable,
  WelcomeHeader
} from '../../components'
import { HomeContentWrapper } from '../../pages/HomePage/StyledHomePageItems'
import {
  getHomePageSubTitle,
  getHomePageTitle,
  getPortalName
} from '../../redux/slices/global'
import {
  fetchActiveJobs,
  fetchBulletins,
  fetchClosedJobs,
  fetchOutstandingTasks,
  fetchSuspendedJobs,
  fetchUpgradeJobs,
  getActiveJobsData,
  getBulletinRecords,
  getClosedJobsData,
  getOutstandingTasksData,
  getSuspendedJobsData,
  getUpgradeJobsData,
  hideBulletinById,
  resetHomePageState
} from '../../redux/slices/home'

import { setAppTitle } from '../../helpers/appState'

const HomePageContent = () => {
  const dispatch = useDispatch()

  const portalName = useSelector(getPortalName)
  const bulletins = useSelector(getBulletinRecords)
  const activeJobsData = useSelector(getActiveJobsData)
  const suspendedJobsData = useSelector(getSuspendedJobsData)
  const upgradeJobsData = useSelector(getUpgradeJobsData)
  const closedJobsData = useSelector(getClosedJobsData)
  const outstandingTasksData = useSelector(getOutstandingTasksData)

  const homePageTitle = useSelector(getHomePageTitle)
  const homePageSubTitle = useSelector(getHomePageSubTitle)

  const [tabIndex, setTabIndex] = useState(0)

  const onBulletinClosedHandler = useCallback(bulletinId => {
    dispatch(hideBulletinById({ bulletinId }))
  }, [])

  const onTabChangedHandler = useCallback(tabIndex => {
    setTabIndex(tabIndex)
  }, [])

  const onLoadMoreActiveJobsHandler = useCallback(() => {
    if (!activeJobsData.loading)
      dispatch(fetchActiveJobs({ page: activeJobsData.page + 1 }))
  }, [activeJobsData.loading, activeJobsData.page])

  const onLoadMoreSuspendedJobsHandler = useCallback(() => {
    if (!suspendedJobsData.loading)
      dispatch(fetchSuspendedJobs({ page: suspendedJobsData.page + 1 }))
  }, [suspendedJobsData.loading, suspendedJobsData.page])

  const onLoadMoreUpgradeJobsHandler = useCallback(() => {
    if (!upgradeJobsData.loading)
      dispatch(fetchUpgradeJobs({ page: upgradeJobsData.page + 1 }))
  }, [upgradeJobsData.loading, upgradeJobsData.page])

  const onLoadMoreClosedJobsHandler = useCallback(() => {
    if (!closedJobsData.loading)
      dispatch(fetchClosedJobs({ page: closedJobsData.page + 1 }))
  }, [closedJobsData.loading, closedJobsData.page])

  const onLoadMoreOutstandingTasksHandler = useCallback(() => {
    if (!outstandingTasksData.loading) {
      dispatch(fetchOutstandingTasks({ page: outstandingTasksData.page + 1 }))
    }
  }, [outstandingTasksData.loading, outstandingTasksData.page])

  useEffect(() => {
    if (!bulletins) dispatch(fetchBulletins())
  }, [bulletins])

  useEffect(() => {
    setAppTitle(portalName, 'Home')
    dispatch(fetchOutstandingTasks({ page: 1 }))
    dispatch(fetchActiveJobs({ page: 1 }))
    dispatch(fetchSuspendedJobs({ page: 1 }))
    dispatch(fetchUpgradeJobs({ page: 1 }))
    dispatch(fetchClosedJobs({ page: 1 }))
    return () => {
      dispatch(resetHomePageState())
    }
  }, [])

  return (
    <>
      <WelcomeHeader
        headingText={homePageTitle}
        contentText={homePageSubTitle}
      />
      <HomeContentWrapper>
        <BulletinList
          bulletins={bulletins ?? []}
          onBulletinClosedHandler={onBulletinClosedHandler}
        />
      </HomeContentWrapper>
      <HomeContentWrapper>
        <JobTables
          currentTabIndex={tabIndex}
          onTabChanged={onTabChangedHandler}
          activeJobs={activeJobsData.records ?? []}
          totalActiveJobs={activeJobsData.total}
          suspendedJobs={suspendedJobsData.records ?? []}
          totalSuspendedJobs={suspendedJobsData.total}
          upgradeJobs={upgradeJobsData.records ?? []}
          totalUpgradeJobs={upgradeJobsData.total}
          closedJobs={closedJobsData.records ?? []}
          totalClosedJobs={closedJobsData.total}
          onLoadMoreActiveJobs={onLoadMoreActiveJobsHandler}
          onLoadMoreSuspendedJobs={onLoadMoreSuspendedJobsHandler}
          onLoadMoreUpgradeJobs={onLoadMoreUpgradeJobsHandler}
          onLoadMoreClosedJobs={onLoadMoreClosedJobsHandler}
        />
      </HomeContentWrapper>
      <HomeContentWrapper>
        <OutstandingTaskTable
          items={outstandingTasksData.records ?? []}
          totalOutstandingTasks={outstandingTasksData.total}
          onLoadMore={onLoadMoreOutstandingTasksHandler}
        />
      </HomeContentWrapper>
    </>
  )
}

export default HomePageContent
