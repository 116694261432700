import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import CommentInput from '../../../CommentPanel/CommentInput/CommentInput'
import CommentHistory from '../../../CommentPanel/CommentHistory/CommentHistory'
import { useSelector } from 'react-redux'
import { getFieldValidator } from '../../../../redux/slices/global'

const JobRecordComments = ({
  comments,
  onCommentSubmitted,
  isMobile,
  commentTotals,
  onLoadMoreCommentsClicked
}) => {
  const [comment, setComment] = useState('')

  const commentValidator = useSelector(getFieldValidator)

  const onCommentChangedHandler = useCallback(value => {
    setComment(value)
  }, [])

  const onSubmittedHandler = useCallback(
    e => {
      e.preventDefault()
      onCommentSubmitted(comment)
      setComment('')
    },
    [comment]
  )

  return (
    <>
      <CommentInput
        isMobile={isMobile}
        inputName='comment'
        value={comment}
        placeholder='Add Comment ...'
        onInputChanged={onCommentChangedHandler}
        onCommentSubmitted={onSubmittedHandler}
        maxLength={commentValidator?.postComment?.comment?.length ?? null}
      />
      <CommentHistory
        comments={comments}
        commentTotals={commentTotals}
        onLoadMoreClicked={onLoadMoreCommentsClicked}
      />
    </>
  )
}

JobRecordComments.propTypes = {
  isMobile: PropTypes.bool,
  commentTotals: PropTypes.number,
  comments: PropTypes.array,
  onCommentSubmitted: PropTypes.func,
  onLoadMoreCommentsClicked: PropTypes.func
}

export default JobRecordComments
