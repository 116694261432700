import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Combobox } from '@salesforce/design-system-react'
import { isArray } from 'lodash'
import styled from 'styled-components'

const getSelectionLabel = values =>
  isArray(values) ? values.map(item => item.label ?? '').toString() : ''

  const StyledComboBox = styled(Combobox)`
  input.slds-combobox__input {
    background-color: ${props => (props?.disabled ? '#f3f3f3' : 'inherit')};
    pointer-events: ${props => (props?.disabled ? 'none' : '')};
    cursor: ${props => (props?.disabled ? 'not-allowed' : '')};
  }
`

const StandardCombobox = ({
  // onChange,
  name,
  onSelect,
  label,
  placeholder,
  multiple,
  options,
  value,
  loading,
  readOnly,
  required,
  ...props
}) => {
  const [textDisplay, setTextDisplay] = useState('')

  const onSelectedHandler = useCallback((event, selections) => {
    const values = selections?.selection ?? []
    setTextDisplay(getSelectionLabel(values))
    onSelect(selections ?? {}, name, { ...event })
  }, [])

  useEffect(() => {
    setTextDisplay(getSelectionLabel(value))
    return () => {
      setTextDisplay('')
    }
  }, [value])

  return (
    <StyledComboBox
      id='combobox-unique-id'
      events={{
        // onChange: onChange,
        onRequestRemoveSelectedOption: onSelectedHandler,
        onSelect: onSelectedHandler,
        // onBlur: onSelectedHandler
      }}
      labels={{
        label,
        placeholder
      }}
      multiple={multiple}
      options={options}
      selection={value}
      value={textDisplay}
      required={required}
      variant={readOnly ? 'readonly' : 'inline-listbox'}
      hasMenuSpinner={loading}
      {...props}
    />
  )
}

StandardCombobox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  searchValue: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool
}

export default StandardCombobox
