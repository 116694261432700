import { last } from 'lodash'

export const buildFilePayload = async (file, entityId, options) => {
  if (!file) return null

  try {
    const { name } = file

    let fileName = name

    if (options?.fileNamePostfix) {
      const fileNameChunk = fileName.split('.')
      const fileExtension = last(fileNameChunk)

      fileName = fileName.replace(
        `.${fileExtension}`,
        `${options.fileNamePostfix}.${fileExtension}`
      )
    }
    const fileContent = await getBase64FileContent(file)

    const buildedFile = {
      fileName,
      originalName: name,
      fileContent,
      file,
      sfEntityId: entityId
    }
    return buildedFile
  } catch (error) {
    console.error('Convert file to Base64 Error with message:', error)
    return null
  }
}

export const getBase64FileContent = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
