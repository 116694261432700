import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { setAppTitle } from '../../helpers/appState'
import {
  LoginContentContainer,
  LoginHeading
} from '../../pages/LoginPage/StyledLoginItems'
import { getPortalName } from '../../redux/slices/global'
// import { Button } from '@salesforce/design-system-react'
import { ButtonWrapper } from './StyledPasswordChangeedSuccessPanel'

const PasswordChangedSuccessPanel = () => {
  const navigate = useNavigate()
  const portalName = useSelector(getPortalName)

  useEffect(() => {
    setAppTitle(portalName, 'Reset Password')
  }, [])

  return (
    <>
      <LoginHeading>Password Changed</LoginHeading>
      <LoginContentContainer style={{ padding: '1rem' }}>
        <div className='slds-grid slds-grid_align-center slds-text-align_left slds-p-top_large slds-p-bottom_xx-large'>
          Your password has been reset successfully. Please return to the login
          page to login.
        </div>
        <div className='slds-grid slds-grid_align-center slds-p-top_xx-large'>
          <ButtonWrapper
            label='Return to Login'
            onClick={() => navigate('/login')}
            variant='brand'
          ></ButtonWrapper>
        </div>
      </LoginContentContainer>
    </>
  )
}

export default PasswordChangedSuccessPanel
