import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { startCase } from 'lodash'
import relatedListTableColumns from './relatedListTableColumns'
import { LightningDataTable } from '../../../common'
import { generateTableColumns } from '../../../../helpers/dataTable'

import { Button, Card } from '@salesforce/design-system-react'

const JobRelatedLists = ({
  relatedListName,
  relatedList,
  onLoadMore,
  isCurrentTab,
  onAddPaymentClicked,
  total
}) => {
  const Actions = <Button onClick={onAddPaymentClicked} label='New' />

  const columns = generateTableColumns(
    relatedListTableColumns[relatedListName] ?? []
  )

  return (
    <Card
      key={relatedListName}
      heading={`${startCase(relatedListName)} (${relatedList.length ?? 0})`}
      headerActions={
        relatedListName === 'paymentsAddedDeducted' ? Actions : null
      }
    >
      <LightningDataTable
        className='no-shrink-column'
        columns={columns}
        items={relatedList}
        totalItems={total}
        hasMore={isCurrentTab}
        onLoadMore={onLoadMore}
      />
    </Card>
  )
}

JobRelatedLists.propTypes = {
  relatedListName: PropTypes.string,
  relatedList: PropTypes.array,
  onLoadMore: PropTypes.func,
  isCurrentTab: PropTypes.bool,
  onAddPaymentClicked: PropTypes.func,
  total: PropTypes.number
}

export default memo(JobRelatedLists)
