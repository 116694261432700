import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Header, Image, SideMenu } from '../../components'
import { getHomeBannerUrl, getFaqBannerImageUrl } from '../../redux/slices/global'

const ContentContainer = styled.div`
  padding-top: 5vh;
`

const TopBannerImage = styled.div`
  height: 15vh;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`

const Layout = () => {
  const navigate = useNavigate()
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  const homePageBannerImage = useSelector(getHomeBannerUrl)
  const faqPageBannerImage = useSelector(getFaqBannerImageUrl)

  const openSideMenu = () => {
    setSideMenuOpen(prev => !prev)
  }

  const newQuoteClickedHandler = useCallback(() => {
    navigate('/quotes/new')
    setSideMenuOpen(false)
  }, [])

  const newJobClickedHandler = useCallback(() => {
    navigate('/quotes/submit')
    setSideMenuOpen(false)
  }, [])

  const onSideMenuItemClickedHandler = useCallback(() => {
    setSideMenuOpen(false)
  }, [])

  return (
    <>
      <Header
        menuToggleClickHandler={openSideMenu}
        newQuoteClickedHandler={newQuoteClickedHandler}
        newJobClickedHandler={newJobClickedHandler}
      ></Header>
      <SideMenu
        show={sideMenuOpen}
        newQuoteClickedHandler={newQuoteClickedHandler}
        newJobClickedHandler={newJobClickedHandler}
        onSideMenuItemClickedHandler={onSideMenuItemClickedHandler}
      ></SideMenu>
      <ContentContainer>
        <TopBannerImage>
          <Image
            width='100%'
            height='100%'
            cssUnit={'px'}
            src={window.location.pathname == '/faq' ? faqPageBannerImage : homePageBannerImage}
            alt='Banner'
            objectFit='cover'
          />
        </TopBannerImage>
        <Outlet />
      </ContentContainer>
    </>
  )
}

export default Layout
