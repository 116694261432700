import {
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_PASSWORD,
  INPUT_TYPE_TEXT
} from '../../constants'

export default {
  username: {
    inputType: INPUT_TYPE_TEXT,
    validations: [
      {
        name: 'required',
        errorText: 'Field is required'
      }
    ],
    inputProps: {
      label: 'Username',
      type: INPUT_TYPE_EMAIL,
      errorText: null,
      required: true,
      styleInput: { height: '2.75rem' }
    },
    value: '',
    valid: false
  },
  password: {
    inputType: INPUT_TYPE_TEXT,
    validations: [
      {
        name: 'required',
        errorText: 'Field is required'
      }
    ],
    inputProps: {
      label: 'Password',
      type: INPUT_TYPE_PASSWORD,
      errorText: null,
      required: true,
      styleInput: { height: '2.75rem' }
    },
    value: '',
    valid: false
  }
}
