import {
  performGetRequest,
  performPatchRequest,
  performPostRequest
} from '../helpers/service'

export const getJobDetailById = async jobId => {
  const response = await performGetRequest('jobs/' + jobId)
  return response
}

export const getJobLineListById = async (jobId, page, size) => {
  const response = await performGetRequest('jobs/' + jobId + '/lines', {
    page: page,
    size: size
  })
  return response
}

export const getJobSecurityListById = async (jobId, page, size) => {
  const response = await performGetRequest('jobs/' + jobId + '/securities', {
    page: page,
    size: size
  })
  return response
}

export const getPromiseToPaysListById = async (jobId, page, size) => {
  const response = await performGetRequest(
    'jobs/' + jobId + '/promise-to-pays',
    { page: page, size: size }
  )
  return response
}

export const getPaymentsListById = async (jobId, page, size) => {
  const response = await performGetRequest('jobs/' + jobId + '/payments', {
    page: page,
    size: size
  })
  return response
}

export const getExtraChargesListById = async (jobId, page, size) => {
  const response = await performGetRequest('jobs/' + jobId + '/extra-charges', {
    page: page,
    size: size
  })
  return response
}

export const getJobCommentsListById = async (jobId, page, size) => {
  const response = await performGetRequest('jobs/' + jobId + '/job-comments', {
    page: page,
    size: size
  })
  return response
}

export const postJobCommentById = async data => {
  const response = await performPostRequest('job-comments', data)
  return response
}

export const patchActionJobById = async (jobId, data) => {
  const response = await performPatchRequest(`jobs/${jobId}/action`, data)
  return response
}

export const postCreateJobApproval = async (data) => {
  const response = await performPostRequest(`job-approvals`, data)
  return response
}

export const getFilesListById = async jobId => {
  const response = await performGetRequest('jobs/' + jobId + '/files')
  return response
}

export const postPaymentByData = async data => {
  const response = await performPostRequest('payments', data)
  return response
}
