import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CardWrapper } from './StyledInvoiceList'

import { Icon, Card } from '@salesforce/design-system-react'

import { CardSearchHeader, LightningDataTable } from '../common'

import getColumnDefinitions from './invoiceListColumns'
import {
  filterRecords,
  generateTableColumns,
  sortRecords
} from '../../helpers/dataTable'

import { useDispatch } from 'react-redux'
import { downloadInvoiceFile } from '../../redux/slices/invoice'

const InvoiceList = ({ data, onLoadMore, totalItems }) => {
  const dispatch = useDispatch()

  const [internalFilterItems, setInternalFilterItems] = useState([])
  const [itemSearch, setItemSearch] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [currentSortColumn, setCurrentSortColumn] = useState(null)
  const [sortDirectionMemo, setSortDirectionMemo] = useState({})

  const isEmpty = data.length === 0

  const filterChangedHandler = useCallback(event => {
    setItemSearch(event.target.value)
  }, [])

  const onSortHandler = useCallback(
    sortColumn => {
      const { sortDirection, property: sortProperty } = sortColumn

      // This method may return null if data provided item is not array
      const sortedItems = sortRecords(
        internalFilterItems,
        sortProperty,
        sortDirection
      )

      if (sortedItems) {
        setInternalFilterItems(sortedItems)
        setCurrentSortColumn(sortProperty)
        setSortDirectionMemo(prev => ({
          ...prev,
          [sortProperty]: sortDirection
        }))
      }
    },
    [internalFilterItems]
  )

  const onSearchHandler = useCallback(() => {
    let filteredItems = filterRecords(itemSearch, data, columnDefinitions)

    if (currentSortColumn != null) {
      filteredItems = sortRecords(
        filteredItems,
        currentSortColumn,
        sortDirectionMemo
      )
    }
    setInternalFilterItems(filteredItems)
    setIsSearching(!!itemSearch)
  }, [itemSearch, currentSortColumn, sortDirectionMemo, data])

  const onFileClickedHandler = useCallback(invoiceData => {
    if (invoiceData && invoiceData.id && invoiceData.invoiceDocumentId) {
      const { invoiceDocumentId, id } = invoiceData
      dispatch(downloadInvoiceFile({ id, invoiceDocumentId }))
    }
  }, [])

  useEffect(() => {
    let computedData = filterRecords(itemSearch, data, columnDefinitions)
    if (currentSortColumn != null) {
      computedData = sortRecords(
        computedData,
        currentSortColumn,
        sortDirectionMemo
      )
    }

    setInternalFilterItems(computedData)
    return () => {
      setInternalFilterItems([])
    }
  }, [data])

  const columnDefinitions = getColumnDefinitions({
    sortColumn: currentSortColumn,
    sortDirectionMemo,
    onFileClick: onFileClickedHandler
  })

  const columns = generateTableColumns(columnDefinitions)

  return (
    <div style={{ overflow: 'auto' }}>
      <CardWrapper>
        <Card
          id='ExampleCard'
          header={
            <CardSearchHeader
              headerText={`Invoice (${totalItems})`}
              isEmpty={isEmpty}
              onFilterChange={filterChangedHandler}
              onSearch={onSearchHandler}
              icon={<Icon category='standard' name='work_capacity_usage' />}
            />
          }
        >
          <LightningDataTable
            columns={columns}
            items={internalFilterItems}
            id='invoice'
            totalItems={totalItems}
            hasMore={data.length < totalItems}
            onSort={onSortHandler}
            onLoadMore={onLoadMore}
            className={'no-shrink-column'}
            isFiltering={isSearching}
          />
        </Card>
      </CardWrapper>
    </div>
  )
}

InvoiceList.propTypes = {
  data: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired
}

export default memo(InvoiceList)
