import React from 'react'
import styled from 'styled-components'
import { AdminPageContent } from '../../containers'

const AdminPageWrapper = styled.div`
  padding: 1rem;
  background: white;
  min-height: 80vh;
  height: 100%;
`

const AdminPage = () => {
  return (
    <AdminPageWrapper>
      <AdminPageContent />
    </AdminPageWrapper>
  )
}

export default AdminPage
