import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InvoiceList } from '../../components'
import {
  fetchInvoiceList,
  getInvoiceListData,
  resetInvoiceListState,
  getDownloadFileResult,
  resetDownloadResult
} from '../../redux/slices/invoice'

import useAlert from '../../hooks/useAlert'
import { createErrorAlert } from '../../helpers/alert'

import { Spinner } from '@salesforce/design-system-react'
import { getPortalName } from '../../redux/slices/global'
import { setAppTitle } from '../../helpers/appState'

const InvoiceListContent = () => {
  const dispatch = useDispatch()
  const fireAlert = useAlert()

  const portalName = useSelector(getPortalName)

  const invoiceListData = useSelector(getInvoiceListData)
  const downloadFileResult = useSelector(getDownloadFileResult)

  const onLoadMoreInvoiceListHandler = useCallback(() => {
    if (!invoiceListData.loading)
      dispatch(fetchInvoiceList({ page: invoiceListData.page + 1 }))
  }, [invoiceListData.loading, invoiceListData.page])

  useEffect(() => {
    if (downloadFileResult?.error != null) {
      fireAlert(createErrorAlert(downloadFileResult?.error?.status, 3000))
      dispatch(resetDownloadResult())
    }
  }, [downloadFileResult.error])

  useEffect(() => {
    setAppTitle(portalName, 'Invoices')
    dispatch(fetchInvoiceList({ page: 1 }))

    return () => {
      dispatch(resetInvoiceListState())
    }
  }, [])

  return (
    <div className='slds-p-around_small'>
      <InvoiceList
        data={invoiceListData.records}
        onLoadMore={onLoadMoreInvoiceListHandler}
        totalItems={invoiceListData.total}
      />
      {downloadFileResult.loading ? <Spinner /> : null}
    </div>
  )
}

export default InvoiceListContent
