import { uniqueId } from 'lodash'

export const createSimpleAlert = (
  variant = 'info',
  message,
  duration = 5000
) => {
  return { id: uniqueId('ALERTS_'), variant, message, duration }
}

export const createSuccessAlert = (message, duration = 5000) =>
  createSimpleAlert('success', message, duration)

export const createWarningAlert = (message, duration = 5000) =>
  createSimpleAlert('warning', message, duration)

export const createErrorAlert = (message, duration = 5000) =>
  createSimpleAlert('error', message, duration)
