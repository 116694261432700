import {
  performGetRequest,
  performPostRequest,
  performPatchRequest
} from '../helpers/service'

export const getJobType = async () => {
  const response = await performGetRequest('work-types')
  return response
}

export const getSecurityTypeByJobTypeId = async jobTypeId => {
  const response = await performGetRequest('security-types', {
    jobTypeId: jobTypeId
  })
  return response
}

export const getPaymentBankAccounts = async () => {
  const response = await performGetRequest('bank-accounts/payments')
  return response
}

export const getQuoteById = async quoteId => {
  const response = await performGetRequest('quotes/' + quoteId)
  return response
}

export const postQuote = async data => {
  const response = await performPostRequest('cases', data)
  return response
}

export const postCalculateQuotePrice = async data => {
  const response = await performPostRequest('quotes/submit-quote', data)
  return response
}

export const postCreateCustomQuote = async data => {
  const response = await performPostRequest('quotes/submit-custom-quote', data)
  return response
}

export const patchDeclineQuote = async (quoteId, data) => {
  const response = await performPatchRequest(
    `quotes/${quoteId}/decline-quote`,
    data
  )
  return response
}

export const patchHoldQuote = async quoteId => {
  const response = await performPatchRequest(`quotes/${quoteId}/hold-quote`)
  return response
}
