import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { ForgotPasswordEmailSent, ForgotPasswordForm } from '../../components'
import { setAppTitle } from '../../helpers/appState'

import { Spinner } from '@salesforce/design-system-react'

import {
  LoginContentContainer,
  LoginHeading
} from '../../pages/LoginPage/StyledLoginItems'
import {
  getLoginErrorMessage,
  getLoginLoadingStatus,
  getRequestResetPasswordStatus,
  requestResetPassword,
  resetAuthState
} from '../../redux/slices/auth'
import { getPortalName } from '../../redux/slices/global'

const pageTitle = 'Forgot Password'

const ForgotPasswordPanel = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [username, setUsername] = useState(null)

  const loginLoading = useSelector(getLoginLoadingStatus)
  const errorText = useSelector(getLoginErrorMessage)
  const requestResetPasswordSuccess = useSelector(getRequestResetPasswordStatus)
  const portalName = useSelector(getPortalName)

  const onInputChangedHandler = useCallback(value => {
    setUsername(value)
  }, [])

  const onSubmittedHandler = useCallback(
    event => {
      event.preventDefault()

      if (username) {
        dispatch(requestResetPassword(username))
      }
    },
    [username]
  )

  const onCancelClickedHandler = useCallback(() => {
    navigate('/login')
  }, [])

  useEffect(() => {
    setAppTitle(portalName, pageTitle)
  }, [portalName])

  useEffect(() => {
    return () => {
      dispatch(resetAuthState())
    }
  }, [])

  const headingText = requestResetPasswordSuccess
    ? 'Check your email'
    : 'Forgot Your Password'

  const display = requestResetPasswordSuccess ? (
    <ForgotPasswordEmailSent onBackClicked={onCancelClickedHandler} />
  ) : (
    <ForgotPasswordForm
      username={username}
      errorText={errorText}
      emailSent={requestResetPasswordSuccess}
      onSubmitted={onSubmittedHandler}
      onInputChanged={onInputChangedHandler}
      onCancelClicked={onCancelClickedHandler}
    />
  )

  return (
    <>
      {loginLoading ? <Spinner /> : null}
      <LoginHeading>{headingText}</LoginHeading>
      <LoginContentContainer style={{ padding: '1rem' }}>
        {display}
      </LoginContentContainer>
    </>
  )
}

export default ForgotPasswordPanel
