import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Textarea } from '@salesforce/design-system-react'
import { debounce } from 'lodash'

const StandardTextArea = ({ name, value, onChange, ...inputProps }) => {
  const [internalValue, setInternalValue] = useState('')

  const debouncedChangeHandler = useCallback(debounce(onChange, 150), [
    onChange
  ])

  const onInputChangedHandler = useCallback(e => {
    const sourceEvent = { ...e }
    const { name, value } = e.target
    setInternalValue(value)
    debouncedChangeHandler(value, name, sourceEvent)
  }, [])

  useEffect(() => {
    setInternalValue(value)
    return () => {
      setInternalValue('')
    }
  }, [value])

  return (
    <Textarea
      id={name}
      name={name}
      value={internalValue}
      onChange={onInputChangedHandler}
      {...inputProps}
    />
  )
}

StandardTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default StandardTextArea
