import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, Card } from '@salesforce/design-system-react'
import { LightningDataTable } from '../../../common'
import { generateTableColumns } from '../../../../helpers/dataTable'
import { getFilesListColumn } from './fileListColumns'
import { downloadFileById } from '../../../../redux/slices/job'
import { useDropzone } from 'react-dropzone'

const JobRecordFileList = ({
  fileList,
  onLoadMoreFile,
  isCurrentTab,
  onFileChanged
}) => {
  const dispatch = useDispatch()

  const { getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: onFileChanged
  })

  const toFilesHandler = useCallback(rowData => {
    dispatch(downloadFileById(rowData.id))
  })

  const columns = generateTableColumns(getFilesListColumn(toFilesHandler))

  return (
    <Card
      heading={`Files (${fileList[0] === '' ? 0 : fileList.length})`}
      headerActions={<Button onClick={open} label='Upload' />}
    >
      <LightningDataTable
        items={fileList[0] === '' ? [] : fileList}
        columns={columns}
        hasMore={isCurrentTab}
        onLoadMore={onLoadMoreFile}
      />
      <input {...getInputProps()} hidden />
    </Card>
  )
}

JobRecordFileList.propTypes = {
  fileList: PropTypes.array,
  onLoadMoreFile: PropTypes.func,
  isCurrentTab: PropTypes.bool,
  onFileChanged: PropTypes.func
}

export default memo(JobRecordFileList)
