import { configureStore } from '@reduxjs/toolkit'
import { throttle } from 'lodash'

import {
  getAccessToken,
  getRemittanceType,
  getTimezone,
  setAccessToken,
  setRemittanceType,
  setTimezone
} from '../helpers/localStorage'

import {
  authReducer,
  globalReducer,
  homeReducer,
  userReducer,
  bankAccountReducer,
  jobReducer,
  adminReducer,
  quoteReducer,
  invoiceReducer,
  promiseToPaysReducer,
  faqReducer,
  submitJobReducer
} from './slices'
import jwtDecode from 'jwt-decode'

const store = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
    home: homeReducer,
    user: userReducer,
    bankAccount: bankAccountReducer,
    job: jobReducer,
    admin: adminReducer,
    quote: quoteReducer,
    promiseToPays: promiseToPaysReducer,
    faq: faqReducer,
    invoice: invoiceReducer,
    submitJob: submitJobReducer
  },
  devTools: process.env.NODE_ENV === 'development',
  preloadedState: {
    auth: {
      accessToken: getAccessToken(),
      role: getAccessToken() ? jwtDecode(getAccessToken()).role : null,
      timezone: getTimezone(),
      remittanceType: getRemittanceType()
    },
    global: { portalName: 'iDebt360' }
  }
})

store.subscribe(
  throttle(() => {
    setAccessToken(store.getState().auth.accessToken)
    setTimezone(store.getState().auth.timezone)
    setRemittanceType(store.getState().auth.remittanceType)
  }, 500)
)

export default store
