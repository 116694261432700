import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { INPUT_TYPE_EMAIL } from '../../../constants'

import { StandardInput } from '../../common'
import {
  LoginButton,
  LoginUnorderedList
} from '../../../pages/LoginPage/StyledLoginItems'
import { validateEmail } from '../../../helpers/form'
import { isNull } from 'lodash'

const ForgotPasswordForm = ({
  onInputChanged,
  onCancelClicked,
  onSubmitted,

  username,
  errorText
}) => {
  const emailValid = validateEmail(username)

  return (
    <>
      <p className='slds-m-around_x-small'>Having trouble logging in?</p>
      <LoginUnorderedList className='slds-list_dotted slds-m-vertical_x_small'>
        <li>Usernames are in the form of an email address.</li>
        <li>Passwords are case sensitive.</li>
      </LoginUnorderedList>
      <p className='slds-m-around_x-small'>
        To reset your password, enter your <b>iDebt360</b> username.
      </p>
      <form className='slds-m-around_x-small' onSubmit={onSubmitted}>
        <StandardInput
          name='username'
          styleInput={{ height: '2.75rem' }}
          type={INPUT_TYPE_EMAIL}
          value={username ?? ''}
          required
          label='Username'
          onChange={onInputChanged}
          errorText={isNull(username) || emailValid ? errorText : 'Username must be an email.'}
        />
        <div className='slds-grid slds-m-top_small'>
          <LoginButton type='button' label='Cancel' onClick={onCancelClicked} />
          <LoginButton
            type='submit'
            variant='brand'
            label='Continue'
            disabled={!emailValid}
          />
        </div>
      </form>
    </>
  )
}

ForgotPasswordForm.propTypes = {
  username: PropTypes.string,
  errorText: PropTypes.string,
  onSubmitted: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired
}

export default memo(ForgotPasswordForm)
