import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@salesforce/design-system-react'
import styled from 'styled-components'

const ModalWithDatePicker = styled(Modal)`
  & .slds-modal__content {
    overflow: visible;
  }
`

const StandardModal = ({
  hasDatePicker = false,
  isOpen,
  modalTitle,
  onClose,
  footer = null,
  children,
  ...props
}) => {
  const ModalContainer = hasDatePicker ? ModalWithDatePicker : Modal
  return (
    <ModalContainer
      ariaHideApp={false}
      isOpen={isOpen}
      heading={modalTitle}
      onRequestClose={onClose}
      footer={footer}
      {...props}
    >
      <section className='slds-p-around_large'>{children}</section>
    </ModalContainer>
  )
}

StandardModal.propTypes = {
  hasDatePicker: PropTypes.bool,
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  onClose: PropTypes.func,
  footer: PropTypes.node,
  children: PropTypes.node
}

export default StandardModal
