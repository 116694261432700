import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@salesforce/design-system-react'
import { StandardModal } from '../../common'

const SubmitJobSuccessModal = ({ isOpen, onSaveButtonClickedHandler }) => {
  return (
    <StandardModal
      isOpen={isOpen}
      modalTitle={'Job File Submitted'}
      disableClose
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            variant='brand'
            onClick={onSaveButtonClickedHandler}
            label='Return to Home Page'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>
        Your Job File has been successfully submitted. Please allow up to 5
        minutes for the Job to appear in your job list on the home page. Please
        reach out with any questions or concerns.
      </div>
    </StandardModal>
  )
}

SubmitJobSuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onSaveButtonClickedHandler: PropTypes.func
}

export default memo(SubmitJobSuccessModal)
