import React, { useEffect, useCallback } from 'react'
import propTypes from 'prop-types'

import { JobRelatedLists } from '../../../components/JobDetail/JobRecordAdditionalData/JobRelatedLists'
import { PAGE_SIZE } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  getJobDetailData,
  fetchJobLineListById,
  getJobLineListData,
  fetchJobSecurityListById,
  getJobSecurityListData,
  fetchPromisetoPaysListById,
  getJobPromiseToPaysListData,
  fetchPaymentsListById,
  getPaymentsListData,
  fetchExtraChargesListById,
  getExtraChargesListData
  //   resetJobData
} from '../../../redux/slices/job'

const JobRelatedListsContent = ({
  jobId,
  isCurrentTab,
  onAddPaymentClicked
}) => {
  const dispatch = useDispatch()
  const workOrders = useSelector(getJobLineListData)
  const securities = useSelector(getJobSecurityListData)
  const promiseToPays = useSelector(getJobPromiseToPaysListData)
  const paymentsAddedDeducted = useSelector(getPaymentsListData)
  const extraCharges = useSelector(getExtraChargesListData)
  const jobDetail = useSelector(getJobDetailData)

  //on related lists load more
  const onWorkOrdersLoadMore = useCallback(() => {
    if (!workOrders.loading) {
      dispatch(
        fetchJobLineListById({
          jobId,
          page: workOrders.page + 1,
          size: PAGE_SIZE
        })
      )
    }
  })

  const onSecuritiesLoadMore = useCallback(() => {
    if (!securities.loading) {
      dispatch(
        fetchJobSecurityListById({
          jobId,
          page: securities.page + 1,
          size: PAGE_SIZE
        })
      )
    }
  })

  const onPromiseToPaysLoadMore = useCallback(() => {
    if (!promiseToPays.loading) {
      dispatch(
        fetchPromisetoPaysListById({
          jobId,
          page: promiseToPays.page + 1,
          size: PAGE_SIZE
        })
      )
    }
  })

  const onPaymentsLoadMore = useCallback(() => {
    if (!paymentsAddedDeducted.loading) {
      dispatch(
        fetchPaymentsListById({
          jobId,
          page: paymentsAddedDeducted.page + 1,
          size: PAGE_SIZE
        })
      )
    }
  })

  const onExtraChargesLoadMore = useCallback(() => {
    if (!extraCharges.loading) {
      dispatch(
        fetchExtraChargesListById({
          jobId,
          page: extraCharges.page + 1,
          size: PAGE_SIZE
        })
      )
    }
  })

  useEffect(() => {
    if (jobDetail.statusCode === 200) {
      dispatch(fetchJobLineListById({ jobId, page: 1, size: PAGE_SIZE }))
      dispatch(fetchJobSecurityListById({ jobId, page: 1, size: PAGE_SIZE }))
      dispatch(fetchPromisetoPaysListById({ jobId, page: 1, size: PAGE_SIZE }))
      dispatch(fetchPaymentsListById({ jobId, page: 1, size: PAGE_SIZE }))
      dispatch(fetchExtraChargesListById({ jobId, page: 1, size: PAGE_SIZE }))
    }
  }, [jobDetail.statusCode])
  return (
    <>
      <JobRelatedLists
        relatedListName={'workOrders'}
        relatedList={workOrders.records ?? []}
        onLoadMore={onWorkOrdersLoadMore}
        isCurrentTab={isCurrentTab}
        total={workOrders.total}
      />
      <JobRelatedLists
        relatedListName={'securities'}
        relatedList={securities.records ?? []}
        onLoadMore={onSecuritiesLoadMore}
        isCurrentTab={isCurrentTab}
        total={securities.total}
      />
      <JobRelatedLists
        relatedListName={'promiseToPays'}
        relatedList={promiseToPays.records ?? []}
        onLoadMore={onPromiseToPaysLoadMore}
        isCurrentTab={isCurrentTab}
        total={promiseToPays.total}
      />
      <JobRelatedLists
        relatedListName={'paymentsAddedDeducted'}
        relatedList={paymentsAddedDeducted.records ?? []}
        onLoadMore={onPaymentsLoadMore}
        isCurrentTab={isCurrentTab}
        total={paymentsAddedDeducted.total}
        onAddPaymentClicked={onAddPaymentClicked}
      />
      <JobRelatedLists
        relatedListName={'extraCharges'}
        relatedList={extraCharges.records ?? []}
        onLoadMore={onExtraChargesLoadMore}
        isCurrentTab={isCurrentTab}
        total={extraCharges.total}
      />
    </>
  )
}

JobRelatedListsContent.propTypes = {
  jobId: propTypes.string,
  isCurrentTab: propTypes.bool,
  onAddPaymentClicked: propTypes.func
}

export default JobRelatedListsContent
