import React from 'react'
import PropTypes from 'prop-types'

import { QuoteActions } from './QuoteActions'
import { QuoteDetails } from './QuoteDetails'
import { Divider } from '../../common'
import styled from 'styled-components'

const CardWrapper = styled.div`
  .cardBox {
    margin: auto;
  }
`

const QuoteSummayHeader = () => {
  return (
    <div className='slds-text-heading_large slds-p-around_x-small'>
      Quote Summary
    </div>
  )
}

const CreateQuoteSummary = ({
  quoteNumber,
  quoteSuccessCharge,
  quoteDeclinedCharge,
  onAccepted,
  onDeclined,
  onHolded,
  isExpired = false,
  isError = false
}) => {
  return (
    <CardWrapper>
      <div className='slds-p-around_small slds-medium-size_1-of-2 cardBox'>
        <QuoteSummayHeader />
        <Divider />
        <QuoteDetails
          quoteNumber={quoteNumber}
          quoteSuccessCharge={quoteSuccessCharge}
          quoteDeclinedCharge={quoteDeclinedCharge}
          isExpired={isExpired}
        />
        {!isExpired && !isError ? (
          <QuoteActions
            onAccepted={onAccepted}
            onDeclined={onDeclined}
            onHolded={onHolded}
          />
        ) : null}
      </div>
    </CardWrapper>
  )
}

CreateQuoteSummary.propTypes = {
  quoteNumber: PropTypes.string,
  quoteSuccessCharge: PropTypes.number,
  quoteDeclinedCharge: PropTypes.number,
  onAccepted: PropTypes.func,
  onDeclined: PropTypes.func,
  onHolded: PropTypes.func,
  isExpired: PropTypes.bool,
  isError: PropTypes.bool
}

export default CreateQuoteSummary
