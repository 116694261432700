import { INPUT_TYPE_NUMBER, INPUT_TYPE_TEXTAREA } from '../../../constants'

export default {
  comment: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Release Hold Comments',
      type: INPUT_TYPE_TEXTAREA,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  advisedArrears: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Current Arrears',
      type: INPUT_TYPE_NUMBER,
      errorText: null,
      required: true,
      fixedTextLeft: '$'
    },
    value: null,
    valid: false
  }
}
