import styled from 'styled-components'

export const ContactUsPageWrapper = styled.div`
  background: white;
  padding: 1.5rem;
  width: 75%;
  margin: auto;
  min-height: 100vh;
  height: 100%;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`

export const ContactUsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`
