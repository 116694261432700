import {
  buildDateTimeColumn,
  buildLinkColumn,
  buildSimpleColumn
} from '../../../helpers/dataTable'

export default ({ sortColumn, sortDirectionMemo }) => [
  buildLinkColumn(
    'Quote Number',
    'quoteNumber',
    true,
    { baseUrl: '/quotes' },
    {
      sortable: true,
      sortColumn,
      sortDirectionMemo
    }
  ),
  buildSimpleColumn('Job Type', 'jobType', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn(
    'Address',
    'address',
    false,
    {
      sortable: true,
      sortColumn,
      sortDirectionMemo
    },
    false
  ),
  buildSimpleColumn('Client Ref#', 'clientRef', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildDateTimeColumn(
    'Created Date',
    'createdDate',
    false,
    { withTime: false, applyTimezoneOnDate: true },
    { sortable: true, sortColumn, sortDirectionMemo }
  )
]
