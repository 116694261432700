import React, { memo } from 'react'
import PropTypes from 'prop-types'

const RecordSectionHead = ({ sectionName }) => {
  return (
    <div className='slds-section slds-is-open'>
      <h3 className='slds-section__title'>
        <button
          className='slds-button slds-section__title-action'
          style={{ pointerEvents: 'none' }}
        >
          <span className='slds-truncate' title={sectionName}>
            {sectionName}
          </span>
        </button>
      </h3>
    </div>
  )
}

RecordSectionHead.propTypes = {
  sectionName: PropTypes.string
}

export default memo(RecordSectionHead)
