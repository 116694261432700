import React, { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { BulletinList } from '../../components'

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  top: 56px;
  align-items: center;
  align-content: center;
  width: 100%;
`

export const AlertContext = createContext()

export const AlertContextProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([])

  const fireAlert = useCallback(newAlert => {
    setAlerts(prev => [...prev, newAlert])
  }, [])

  const onAlertClosedHandler = useCallback(targetIndex => {
    setAlerts(prev => prev.filter(item => item.id !== targetIndex))
  }, [])

  return (
    <AlertContext.Provider value={fireAlert}>
      <AlertContainer>
        <BulletinList
          bulletins={alerts}
          onBulletinClosedHandler={onAlertClosedHandler}
        />
      </AlertContainer>
      {children}
    </AlertContext.Provider>
  )
}

AlertContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
