import React from 'react'
import PropTypes from 'prop-types'

import { Markup } from 'interweave'

const FAQQuestions = ({ question, answer }) => {
  return (
    <div className='faq-container'>
      <div className='question'>{question}</div>
      <div className='answer'>
        <Markup content={answer} />
      </div>
    </div>
  )
}

FAQQuestions.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string
}

export default FAQQuestions
