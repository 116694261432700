import {
  performGetRequest,
  performPostRequest,
  performPatchRequest
} from '../helpers/service'

export const createBankAccount = async data => {
  const response = await performPostRequest('bank-accounts', data)
  return response
}

export const getBankAccountList = async (page, size) => {
  const response = await performGetRequest('bank-accounts', {
    page: page,
    size: size
  })
  return response
}

export const getBankAccountById = async bankAccountId => {
  const response = await performGetRequest('bank-accounts/' + bankAccountId)
  return response
}

export const patchBankAccountByData = async data => {
  const response = await performPatchRequest('bank-accounts', data)
  return response
}
