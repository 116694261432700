import dayjs from 'dayjs'
import { accessTokenKey, remittanceKey, timezoneKey } from '../constants'

export const getAccessToken = () => {
  try {
    return localStorage.getItem(accessTokenKey)
  } catch (error) {
    return undefined
  }
}

export const getTimezone = () => {
  try {
    return localStorage.getItem(timezoneKey)
  } catch (error) {
    return undefined
  }
}

export const setAccessToken = accessToken => {
  if (accessToken) {
    localStorage.setItem(accessTokenKey, accessToken)
  } else {
    localStorage.removeItem(accessTokenKey)
  }
}

export const setTimezone = timezone => {
  if (timezone) {
    dayjs.tz.setDefault(timezone)
    localStorage.setItem(timezoneKey, timezone)
  } else {
    dayjs.tz.setDefault()
    localStorage.removeItem(timezoneKey)
  }
}

export const setRemittanceType = remittanceType => {
  if (remittanceType) {
    localStorage.setItem(remittanceKey, remittanceType)
  } else {
    localStorage.removeItem(remittanceKey)
  }
}

export const getRemittanceType = () => {
  try {
    return localStorage.getItem(remittanceKey)
  } catch (error) {
    return undefined
  }
}