import { sumBy } from 'lodash'
import {
  BMP_FILE_TYPE,
  FILE_PROVISION_TYPE_OTHER,
  FILE_PROVISION_TYPE_TEMPLATE,
  GIF_FILE_TYPE,
  JPEG_FILE_TYPE,
  PDF_FILE_TYPE,
  PNG_FILE_TYPE,
  sumMaxSize,
  TIFF_FILE_TYPE,
  WEBP_FILE_TYPE,
  CSV_FILE_TYPE,
  XLSX_FILE_TYPE
} from '../constants/file'
import { buildFilePayload } from './file'

export const validateSubmitJobFileData = (
  selectedProvisionType,
  templateFormat,
  jobInstructionFile,
  supportingFiles
) => {
  const provisionTypeValid = [
    FILE_PROVISION_TYPE_TEMPLATE,
    FILE_PROVISION_TYPE_OTHER
  ].includes(selectedProvisionType)

  const templateTypeValid =
    selectedProvisionType === FILE_PROVISION_TYPE_TEMPLATE
      ? templateFormat.length > 0
      : true
  const jobInstructionFileValid = jobInstructionFile.length > 0
  const supportingFilesValid = sumBy(supportingFiles, 'size') <= sumMaxSize

  return (
    provisionTypeValid &&
    templateTypeValid &&
    jobInstructionFileValid &&
    supportingFilesValid
  )
}

export const buildSubmitJobPayload = async (
  jobFileProvisionType,
  jobFileTemplateId,
  jobInstructionFiles,
  supportingFileList,
  sfEntityId,
  remittanceInterventionRequired,
  remittanceBankAccountId,
  billerCode,
  referenceNumber,
  skipBpayDetails,
  bankFileType,
  additionalJobNote
) => {
  const jobInstructionFile = await buildFilePayload(
    jobInstructionFiles[0],
    sfEntityId,
    { fileNamePostfix: '[JOBFILE]' }
  )
  const supportingFiles = await Promise.all(
    supportingFileList.map(
      async file => await buildFilePayload(file, sfEntityId)
    )
  )

  return {
    jobId: sfEntityId,
    jobFileProvisionType: jobFileProvisionType,
    jobFileTemplateId:
      jobFileProvisionType === FILE_PROVISION_TYPE_TEMPLATE
        ? jobFileTemplateId
        : null,
    jobInstructionFile,
    supportingFiles,
    status:
      jobFileProvisionType === FILE_PROVISION_TYPE_TEMPLATE
        ? 'Job File OCR Processing'
        : 'Job File Review Required',
    remittanceInterventionRequired,
    remittanceBankAccount: remittanceBankAccountId,
    billerCode: skipBpayDetails ? undefined : billerCode,
    referenceNumber: skipBpayDetails ? undefined : referenceNumber,
    skipBpayDetails,
    bankFileType,
    additionalJobNotes: additionalJobNote
  }
}

export const getAcceptedFileTypes = jobFileType => {
  let acceptedFileTypes = [
    PDF_FILE_TYPE,
    TIFF_FILE_TYPE,
    GIF_FILE_TYPE,
    JPEG_FILE_TYPE,
    PNG_FILE_TYPE,
    WEBP_FILE_TYPE,
    BMP_FILE_TYPE
  ]

  if (jobFileType === FILE_PROVISION_TYPE_OTHER) {
    acceptedFileTypes = [
      ...acceptedFileTypes,
      CSV_FILE_TYPE,
      XLSX_FILE_TYPE
    ]
  }

  return acceptedFileTypes
}

export const getFileErrorText = jobFileType => {
  return `Job File Types support ${jobFileType === FILE_PROVISION_TYPE_OTHER ? 'CSV, XLSX, ' : ''}PDF, TIFF, GIF, JPEG, PNG, BMP, and WEBP formats up to max size of 4MB for a single file and 20MB for multiple files. Please use Other for any other file types`
}

export const getSelectedPaymentType = selectedRemittanceType => {
  return selectedRemittanceType?.[0]?.id
}