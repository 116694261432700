import React from 'react'
import PropTypes from 'prop-types'
import { FormCard } from '../../../components'
import { Button, Icon } from '@salesforce/design-system-react'

const ClientSpecificInfoForm = ({ formData, onEditButtonClicked }) => {
  return (
    <FormCard
      header='Client Specific Information'
      readOnly={true}
      icon={<Icon category='standard' name='account' />}
      form={formData}
      onInputChanged={onEditButtonClicked}
      headerActions={<Button label='Edit' onClick={onEditButtonClicked} />}
    />
  )
}

ClientSpecificInfoForm.propTypes = {
  formData: PropTypes.object,
  onEditButtonClicked: PropTypes.func
}

export default ClientSpecificInfoForm
