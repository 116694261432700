import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getLoginStatus } from '../../redux/slices/auth'
import { Navigate, useLocation } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const location = useLocation()
  const isLoggedIn = useSelector(getLoginStatus)

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to='/login' replace state={{ from: location.pathname }} />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node
}

export default PrivateRoute
