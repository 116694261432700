import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  createReduxRecordListState,
  updateReduxRecords
} from '../../helpers/redux'

import { getAPIRequestError, extractPagination } from '../../helpers/service'

import { getPromiseToPays } from '../../api/promiseToPays'

const initialState = {
  promiseToPayList: createReduxRecordListState()
}

export const fetchPromiseToPays = createAsyncThunk(
  'fetchPromiseToPays',
  async ({ page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getPromiseToPays(page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const promiseToPaySlice = createSlice({
  name: 'promiseToPays',
  initialState,
  reducers: {
    resetQuotePageState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchPromiseToPays.pending, state => {
      state.promiseToPayList.loading = true
    })
    builder.addCase(fetchPromiseToPays.fulfilled, (state, { payload }) => {
      state.promiseToPayList.loading = false
      state.promiseToPayList.records = updateReduxRecords(
        state.promiseToPayList.records,
        payload.data,
        payload.pagination?.page
      )
      state.promiseToPayList.page = payload.pagination?.page
      state.promiseToPayList.size = payload.pagination?.size
      state.promiseToPayList.total = payload.pagination?.total
      state.promiseToPayList.totalPages = payload.pagination?.totalPages
      state.promiseToPayList.error = null
    })
    builder.addCase(fetchPromiseToPays.rejected, (state, { payload }) => {
      state.promiseToPayList.loading = false
      state.promiseToPayList.records = []
      state.promiseToPayList.error = payload?.error
    })
  }
})

export default promiseToPaySlice.reducer

export const getPromiseToPayList = createSelector(
  state => state.promiseToPays.promiseToPayList,
  promiseToPayList => promiseToPayList
)
