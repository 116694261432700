import { Button } from '@salesforce/design-system-react'
import styled from 'styled-components'

export const LoginSingleGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  min-height: 100vh;

  @media screen and (max-width: 760px) {
    padding: 1.25rem;
  }
`

export const LoginGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  min-height: 100vh;
  max-height: 100%;

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(1, 100%);
  }

  @media screen and (max-width: 760px) {
    padding: 1.25rem;
  }
`

export const LoginGridItem = styled.div`
  align-self: center;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    &.mobile-hidden {
      display: none;
    }
  }
`

export const LoginContainer = styled.div`
  display: flex;
  width: 50%;
  min-width: 200px;
  max-width: 375px;
  flex-direction: column;
  color: #16325c;

  @media screen and (max-width: 760px) {
    width: 100%;
  }
`

export const LoginImageContainer = styled.div`
  height: 120px;
  width: 100%;
  margin: 0.75rem 0;
`

export const LoginContentContainer = styled.div`
  background-color: white;
  text-align: left;
  border: 1px solid #d8dde6;
  border-radius: 0.15rem;
  padding: 1.25rem;
`

export const LoginExtraText = styled.p`
  margin-top: 0.5rem;
  padding: 0.375rem;
  text-align: center;
`

export const LoginHeading = styled.h1`
  font-size: 1.5rem;
  font-weight: 250;
  color: #16325c;
  margin: 0.75rem;
  text-align: center;
`

export const LoginButton = styled(Button)`
  width: 100%;
  height: 2.75rem;

  & + & {
    margin-left: 0.75rem;
  }
`

export const LoginUnorderedList = styled.ul`
  line-height: 2;
`
