import { ContactUsContent } from '../../containers'
import {
  ContactUsPageWrapper,
  ContactUsContentWrapper
} from './StyledContactUsPageItems'

const ContactUsPage = () => {
  return (
    <ContactUsPageWrapper>
      <ContactUsContentWrapper>
        <ContactUsContent />
      </ContactUsContentWrapper>
    </ContactUsPageWrapper>
  )
}

export default ContactUsPage
