import { performGetRequest } from '../helpers/service'

export const getInvoiceList = async (page, size = 1000) => {
  const response = await performGetRequest('invoices', {
    page,
    size
  })

  return response
}
