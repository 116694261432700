import React from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './redux'

import { setupDayjs } from './helpers/date'

import App from './App'
import { AlertContextProvider } from './contexts/AlertContext/AlertContext'
import { IconSettings } from '@salesforce/design-system-react'
import { LoadScript } from '@react-google-maps/api'

setupDayjs()

const googleMapLibraries = ['places']

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IconSettings iconPath={process.env.PUBLIC_URL + '/assets/icons'}>
        <AlertContextProvider>
          <BrowserRouter>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              libraries={googleMapLibraries}
            >
            <App />
            </LoadScript>
          </BrowserRouter>
        </AlertContextProvider>
      </IconSettings>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
