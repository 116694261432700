import { performGetRequest } from '../helpers/service'

export const getPromiseToPays = async (page, size) => {
  const response = await performGetRequest('promise-pays', {
    page: page,
    size: size
  })

  return response
}
