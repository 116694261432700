import React from 'react'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { WelcomeHeader } from '../../WelcomeHeader'

const CreateQuoteHeader = ({ headingText, 
  contentText='', 
  // subtitleText 
}) => {
  return (
    <div className='slds-text-align_center slds-p-horizontal_xx-small'>
      <WelcomeHeader
        headingText={headingText}
        contentText={contentText}
      />
      {/* <p>{subtitleText}</p> */}
    </div>
  )
}

CreateQuoteHeader.propTypes = {
  headingText: PropTypes.string,
  contentText: PropTypes.string,
  // subtitleText: PropTypes.string
}

export default memo(CreateQuoteHeader)
