import {
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DATETIME,
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SECTION,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_TIME
} from '../constants'
import {
  StandardInput,
  RecordSectionHead,
  StandardDatePicker,
  StandardCombobox,
  StandardTextArea,
  StandardCheckbox,
  StandardTimeInput
} from '../components'
import { intersectionBy, startCase, toNumber, isArray, isEmpty } from 'lodash'
import {
  getDateFormat,
  getTimeFormat,
  getTimeObjectFromTimeString
} from './date'

import dayjs from 'dayjs'
import { getNumberText } from './number'

export const getFormDisplay = (form, onInputChanged, options) => {
  const isAllReadOnly = options?.isAllReadOnly ?? false

  const formDisplay = Object.entries(form).map(formItem => {
    const [formKey, formAttrs] = formItem
    const { inputType, inputProps } = formAttrs
    const label = inputProps?.label === undefined ? startCase(formKey) : null
    const value = formAttrs.value

    if (inputProps?.hidden) return null

    let formComponent = null
    switch (inputType) {
      case INPUT_TYPE_EMAIL:
      case INPUT_TYPE_NUMBER:
      case INPUT_TYPE_TEXT:
        formComponent = (
          <StandardInput
            key={formKey}
            name={formKey}
            value={value ?? ''}
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_DATE:
        formComponent = isAllReadOnly ? (
          <StandardInput
            key={formKey}
            name={formKey}
            type='text'
            value={getDateFormat(value, false) ?? ''}
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        ) : (
          <StandardDatePicker
            key={formKey}
            name={formKey}
            value={value ?? ''}
            label={label}
            readOnly={isAllReadOnly}
            onOpen={isAllReadOnly ? false : null}
            {...inputProps}
            onChange={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_TIME:
        formComponent = isAllReadOnly ? (
          <StandardInput
            key={formKey}
            name={formKey}
            type='text'
            value={getTimeFormat(value) ?? ''}
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        ) : (
          <StandardTimeInput
            key={formKey}
            name={formKey}
            value={value}
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_DROPDOWN:
        formComponent = isAllReadOnly ? (
          <StandardInput
            key={formKey}
            name={formKey}
            type='text'
            value={
              isArray(value) ? value.map(item => item.label).toString() : ''
            }
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        ) : (
          <StandardCombobox
            key={formKey}
            name={formKey}
            value={value}
            label={label}
            readOnly={true}
            options={formAttrs.options}
            {...inputProps}
            onSelect={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_SEARCH:
        formComponent = (
          <StandardCombobox
            key={formKey}
            name={formKey}
            value={value}
            singleInputDisabled={isAllReadOnly}
            label={label}
            {...inputProps}
            onSelect={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_TEXTAREA:
        formComponent = (
          <StandardTextArea
            key={formKey}
            name={formKey}
            value={value ?? ''}
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_CHECKBOX:
        formComponent = (
          <StandardCheckbox
            key={formKey}
            name={formKey}
            value={!!value}
            checked={value}
            label={label}
            readOnly={isAllReadOnly}
            {...inputProps}
            onChange={onInputChanged}
          />
        )
        break

      case INPUT_TYPE_SECTION:
        formComponent = (
          <RecordSectionHead key={formKey} sectionName={formAttrs.label} />
        )
        break

      default:
        break
    }

    return (
      <div key={formKey} className='slds-m-bottom_x-small'>
        {formComponent}
      </div>
    )
  })

  return formDisplay
}

export const updateForm = (form, name, value) => {
  const copyForm = { ...form }
  const formItem = copyForm[name]

  switch (formItem?.inputType) {
    case INPUT_TYPE_DROPDOWN:
      value = value.selection
      break

    case INPUT_TYPE_NUMBER:
      if (formItem?.inputProps?.readOnly) {
        value = getNumberText(value, 2, true)
      }
      break

    default:
      break
  }

  const updatedFormItem = { ...formItem, value: value ?? '', touched: true }

  const validatedFormItem = validateFormItem(updatedFormItem)

  const updatedForm = Object.assign(form, { [name]: validatedFormItem })

  return { ...updatedForm }
}

export const applyDataToForm = (forms, data) => {
  const updatedForm = Object.entries(forms).reduce((mapped, item) => {
    const [formKey, formAttrs] = item
    let value = data[formKey]

    switch (formAttrs.inputType) {
      case INPUT_TYPE_NUMBER:
        value = toNumber(value)
        break

      case INPUT_TYPE_TIME:
        value = getTimeObjectFromTimeString(value)?.format()
        break

      case INPUT_TYPE_DATE:
        value = dayjs(value).utc(true).format()
        break

      case INPUT_TYPE_DROPDOWN:
        value = [{ id: value, label: value }]
        value = intersectionBy(formAttrs.options, value, 'id')
        break

      case INPUT_TYPE_TEXT:
      default:
        break
    }

    const updatedFormItem = { ...formAttrs, value }
    // const validatedFormItem = validateFormItem(updatedFormItem)
    const updatedForms = Object.assign(forms, { [formKey]: updatedFormItem })

    return { ...updatedForms }
  }, {})

  return updatedForm
}

export const extractForm = (form, onlyTouched) => {
  const formData = Object.entries(form).reduce((formData, [key, attr]) => {
    if (onlyTouched && !attr?.touched) return formData

    let value = attr.value

    switch (attr.inputType) {
      case INPUT_TYPE_NUMBER:
        value = toNumber(value)
        break

      case INPUT_TYPE_DATE:
        value = getDateFormat(value, false)
        break

      case INPUT_TYPE_DATETIME:
        value = getDateFormat(value, true)
        break

      case INPUT_TYPE_DROPDOWN:
        // This required the value to be an array
        value = intersectionBy(
          attr?.inputProps?.options ?? attr?.options,
          value,
          'id'
        )
        break

      default:
        break
    }
    return {
      ...formData,
      [key]: value
    }
  }, {})

  return formData
}

export const clearForm = form => {
  const formData = Object.entries(form).reduce((formData, [key, attr]) => {
    let value = ''

    switch (attr.inputType) {
      case INPUT_TYPE_DATE:
      case INPUT_TYPE_DATETIME:
        value = null
        break

      default:
        break
    }

    let inputProps = {}
    if (attr.inputProps) {
      inputProps = { ...attr.inputProps, errorText: '' }
    }

    const updatedAttr = { ...attr, value, inputProps, touched: false }
    return {
      ...formData,
      [key]: updatedAttr
    }
  }, {})

  return formData
}

export const clearFormTouchedStatus = form => {
  const formData = Object.entries(form).reduce((formData, [key, attr]) => {
    const updatedAttr = { ...attr, touched: false }
    return {
      ...formData,
      [key]: updatedAttr
    }
  }, {})

  return formData
}

export const getFormValidStatus = forms => {
  const validatedForm = Object.entries(forms).reduce(
    (prev, [formKey, formAttrs]) => ({
      ...prev,
      [formKey]: validateFormItem(formAttrs)
    }),
    {}
  )

  const formValid = Object.entries(validatedForm).reduce(
    (currentValid, [, formAttrs]) => {
      const validatedForm = validateFormItem(formAttrs)

      return currentValid && !validatedForm?.inputProps?.errorText
    },
    true
  )

  return { formValid, validatedForm }
}

export const validateFormItem = formItem => {
  let errorText = ''
  let value = formItem.value
  const inputProps = formItem.inputProps

  const label = inputProps?.label
  const required = inputProps?.required
  const maxLength = inputProps?.maxLength

  if (required && isEmpty(value)) {
    errorText = `${label ?? 'Field'} is required.`
  }

  if (maxLength && `${value}`.split('.')[0]?.length > maxLength) {
    errorText = `${label ?? 'Field'} length must not exceed ${maxLength}.`
  }

  switch (formItem.inputType) {
    case INPUT_TYPE_EMAIL:
      errorText = validateEmail(value)
        ? ''
        : `${label ?? 'Field'} must be an email.`
      break

    case INPUT_TYPE_DATE:
      errorText = dayjs(value).isValid()
        ? ''
        : `${label ?? 'Field'} is not a valid date.`
      break

    default:
      break
  }

  if (inputProps?.hidden) errorText = null

  return { ...formItem, inputProps: { ...formItem.inputProps, errorText } }
}

export const validateEmail = email => {
  const pattern =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

  return pattern.test(email)
}

export const applyValidation = (forms, validator) => {
  const updatedForm = Object.entries(forms).reduce((mapped, item) => {
    const [formKey, formAttrs] = item
    const targetValidator = validator?.[formKey] ?? null
    const { inputProps } = formAttrs

    if (!targetValidator) {
      return mapped
    }

    const maxLength = targetValidator.length ?? null
    let updatedInputProps = { ...inputProps, ...targetValidator, maxLength }

    if (targetValidator.type === INPUT_TYPE_NUMBER) {
      const maxValue = parseFloat('9'.repeat(maxLength))
      updatedInputProps = { ...updatedInputProps, maxValue, maxLength }
    }

    const updatedFormItem = { ...formAttrs, inputProps: updatedInputProps }

    const updatedForms = Object.assign(mapped, { [formKey]: updatedFormItem })

    return { ...updatedForms }
  }, forms)

  return updatedForm
}
