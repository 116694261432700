import { performGetRequest, performPatchRequest } from '../helpers/service'

export const getAdminDetail = async () => {
  const response = await performGetRequest('/account')

  return response
}

export const getPricingList = async (page, size) => {
  const response = await performGetRequest('/job-costs', {
    page,
    size
  })

  return response
}

export const patchAccountsWithData = async data => {
  const response = await performPatchRequest('accounts', data)
  return response
}
