import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../components'
import { Button } from '@salesforce/design-system-react'

import { useDispatch, useSelector } from 'react-redux'

import { PAGE_SIZE } from '../../constants'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm,
  getFormValidStatus,
  applyValidation
} from '../../helpers/form'

import newUserForm from './newUserForm'

import {
  createUser,
  getCreateUserResult,
  fetchUserList,
  resetAdminUserResult
} from '../../redux/slices/user'

import useAlert from '../../hooks/useAlert'

import { createSuccessAlert, createErrorAlert } from '../../helpers/alert'
import { getFieldValidator } from '../../redux/slices/global'

const AddNewUserModal = ({
  isOpen,
  modalTitle,
  // userData,
  // onSubmitted,
  onClose
}) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState(newUserForm)
  const [rerenderState, setRerenderState] = useState(false) // for solve re-render validate on dropdown issue
  const dispatch = useDispatch()
  const createUserResult = useSelector(getCreateUserResult)
  const validator = useSelector(getFieldValidator)
  const fireAlert = useAlert()

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => updateForm(prev, name, value))
  })

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSaveAndSendPasswordHandler = useCallback(() => {
    const form = formRef.current
    setRerenderState(!rerenderState) // for solve re-render validate on dropdown issue
    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)

      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData)
        dispatch(createUser(data))
        onClose()
        setFormData(prev => {
          const clearedForm = clearForm(prev)
          return clearedForm
        })
      } else {
        setFormData(checkValidForm.validatedForm)
      }
    }
  }, [formData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    if (createUserResult !== '') {
      if (createUserResult === 201) {
        fireAlert(createSuccessAlert('User has been created', 1500))
      } else {
        fireAlert(createErrorAlert(createUserResult, 1500))
      }
      setTimeout(() => {
        dispatch(resetAdminUserResult())
        dispatch(fetchUserList({ page: 1, size: PAGE_SIZE }))
      }, 1000)
    }
  }, [createUserResult])

  useEffect(() => {
    setFormData(prev => {
      const clearedForm = clearForm(prev)
      return clearedForm
    })
  }, [isOpen])

  useEffect(() => {
    setFormData(prev => applyValidation(prev, validator?.contact))
  }, [validator])

  return (
    <StandardModal
      hasDatePicker={false}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            iconName='save'
            iconCategory='utility'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Save & Send Password via email'
          />
        </div>
      }
    >
      <div className='slds-p-vertical_xx-small slds-text-heading_medium'>
        Add a User
      </div>
      <div className='slds-p-around_x-small'>
        <form ref={formRef} onSubmit={onSaveAndSendPasswordHandler}>
          {formDisplay}
        </form>
      </div>
    </StandardModal>
  )
}

AddNewUserModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  userData: PropTypes.object,
  onSubmitted: PropTypes.func,
  onClose: PropTypes.func
}

export default memo(AddNewUserModal)
