import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getNumberText } from '../../../helpers/number'
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen'
import styled from 'styled-components'

const WordBreakingDiv = styled.div`
  word-break: break-all;
`

const WhiteSpaceBreakingDiv = styled.div`
  white-space: break-spaces;
`

const ValueDisplay = ({ label, value, valueType }) => {
  const isMobile = useCheckMobileScreen()

  let valueDisplay = null
  switch (valueType) {
    case 'currency':
      valueDisplay = '$' + getNumberText(value, 2, true)
      break

    default:
      valueDisplay = value
      break
  }
  return (
    <div className='slds-grid slds-grid-vertical slds-wrap slds-p-around_xx-small'>
      <WhiteSpaceBreakingDiv
        className={`slds-col slds-size_1-of-1 ${
          isMobile ? '' : 'slds-text-heading_small'
        }`}
      >
        {label}
      </WhiteSpaceBreakingDiv>
      <WordBreakingDiv
        className={`slds-col slds-size_1-of-1 ${
          isMobile ? 'slds-text-heading_small' : 'slds-text-heading_medium'
        }`}
      >
        {valueDisplay}
      </WordBreakingDiv>
    </div>
  )
}

ValueDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueType: PropTypes.string
}

export default memo(ValueDisplay)
