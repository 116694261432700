import React, { memo } from 'react'
import PropTypes from 'prop-types'

const WelcomeHeader = ({ headingText, contentText }) => {
  return (
    <div className='slds-p-horizontal_medium'>
      <p className='slds-text-heading_large slds-m-vertical_small'>
        {headingText}
      </p>
      <p className='slds-m-vertical_x-small'>{contentText}</p>
    </div>
  )
}

WelcomeHeader.propTypes = {
  headingText: PropTypes.string,
  contentText: PropTypes.string
}

export default memo(WelcomeHeader)
