import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
  border: none;
  background: inherit;
`

const LinkButton = ({ onClick, children }) => {
  return (
    <StyledButton type='button' onClick={onClick} className='slds-text-link'>
      {children}
    </StyledButton>
  )
}

LinkButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default LinkButton
