import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { LoginButton } from '../../../pages/LoginPage/StyledLoginItems'

const ForgotPasswordEmailSent = ({ onBackClicked }) => {
  return (
    <>
      <p>
        If user exists with that username, you&apos;ll receive a message and
        email with a link to finish resetting your password.
      </p>
      <p className='slds-m-vertical_small'>
        Can&apos;t find the email? Try checking your spam folder.
      </p>
      <p>If your still can&apos;t log in, contact your Account Manager.</p>
      <LoginButton
        className='slds-m-top_small'
        type='button'
        variant='brand'
        label='Return to Login'
        onClick={onBackClicked}
      />
    </>
  )
}

ForgotPasswordEmailSent.propTypes = {
  onBackClicked: PropTypes.func.isRequired
}

export default memo(ForgotPasswordEmailSent)
