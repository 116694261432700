import React, { memo, useCallback, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../../components'
import { Button } from '@salesforce/design-system-react'
import { FormWrapper, ParagraphWrapper } from './StyledAddBankAccountModal'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm
} from '../../../helpers/form'

import bankAccountForm from './bankAccountForm'

import { useDispatch } from 'react-redux'
import {
  resetBankAccountResult,
} from '../../../redux/slices/bankAccount'

const AddBankAccountModal = ({ isOpen, modalTitle, onClose, onSubmitted }) => {
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const [formData, setFormData] = useState(bankAccountForm)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => updateForm(prev, name, value))
  }, [])

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSaveAndSendPasswordHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()

      if (formValid) {
        const data = extractForm(formData)
        onSubmitted(data)
      }
    }
  }, [formData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  const closeModal = () => {
    setFormData(prev => {
      return clearForm(prev)
    })
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      setFormData(prev => {
        return clearForm(prev)
      })
      dispatch(resetBankAccountResult)
    }
  }, [isOpen])

  return (
    <>
      <StandardModal
        hasDatePicker={false}
        isOpen={isOpen}
        modalTitle={modalTitle}
        onClose={closeModal}
        footer={
          <div className='slds-grid slds-grid_align-end'>
            <Button
              className='slds-col test'
              iconName='save'
              iconCategory='utility'
              variant='brand'
              iconPosition='left'
              onClick={onSaveButtonClickedHandler}
              label='Save'
            />
          </div>
        }
      >
        <FormWrapper>
          <div className='slds-p-around_x-small slds-grid slds-wrap slds-grid'>
            <div className='slds-col slds-size_1-of-2'>
              <div className='input-header'>
                <ParagraphWrapper>
                  <p className='input-text'>Bank Account Name</p>
                </ParagraphWrapper>
              </div>
              <div className='input-header '>
                <ParagraphWrapper>
                  <p className='input-text'>BSB</p>
                </ParagraphWrapper>
              </div>
              <div className='input-header '>
                <ParagraphWrapper>
                  <p className='input-text'>Account Number</p>
                </ParagraphWrapper>
              </div>
            </div>
            <div className='slds-col slds-size_1-of-2'>
              <form ref={formRef} onSubmit={onSaveAndSendPasswordHandler}>
                {formDisplay}
              </form>
            </div>
          </div>
        </FormWrapper>
      </StandardModal>
    </>
  )
}

AddBankAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  onSubmitted: PropTypes.func,
  onClose: PropTypes.func
}

export default memo(AddBankAccountModal)
