import { performGetRequest, performPatchRequest, performPostRequest } from "../helpers/service";

export const getUserByUserId = async (userId) => {
    const response = await performGetRequest(`users/${userId}`)
    return response
}

export const patchUserByUserData = async (userData) => {
    const response = await performPatchRequest('users', userData)
    return response
}

export const createNewUser = async(userData) => {
    const response = await performPostRequest('users', userData)
    return response
}

export const getUserList = async (page, size) => {
    const response = await performGetRequest('users', {page: page, size: size})
    return response
}