import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Toast } from '@salesforce/design-system-react'

const StyledToast = styled(Toast)`
  & .slds-notify__content {
    width: 100%;
  }

  @media screen and (max-width: 1023px) {
    min-width: auto;
  }
`

const BulletinBanner = ({
  id,
  variant,
  message,
  dismissible = true,
  duration,
  onClose
}) => {
  return (
    <StyledToast
      className='slds-text-align_center'
      variant={variant}
      labels={{
        details: message
      }}
      duration={duration ?? null}
      onRequestClose={() => onClose(id)}
      dismissible={dismissible}
    />
  )
}

BulletinBanner.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  dismissible: PropTypes.bool,
  duration: PropTypes.number
}

export default memo(BulletinBanner)
