import styled from 'styled-components'

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 2px 0 darkslategrey;
  height: 56px;
  z-index: 100;

  .toolbar_navigator {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
  }

  .spacer {
    flex: 1;
  }

  @media screen and (min-width: 759px) {
    .toggle-btn {
      display: none;
    }

    .toolbar_logo {
      margin-left: 0;
    }
  }
`

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;

  & > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  & > ul > a {
    text-decoration: none;
    padding: 0 0.5rem;
  }

  & > ul > a:hover,
  & > ul > a:active {
    color: rgb(223, 75, 30);
  }

  .toolbar-actions {
    background-color: #f18429;
    color: white;
  }

  @media screen and (max-width: 760px) {
    & > ul {
      display: none;
    }
  }
`

export const StyledLogoContainer = styled.div`
  margin-right: 1rem;
  text-align: center;

  & a {
    text-decoration: none;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
  }
`

export const StyledActionItem = styled.div`
  margin: 0.5rem;

  @media screen and (max-width: 760px) {
    display: none;
  }
`
