export const INPUT_TYPE_TEXT = 'text'
export const INPUT_TYPE_TEXTAREA = 'textarea'
export const INPUT_TYPE_NUMBER = 'number'
export const INPUT_TYPE_EMAIL = 'email'
export const INPUT_TYPE_URL = 'url'
export const INPUT_TYPE_SEARCH = 'search'
export const INPUT_TYPE_DROPDOWN = 'dropdown'
export const INPUT_TYPE_TEL = 'tel'
export const INPUT_TYPE_COLOR = 'color'
export const INPUT_TYPE_PASSWORD = 'password'
export const INPUT_TYPE_CHECKBOX = 'checkbox'
export const INPUT_TYPE_DATE = 'date'
export const INPUT_TYPE_DATETIME = 'datetime'
export const INPUT_TYPE_DATETIME_LOCAL = 'datetime-local'
export const INPUT_TYPE_MONTH = 'month'
export const INPUT_TYPE_TIME = 'time'
export const INPUT_TYPE_WEEK = 'week'
export const INPUT_TYPE_PICKLIST = 'picklist'

export const INPUT_TYPE_SECTION = 'section'
