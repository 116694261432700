import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  createReduxRecordListState,
  createReduxRecordDetailState,
  mapDropdownItemIdFromValue,
  createReduxRecordPostResultState
} from '../../helpers/redux'
import {
  getJobType,
  getSecurityTypeByJobTypeId,
  getQuoteById,
  postCalculateQuotePrice,
  postCreateCustomQuote,
  postQuote,
  patchDeclineQuote,
  getPaymentBankAccounts,
  patchHoldQuote
} from '../../api/quote'
import { getAPIRequestError } from '../../helpers/service'
import { createNewBankAccount } from './bankAccount'

const initialState = {
  jobType: createReduxRecordListState(),
  securityType: createReduxRecordListState(),
  paymentBankAccount: createReduxRecordListState(),
  quoteDetail: createReduxRecordDetailState(),
  createCustomQuoteResult: createReduxRecordDetailState(),
  quoteNumberDetail: createReduxRecordDetailState(),
  declineQuoteResult: createReduxRecordDetailState(),
  holdQuoteResult: createReduxRecordPostResultState()
}

export const fetchJobType = createAsyncThunk(
  'fetchJobType',
  async (_, thunkAPI) => {
    try {
      const response = await getJobType()

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchSecurityTypeByJobTypeId = createAsyncThunk(
  'fetchSecurityTypeByJobTypeId',
  async (JobTypeId, thunkAPI) => {
    try {
      const response = await getSecurityTypeByJobTypeId(JobTypeId)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchPaymentBankAccount = createAsyncThunk(
  'fetchPaymentBankAccount',
  async (jobId, thunkAPI) => {
    try {
      const response = await getPaymentBankAccounts(jobId)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchQuoteDataByJobId = createAsyncThunk(
  'fetchQuoteDataByJobId',
  async (jobId, thunkAPI) => {
    try {
      const response = await getQuoteById(jobId)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const calculateQuotePrice = createAsyncThunk(
  'calculateQuotePrice',
  async (data, thunkAPI) => {
    try {
      const response = await postCalculateQuotePrice(data)

      const responsePayload = response.data

      if (responsePayload?.statusCode !== 200) {
        const errorData = {
          message: responsePayload.message,
          statusCode: responsePayload?.statusCode
        }

        thunkAPI.rejectWithValue({ error: errorData })
      }

      return { ...responsePayload.data, address: data.addressString }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const createCustomRequestQuote = createAsyncThunk(
  'createCustomRequestQuote',
  async (data, thunkAPI) => {
    try {
      const postData = {
        recordTypeId: data?.recordTypeId ?? '',
        description: data?.data?.description ?? '',
        address: data.quoteData?.address?.value ?? '',
        jobTypeId: data.quoteData?.jobType?.value?.[0]?.id ?? '',
        securityTypeId: data.quoteData?.securityType?.value?.[0]?.id ?? '',
        clientRef: data.quoteData?.clientRef?.value ?? ''
      }
      const response = await postCreateCustomQuote(postData)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const createQuote = createAsyncThunk(
  'createQuote',
  async (date, thunkAPI) => {
    try {
      const data = {
        createdDate: date
      }
      const response = await postQuote(data)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const declineQuote = createAsyncThunk(
  'declineQuote',
  async (data, thunkAPI) => {
    try {
      const response = await patchDeclineQuote(data.quoteId, data.data)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const holdQuote = createAsyncThunk(
  'holdQuote',
  async (data, thunkAPI) => {
    try {
      const response = await patchHoldQuote(data.quoteId)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const quote = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    resetQuotePageState: () => initialState,
    resetPaymentBankAccountState: state => {
      state.paymentBankAccount = initialState.paymentBankAccount
    }
  },
  extraReducers: builder => {
    //fetch Job Type to show in picklist
    builder.addCase(fetchJobType.pending, state => {
      state.jobType.loading = true
    })
    builder.addCase(fetchJobType.fulfilled, (state, { payload }) => {
      state.jobType.loading = false
      state.jobType.records = payload.map(item => {
        return mapDropdownItemIdFromValue(item)
      })
      state.jobType.statusCode = 200
    })
    builder.addCase(fetchJobType.rejected, (state, { payload }) => {
      state.jobType.loading = false
      state.jobType.records = []
      state.jobType.error = payload?.error?.message
      state.jobType.statusCode = 404
    })

    //fetch Security Type to show in picklist
    builder.addCase(fetchSecurityTypeByJobTypeId.pending, state => {
      state.securityType.loading = true
    })
    builder.addCase(
      fetchSecurityTypeByJobTypeId.fulfilled,
      (state, { payload }) => {
        state.securityType.loading = false
        state.securityType.records = payload.map(item => {
          return mapDropdownItemIdFromValue(item)
        })
        state.securityType.statusCode = 200
      }
    )
    builder.addCase(
      fetchSecurityTypeByJobTypeId.rejected,
      (state, { payload }) => {
        state.securityType.loading = false
        state.securityType.records = []
        state.securityType.error = payload?.error?.message
        state.securityType.statusCode = 404
      }
    )

    //fetch Security Type to show in picklist
    builder.addCase(fetchPaymentBankAccount.pending, state => {
      state.paymentBankAccount.loading = true
    })
    builder.addCase(fetchPaymentBankAccount.fulfilled, (state, { payload }) => {
      state.paymentBankAccount.loading = false
      state.paymentBankAccount.records = payload.map(item => {
        return mapDropdownItemIdFromValue(item)
      })
      state.paymentBankAccount.statusCode = 200
    })
    builder.addCase(fetchPaymentBankAccount.rejected, (state, { payload }) => {
      state.paymentBankAccount.loading = false
      state.paymentBankAccount.records = []
      state.paymentBankAccount.error = payload?.error?.message
      state.paymentBankAccount.statusCode = 404
    })

    //get Existing Quote to display in Existing Quote Page
    builder.addCase(fetchQuoteDataByJobId.pending, state => {
      state.quoteDetail.loading = true
    })
    builder.addCase(fetchQuoteDataByJobId.fulfilled, (state, { payload }) => {
      // console.log('payload:', payload)
      state.quoteDetail.loading = false
      state.quoteDetail.record = payload
      state.quoteDetail.statusCode = 200
    })
    builder.addCase(fetchQuoteDataByJobId.rejected, (state, { payload }) => {
      state.quoteDetail.loading = false
      state.quoteDetail.error = payload?.error
      state.quoteDetail.statusCode = payload?.error.statusCode
    })

    // After clicked on get quote button, get quote data to display
    builder.addCase(calculateQuotePrice.pending, state => {
      state.quoteDetail.loading = true
    })
    builder.addCase(calculateQuotePrice.fulfilled, (state, { payload }) => {
      state.quoteDetail.loading = false
      state.quoteDetail.record = payload
        ? {
            ...payload,
            id: payload?.jobId,
            quoteNo: payload?.quoteNumber,
            chargeIfSuccessful: payload?.successJobCost,
            chargeIfUnsuccessful: payload?.unsuccessJobCost
          }
        : null
      state.quoteDetail.statusCode = 200
    })
    builder.addCase(calculateQuotePrice.rejected, (state, { payload }) => {
      state.quoteDetail.loading = false
      state.quoteDetail.error = payload?.error?.message
      state.quoteDetail.statusCode = 400 // Fix statusCode to 400, since this API might return 404 and we should not redirect to /notFound with this case
    })

    // post Create Custom Request Quote by Data
    builder.addCase(createCustomRequestQuote.pending, state => {
      state.createCustomQuoteResult.loading = true
    })
    builder.addCase(
      createCustomRequestQuote.fulfilled,
      (state, { payload }) => {
        state.createCustomQuoteResult.loading = false
        state.createCustomQuoteResult.record = payload
        state.createCustomQuoteResult.statusCode = 201
      }
    )
    builder.addCase(createCustomRequestQuote.rejected, (state, { payload }) => {
      state.createCustomQuoteResult.loading = false
      state.createCustomQuoteResult.error = payload?.error
      state.createCustomQuoteResult.statusCode = payload?.error.statusCode
    })

    //create Quote with present date in format DD/MM/YYYY
    builder.addCase(createQuote.pending, state => {
      state.quoteNumberDetail.loading = true
    })
    builder.addCase(createQuote.fulfilled, (state, { payload }) => {
      state.quoteNumberDetail.loading = false
      state.quoteNumberDetail.record = payload
    })
    builder.addCase(createQuote.rejected, (state, { payload }) => {
      state.quoteNumberDetail.loading = false
      state.quoteNumberDetail.error = payload?.error
    })

    //Patch decline Quote by CaseId and reason
    builder.addCase(declineQuote.pending, state => {
      state.declineQuoteResult.loading = true
    })
    builder.addCase(declineQuote.fulfilled, (state, { payload }) => {
      state.declineQuoteResult.loading = false
      state.declineQuoteResult.record = payload
      state.declineQuoteResult.statusCode = 200
    })
    builder.addCase(declineQuote.rejected, (state, { payload }) => {
      state.declineQuoteResult.loading = false
      state.declineQuoteResult.error = payload?.error
      state.declineQuoteResult.statusCode = payload?.error.statusCode
    })

    //Patch decline Quote by CaseId and reason
    builder.addCase(holdQuote.pending, state => {
      state.holdQuoteResult.loading = true
    })
    builder.addCase(holdQuote.fulfilled, (state, { payload }) => {
      state.holdQuoteResult.loading = false
      state.holdQuoteResult.record = payload
      state.holdQuoteResult.statusCode = 200
    })
    builder.addCase(holdQuote.rejected, (state, { payload }) => {
      state.holdQuoteResult.loading = false
      state.holdQuoteResult.error = payload?.error
      state.holdQuoteResult.statusCode = payload?.error.statusCode
    })

    builder.addCase(createNewBankAccount.fulfilled, (state, { payload }) => {
      state.paymentBankAccount.records = [
        ...state.paymentBankAccount.records,
        {
          id: payload?.data?.id,
          label: payload?.data?.name
        }
      ]
    })
  }
})

export const { resetQuotePageState, resetPaymentBankAccountState } = quote.actions
export default quote.reducer

export const getJobTypeData = createSelector(
  state => state.quote.jobType,
  jobType => jobType
)

export const getSecurityTypeData = createSelector(
  state => state.quote.securityType,
  securityType => securityType
)

export const getPaymentBankAccountData = createSelector(
  state => state.quote.paymentBankAccount,
  paymentBankAccount => paymentBankAccount
)

export const getQuoteDetail = createSelector(
  state => state.quote.quoteDetail,
  quoteDetail => quoteDetail
)

export const getCreateCustomQuoteResult = createSelector(
  state => state.quote.createCustomQuoteResult,
  createCustomQuoteResult => createCustomQuoteResult
)

export const getQuoteNumberDetail = createSelector(
  state => state.quote.quoteNumberDetail,
  quoteNumberDetail => quoteNumberDetail
)

export const getDeclineQuoteResult = createSelector(
  state => state.quote.declineQuoteResult,
  declineQuoteResult => declineQuoteResult
)

export const getHoldQuoteResult = createSelector(
  state => state.quote.holdQuoteResult,
  holdQuoteResult => holdQuoteResult
)
