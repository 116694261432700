import { toNumber } from 'lodash'

export const getNumberText = (numberText, decimalPoint, thousandSaparator) => {
  const decimalCount = toNumber(decimalPoint)
  let float = parseFloat(numberText)

  if (isNaN(float)) {
    float = 0
  }

  if (thousandSaparator) {
    return float.toLocaleString('en-au', {
      minimumFractionDigits: decimalCount,
      maximumFractionDigits: decimalCount
    })
  }
  return float.toFixed(decimalCount)
}
