import { INPUT_TYPE_DATE, INPUT_TYPE_TEXTAREA } from '../../../constants'

export default {
  comment: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Reason for hold',
      type: INPUT_TYPE_TEXTAREA,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  holdReleaseDate: {
    inputType: INPUT_TYPE_DATE,
    inputProps: {
      label: 'Hold Release Date',
      errorText: null,
      required: true
    },
    value: null,
    valid: false
  }
}
