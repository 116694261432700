import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledMenu = styled.nav`
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  padding-top: 56px;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 90;
  transform: translateX(-120%);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateX(0);
  }

  @media screen and (min-width: 759px) {
     {
      display: none;
    }
  }
`

export const StyledList = styled.ul`
  padding: 0.5rem;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`

export const StyledLink = styled(Link)`
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;

  &:hover,
  &:active {
    background-color: #fa923f;
  }
`

export const StyledListItem = styled.li`
  &.button-list-item {
    padding: 0.5rem;
  }

  .list-item-action {
    background-color: #f18429;
    color: white;
  }

  &.divider {
    width: 100%;
    margin: 0.5rem 0;
  }
`
