import React from 'react'
import PropTypes from 'prop-types'

import { getFormDisplay } from '../../../helpers/form'
import {
  LoginButton,
  LoginUnorderedList
} from '../../../pages/LoginPage/StyledLoginItems'
import { Icon } from '@salesforce/design-system-react'

const ResetPasswordForm = ({
  resetPasswordFormText,
  passwordValid,
  passwordValidation,
  form,
  submitDisabled,
  onSubmitted,
  onInputChanged,
  errorText
}) => {
  const formDisplay = getFormDisplay(form, onInputChanged)
  const passwordValidationDisplay = Object.entries(passwordValidation).map(
    ([itemKey, itemAttr]) => {
      let validIcon = null

      if (passwordValid[itemKey]) {
        validIcon = (
          <Icon
            className='slds-m-left_x-small'
            category='utility'
            name='success'
            size='xx-small'
          />
        )
      }
      return (
        <li key={itemKey}>
          {itemAttr.label.replace('$1', itemAttr.value)}
          {validIcon}
        </li>
      )
    }
  )
  return (
    <div className='slds-p-around_x-small'>
      {resetPasswordFormText}
      <LoginUnorderedList className='slds-list_dotted slds-m-vertical_x_small'>
        {passwordValidationDisplay}
      </LoginUnorderedList>
      <form onSubmit={onSubmitted}>
        {formDisplay}
        <div className='slds-grid slds-m-top_small'>
          <LoginButton
            type='submit'
            variant='brand'
            label='Reset Password'
            disabled={submitDisabled}
          />
        </div>
      </form>
      <p className='slds-text-color_destructive slds-m-top_small'>
        {errorText}
      </p>
    </div>
  )
}

ResetPasswordForm.propTypes = {
  resetPasswordFormText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  form: PropTypes.object.isRequired,
  passwordValid: PropTypes.object,
  passwordValidation: PropTypes.object,
  onSubmitted: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  errorText: PropTypes.string
}

export default ResetPasswordForm
