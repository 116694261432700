export const PDF_FILE_TYPE = 'application/pdf'
export const TIFF_FILE_TYPE = 'image/tiff'
export const GIF_FILE_TYPE = 'image/gif'
export const JPEG_FILE_TYPE = 'image/jpeg'
export const PNG_FILE_TYPE = 'image/png'
export const BMP_FILE_TYPE = 'image/bmp'
export const WEBP_FILE_TYPE = 'image/webp'
export const CSV_FILE_TYPE = 'text/csv'
export const XLSX_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const FILE_PROVISION_TYPE_TEMPLATE = 'template'
export const FILE_PROVISION_TYPE_OTHER = 'other'

export const sumMaxSize = 26214400
export const fileMaxSize = 10485760