import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../common'
import { Button } from '@salesforce/design-system-react'

import { useSelector } from 'react-redux'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm,
  applyValidation,
  getFormValidStatus
} from '../../../helpers/form'

import jobActionUpdateRequestForm from './JobActionUpdateModalform'

import { getFieldValidator } from '../../../redux/slices/global'

const JobActionUpdateModal = ({
  isOpen,
  modalTitle,
  jobId,
  onClose,
  onSaveActionUpdate,
  jobDocumentType
}) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState(jobActionUpdateRequestForm)
  const validator = useSelector(getFieldValidator)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => {
      const updatedForm = updateForm(prev, name, value)

      return updatedForm
    })
  })

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)

      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData)
        const formedData = {
          ...data,
          outcome: data.outcome?.[0]?.id,
          remainOnHold: data.remainOnHold ? true : false
        }
        onSaveActionUpdate({ ...formedData })
      } else {
        setFormData(checkValidForm.validatedForm)
      }
    }
  }, [formData, jobId])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    setFormData(prev => {
      // eslint-disable-next-line no-unused-vars
      const { remainOnHold, ...others } = prev
      if (jobDocumentType) {
        return others
      }
      return jobActionUpdateRequestForm
    })
  }, [jobDocumentType])

  useEffect(() => {
    setFormData(prev => applyValidation(prev, validator?.postComment))
  }, [validator])

  useEffect(() => {
    setFormData(prev => {
      const clearedForm = clearForm(prev)
      return clearedForm
    })
  }, [isOpen])

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            iconName='save'
            iconCategory='utility'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Save'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>
        <div className='slds-text-align_center'>{jobDocumentType ?? null}</div>
        <form ref={formRef} onSubmit={onSubmittedHandler}>
          {formDisplay}
        </form>
      </div>
    </StandardModal>
  )
}

JobActionUpdateModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  jobId: PropTypes.string,
  onClose: PropTypes.func,
  onSaveActionUpdate: PropTypes.func,
  jobDocumentType: PropTypes.string
}

export default memo(JobActionUpdateModal)
