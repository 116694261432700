import React, { useEffect } from 'react'
import styles from './App.module.css'

import { Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { AdminOnlyRoute, Layout, PrivateRoute, PublicOnlyRoute } from './hoc'
import {
  adminOnlyRoutes,
  privateRoutes,
  publicRoutes,
  unauthorizedOnlyPages
} from './routes/routes'
import { fetchGlobalSetting } from './redux/slices/global'
import { NotFoundPage } from './pages'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchGlobalSetting())
  }, [])

  const noAuthOnlyPages = unauthorizedOnlyPages.map(route => (
    <Route
      key={route.pathname}
      path={route.pathname ?? '/'}
      element={<PublicOnlyRoute>{route.component ?? null}</PublicOnlyRoute>}
    />
  ))

  const publicPages = publicRoutes.map(route => (
    <Route
      key={route.pathname}
      path={route.pathname ?? '/'}
      element={route.component ?? null}
    />
  ))

  const privatePages = privateRoutes.map(route => (
    <Route
      key={route.pathname}
      path={route.pathname ?? '/'}
      element={route.component ?? null}
      index={route.index}
    ></Route>
  ))

  const adminOnlyPages = adminOnlyRoutes.map(route => (
    <Route
      key={route.pathname}
      path={route.pathname ?? '/'}
      element={route.component ?? null}
      index={route.index}
    ></Route>
  ))

  return (
    <div className={styles.App}>
      <Routes>
        {noAuthOnlyPages}
        {publicPages}
        <Route
          path='/'
          element={
            <AdminOnlyRoute>
              <Layout />
            </AdminOnlyRoute>
          }
        >
          {adminOnlyPages}
        </Route>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          {privatePages}
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
