import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {  getInvoiceList } from '../../api/invoice'
import {
  createReduxRecordListState,
  createReduxRecordDetailState,
  updateReduxRecords
} from '../../helpers/redux'
import { extractPagination, getAPIRequestError } from '../../helpers/service'
import {downloadFileByFileId} from "../../api/document";

export const fetchInvoiceList = createAsyncThunk(
  'fetchInvoiceList',
  async ({ page = 1 }, thunkAPI) => {
    try {
      const response = await getInvoiceList(page)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const downloadInvoiceFile = createAsyncThunk(
  'downloadInvoiceFile',
  async (fileRequest, thunkAPI) => {
    try {
      return await downloadFileByFileId(fileRequest.invoiceDocumentId);
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const initialState = {
  invoices: createReduxRecordListState(),
  downloadFileResult: createReduxRecordDetailState()
}

const invoice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resetInvoiceListState: () => initialState,
    resetDownloadResult: state => {
      state.downloadFileResult = initialState.downloadFileResult
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchInvoiceList.pending, state => {
      state.invoices.loading = true
    })
    builder.addCase(fetchInvoiceList.fulfilled, (state, { payload }) => {
      state.invoices.loading = false
      state.invoices.records = updateReduxRecords(
        state.invoices.records,
        payload.data,
        payload.pagination?.page
      )
      state.invoices.page = payload.pagination?.page
      state.invoices.size = payload.pagination?.size
      state.invoices.total = payload.pagination?.total
      state.invoices.totalPages = payload.pagination?.totalPages
      state.invoices.error = null
    })
    builder.addCase(fetchInvoiceList.rejected, (state, { payload }) => {
      state.invoices.loading = false
      state.invoices.records = []
      state.invoices.error = payload?.error.message
    })

    //Download File by Id
    builder.addCase(downloadInvoiceFile.pending, state => {
      state.downloadFileResult.loading = true
    })
    builder.addCase(downloadInvoiceFile.fulfilled, state => {
      state.downloadFileResult.loading = false
    })
    builder.addCase(downloadInvoiceFile.rejected, (state, { payload }) => {
      state.downloadFileResult.loading = false
      state.downloadFileResult.error = payload?.error
    })
  }
})

export const { resetInvoiceListState, resetDownloadResult } = invoice.actions

export default invoice.reducer

export const getInvoiceListData = createSelector(
  state => state.invoice.invoices,
  invoices => invoices
)

export const getDownloadFileResult = createSelector(
  state => state.invoice.downloadFileResult,
  downloadFileResult => downloadFileResult
)
