import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Tabs } from '@salesforce/design-system-react'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
  & .slds-tabs_scoped__nav {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 0.25rem;
  }
`

const ScrollableTabs = ({
  children,
  variant = 'scoped',
  id,
  onTabSelect,
  selectedIndex,
  ...props
}) => {
  return (
    <StyledTabs
      id={id}
      selectedIndex={selectedIndex}
      variant={variant}
      onSelect={onTabSelect}
      className='slds-p-horizontal_small'
      {...props}
    >
      {children}
    </StyledTabs>
  )
}

ScrollableTabs.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  variant: PropTypes.string,
  onTabSelect: PropTypes.func,
  selectedIndex: PropTypes.number
}

export default memo(ScrollableTabs)
