import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@salesforce/design-system-react'

const StandardCheckbox = ({
  name,
  label,
  onChange,
  value,
  readOnly,
  ...props
}) => {
  const onInputChangedHandler = useCallback(
    e => {
      const sourceEvent = { ...e }
      const { name, checked } = e.target

      if (!readOnly) {
        onChange(checked, name, sourceEvent)
      }
    },
    [onChange, readOnly]
  )

  return (
    <Checkbox
      name={name}
      labels={{ label }}
      onChange={onInputChangedHandler}
      checked={value}
      readOnly={readOnly}
      {...props}
    />
  )
}

StandardCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  readOnly: PropTypes.bool
}

export default StandardCheckbox
