import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import getColumnDefinitions from './outstandingTaskColumns'
import {
  filterRecords,
  generateTableColumns,
  sortRecords
} from '../../../helpers/dataTable'
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen'

import { CardSearchHeader, LightningDataTable } from '../../common'

import { Card, Icon } from '@salesforce/design-system-react'

import { TABLE_HEIGHT_HOME } from '../../../constants'

const OutstandingTaskTable = ({ items, onLoadMore, totalOutstandingTasks }) => {
  const isMobile = useCheckMobileScreen()

  const [filterText, setFilterText] = useState('')
  const [internalTaskList, setInternalTaskList] = useState([])
  const [filtering, setFiltering] = useState(false)
  const [currentSortColumn, setCurrentSortColumn] = useState(null)
  const [sortDirectionMemo, setSortDirectionMemo] = useState({})

  const columnDefinitions = getColumnDefinitions({
    sortColumn: currentSortColumn,
    sortDirectionMemo
  })

  const filterChangedHandler = useCallback(event => {
    setFilterText(event?.target?.value)
  }, [])

  const onSearchHandler = useCallback(() => {
    let computedData = filterRecords(filterText, items, columnDefinitions)

    if (currentSortColumn != null) {
      computedData = sortRecords(
        computedData,
        currentSortColumn,
        sortDirectionMemo
      )
    }

    setInternalTaskList(computedData)
    setFiltering(!!filterText)
  }, [filterText, items, currentSortColumn, sortDirectionMemo])

  const onSortHandler = useCallback(
    sortColumn => {
      const { sortDirection, property: sortProperty } = sortColumn

      // This method may return null if data provided item is not array
      setInternalTaskList(
        prev => sortRecords(prev, sortProperty, sortDirection) ?? prev
      )
      setCurrentSortColumn(sortProperty)
      setSortDirectionMemo(prev => ({
        ...prev,
        [sortProperty]: sortDirection
      }))
    },
    []
  )

  useEffect(() => {
    onSearchHandler()
    return () => {
      setInternalTaskList([])
    }
  }, [items])

  let columnDefs = columnDefinitions
  if (isMobile) {
    columnDefs = columnDefinitions.filter((col, index) => index < 2)
  }
  const columns = generateTableColumns(columnDefs)

  return (
    <Card
      className='slds-text-align_left'
      header={
        <CardSearchHeader
          headerText={`Outstanding Tasks (${totalOutstandingTasks})`}
          onFilterChange={filterChangedHandler}
          onSearch={onSearchHandler}
          icon={<Icon category='standard' name='task' />}
        />
      }
    >
      <LightningDataTable
        columns={columns}
        items={internalTaskList}
        id='tasksTable'
        hasMore={items.length < totalOutstandingTasks}
        totalItems={totalOutstandingTasks}
        onLoadMore={onLoadMore}
        tableHeight={TABLE_HEIGHT_HOME}
        isFiltering={filtering}
        onSort={onSortHandler}
      />
    </Card>
  )
}

OutstandingTaskTable.propTypes = {
  items: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  totalOutstandingTasks: PropTypes.number
}

export default memo(OutstandingTaskTable)
