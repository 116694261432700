import { FAQPageWrapper, FAQContentWrapper } from './StyledFAQPageItems'
import FAQPageContent from '../../containers/FAQPageContent/FAQPageContent'

const FAQPage = () => {
  return (
    <FAQPageWrapper>
      <FAQContentWrapper>
        <FAQPageContent />
      </FAQContentWrapper>
    </FAQPageWrapper>
  )
}

export default FAQPage
