import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Input } from '@salesforce/design-system-react'
import { debounce } from 'lodash'
import { INPUT_TYPE_NUMBER } from '../../../constants'

const StyledInput = styled(Input)`
  & input.login-input {
    height: 2.75rem;
  }
`

const StandardInput = ({
  type,
  name,
  label,
  value,
  required,
  disabled,
  readOnly,
  errorText,
  onChange,
  maxLength,
  maxValue,
  decimal,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value)

  const debouncedChangeHandler = useCallback(debounce(onChange, 50), [onChange])

  const onInputChangedHandler = useCallback(e => {
    const sourceEvent = { ...e }
    let { name, value } = e.target

    if (type === INPUT_TYPE_NUMBER && decimal) {
      const [digit, fraction] = `${value}`.split('.')

      if (fraction) {
        value = `${digit}.${fraction.slice(0, decimal)}`
      }

      setInternalValue(value)
    } else {
      setInternalValue(value)
    }
    debouncedChangeHandler(value, name, sourceEvent)
  }, [])

  useEffect(() => {
    setInternalValue(value)
    return () => {
      debouncedChangeHandler.cancel()
      setInternalValue('')
    }
  }, [value])

  return (
    <StyledInput
      type={type}
      name={name}
      label={label}
      value={internalValue}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      errorText={errorText}
      onChange={onInputChangedHandler}
      maxLength={`${maxLength}`}
      maxValue={maxValue}
      {...props}
    ></StyledInput>
  )
}

StandardInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errorText: PropTypes.string,
  maxLength: PropTypes.number,
  maxValue: PropTypes.number,
  decimal: PropTypes.number
}

export default memo(StandardInput)
