import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ImageWrapper = styled.div`
  text-align: center;
`
function ContactUsContentColumn({ contactUsText, phone, email, image }) {
  return (
    <>
      <div className='slds-p-around_x-small'>
        <p className='left-side-header slds-text-heading_medium'>
          Portal Related Question?
        </p>
        <br />
        <p className='left-side-content'>
          If you have a Question related to your Portal use or access, please
          take a look at our FAQ Page
        </p>
      </div>
      <p className='left-side-header contact-us-head slds-text-align_left slds-p-around_x-small slds-text-heading_medium'>
        Contact Us
      </p>
      <div className='slds-grid slds-wrap'>
        <div className='slds-p-around_x-small slds-col slds-size_1-of-1 slds-large-size_1-of-2'>
          <p className='left-side-content'>{contactUsText}</p>
        </div>
        <div className='slds-p-around_x-small slds-col slds-size_1-of-1 slds-large-size_1-of-2'>
          <p className='right-side-header slds-text-title_bold'>
            If you have any questions - we welcome your call.
          </p>
          <p className='right-side-header slds-p-vertical_small slds-text-title_bold phone-head'>
            Phone
          </p>
          <p className='right-side-content'>{phone}</p>
          <p className='right-side-header slds-p-vertical_small slds-text-title_bold'>
            Email
          </p>
          <p className='right-side-content'>{email}</p>
        </div>
      </div>
      <div>
      <ImageWrapper>
        <img src={image} alt='pic' />
      </ImageWrapper>
      </div>
    </>
  )
}

ContactUsContentColumn.propTypes = {
  contactUsText: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.string
}

export default ContactUsContentColumn
