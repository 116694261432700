import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { setAppTitle } from '../../helpers/appState'
import {
  LoginButton,
  LoginContentContainer,
  LoginHeading
} from '../../pages/LoginPage/StyledLoginItems'
import { getPortalName } from '../../redux/slices/global'

const pageTitle = 'Reset Password'

const ResetPasswordSessionExpiredPanel = () => {
  const navigate = useNavigate()

  const portalName = useSelector(getPortalName)

  const onReturnClickedHandler = useCallback(() => {
    navigate('/login')
  }, [])

  useEffect(() => {
    setAppTitle(portalName, pageTitle)
  }, [portalName])

  return (
    <>
      <LoginHeading>Link Expired</LoginHeading>
      <LoginContentContainer style={{ padding: '1rem' }}>
        <div className='slds-grid slds-grid_align-center slds-text-align_center slds-p-around_small'>
          This link has expired or has previously been used. Please request a
          new password reset to continue.
        </div>
        <LoginButton
          className='slds-m-top_small'
          type='button'
          variant='brand'
          label='Return to Login'
          onClick={onReturnClickedHandler}
        />
      </LoginContentContainer>
    </>
  )
}

export default ResetPasswordSessionExpiredPanel
