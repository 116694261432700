import styled from 'styled-components'

export const ContactUsTextWrapper = styled.p`
  & .right-side-content {
    font-size: 16px;
    font-weight: bold;
    color: orange;
  }
  & .right-side-header {
    font-size: 20px;
    font-weight: bold;
    color: #575757;
  }
  & .left-side-header {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }

  & .left-side-content {
    font-size: 16px;
    color: black;
  }

  & .contact-us-head {
    padding-top: 50px;
  }

  & .phone-head {
    padding-top: 30px;
  }
`

export const ContactUsContent = styled.div``
