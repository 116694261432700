import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { postQuote } from '../../api/quote'
import {
  getOCRFileTypes,
  patchUpdateJobFileType,
} from '../../api/submitJob'
import { postUploadJobFile } from '../../api/document'
import { getDateFormat } from '../../helpers/date'
import {
  createReduxRecordListState,
  createReduxRecordPostResultState,
  mapDropdownItemIdFromValue
} from '../../helpers/redux'
import { getAPIRequestError } from '../../helpers/service'

const initialState = {
  ocrFileTypes: createReduxRecordListState(),
  submitJobStatus: createReduxRecordPostResultState(),
  targetJob: null,
  uploadedFilesStatus: {}
}

export const fetchOCRFileTypes = createAsyncThunk(
  'fetchOCRFileTypes',
  async (_, thunkAPI) => {
    try {
      const response = await getOCRFileTypes()

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const updateJobFileUploadStatus = (thunkAPI, file, fileUploadStatus) => {
  thunkAPI.dispatch(toggleUploadFileStatus({ file, fileUploadStatus }))
}

export const submitJobAndUpdateStatus = createAsyncThunk(
  'submitJobAndUpdateStatus',
  async (data, thunkAPI) => {
    thunkAPI.dispatch(resetUploadedFileStatus())

    let jobId = data.jobId
    const jobInstructionFile = data.jobInstructionFile
    const jobSupportFiles = data.supportingFiles
    const today = getDateFormat(new Date(), false)

    let shouldPatchData = true
    let errorMessage = null

    if (!jobId) {
      try {
        const response = await postQuote({ createdDate: today })

        thunkAPI.dispatch(setTargetJob(response.data))

        jobId = response.data.id
      } catch (error) {
        const errorData = getAPIRequestError(error)

        // console.log('errorData', errorData)

        shouldPatchData = false

        return thunkAPI.rejectWithValue({ error: errorData })
      }
    }

    try {
      updateJobFileUploadStatus(thunkAPI, jobInstructionFile, 'loading')

      await postUploadJobFile(jobId, jobInstructionFile)

      updateJobFileUploadStatus(thunkAPI, jobInstructionFile, 'success')
    } catch (error) {
      // const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      shouldPatchData = false
      errorMessage = 'Upload job file error! Please check and try again.'

      updateJobFileUploadStatus(thunkAPI, jobInstructionFile, 'error')
    }

    // Upload supporting files
    await Promise.all(
      jobSupportFiles.map(async file => {
        try {
          updateJobFileUploadStatus(thunkAPI, file, 'loading')

          const response = await postUploadJobFile(jobId, file)

          if (response.status === 200) {
            updateJobFileUploadStatus(thunkAPI, file, 'success')
          }
        } catch (error) {
          // const errorData = getAPIRequestError(error)

          // console.log('errorData', errorData)

          shouldPatchData = false
          errorMessage =
            'Upload support file error! Please check and try again.'

          updateJobFileUploadStatus(thunkAPI, file, 'error')
        }
      })
    )

    if (shouldPatchData) {
      try {
        const response = await patchUpdateJobFileType(jobId, {
          jobFileProvisionType: data.jobFileProvisionType,
          jobFileTemplateId: data.jobFileTemplateId,
          status: data.status,
          remittanceInterventionRequired: data.remittanceInterventionRequired,
          remittanceBankAccount: data.remittanceBankAccount,
          billerCode: data.billerCode,
          referenceNumber: data.referenceNumber,
          skipBpayDetails: data.skipBpayDetails,
          bankFileType: data.bankFileType,
          additionalJobNotes: data.additionalJobNotes
        })

        return {
          statusCode: response.status
        }
      } catch (error) {
        const errorData = getAPIRequestError(error)

        // console.log('errorData', errorData)

        return thunkAPI.rejectWithValue({ error: errorData })
      }
    } else {
      return thunkAPI.rejectWithValue({
        error: { statusCode: 400, message: errorMessage }
      })
    }
  }
)

const submitJob = createSlice({
  name: 'submitJob',
  initialState,
  reducers: {
    resetSubmitJobPageState: () => initialState,
    resetUploadedFileStatus: state => {
      state.uploadedFilesStatus = {}
    },
    toggleUploadFileStatus: (state, { payload }) => {
      if (payload.file?.originalName) {
        state.uploadedFilesStatus = {
          ...state.uploadedFilesStatus,
          [payload.file.originalName]: payload.fileUploadStatus
        }
      }
    },
    setTargetJob: (state, { payload }) => {
      state.targetJob = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOCRFileTypes.pending, state => {
      state.ocrFileTypes.loading = true
    })
    builder.addCase(fetchOCRFileTypes.fulfilled, (state, { payload }) => {
      state.ocrFileTypes.loading = false
      state.ocrFileTypes.records = payload.map(item => {
        return mapDropdownItemIdFromValue(item)
      })
      state.ocrFileTypes.statusCode = 200
    })
    builder.addCase(fetchOCRFileTypes.rejected, (state, { payload }) => {
      state.ocrFileTypes.loading = false
      state.ocrFileTypes.records = []
      state.ocrFileTypes.error = payload?.error?.message
      state.ocrFileTypes.statusCode = 404
    })

    builder.addCase(submitJobAndUpdateStatus.pending, state => {
      state.submitJobStatus.loading = true
      state.submitJobStatus.message = null
      state.submitJobStatus.statusCode = null
      state.submitJobStatus.error = null
    })
    builder.addCase(
      submitJobAndUpdateStatus.fulfilled,
      (state, { payload }) => {
        state.submitJobStatus.loading = false
        state.submitJobStatus.message = payload.message
        state.submitJobStatus.statusCode = payload.statusCode
      }
    )
    builder.addCase(submitJobAndUpdateStatus.rejected, (state, { payload }) => {
      state.submitJobStatus.loading = false
      state.submitJobStatus.error = payload?.error?.message
      state.submitJobStatus.statusCode = payload?.error?.statusCode
    })
  }
})

export const {
  resetSubmitJobPageState,
  toggleUploadFileStatus,
  resetUploadedFileStatus,
  setTargetJob
} = submitJob.actions
export default submitJob.reducer

export const getOCRFileTypeData = createSelector(
  state => state.submitJob.ocrFileTypes,
  ocrFileTypes => ocrFileTypes.records
)

export const getUploadedFilesStatus = createSelector(
  state => state.submitJob.uploadedFilesStatus,
  uploadedFilesStatus => uploadedFilesStatus
)

export const getSubmitJobStatus = createSelector(
  state => state.submitJob.submitJobStatus,
  submitJobStatus => submitJobStatus
)

export const getTargetJob = createSelector(
  state => state.submitJob.targetJob,
  targetJob => targetJob
)
