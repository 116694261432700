import React, { memo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../../components'
import { Button } from '@salesforce/design-system-react'
import { FormWrapper, ParagraphWrapper } from './StyledAddBankAccountModal'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm
} from '../../../helpers/form'

import bankAccountForm from './bankAccountForm'

// import { useDispatch } from 'react-redux'

const EditBankAccountModal = ({
  isOpen,
  modalTitle,
  bankAccountData,
  onClose,
  onSubmitted
}) => {
  // const dispatch = useDispatch()
  const [formData, setFormData] = useState(bankAccountForm)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => {
      return updateForm(prev, name, value)
    })
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const data = extractForm(formData)
    closeModal()
    onSubmitted({ data, bankAccountId: bankAccountData.id })
  }, [formData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  const closeModal = () => {
    setFormData(prev => {
      return clearForm(prev)
    })
    onClose()
  }

  useEffect(() => {
    setFormData(prev => {
      return clearForm(prev)
    })
  }, [])

  useEffect(() => {
    setFormData(prev => {
      updateForm(prev, 'name', bankAccountData?.name)
      updateForm(prev, 'bsb', bankAccountData?.bsb)
      return updateForm(prev, 'accountNumber', bankAccountData?.accountNumber)
    })
  }, [bankAccountData])

  return (
    <>
      <StandardModal
        hasDatePicker={false}
        isOpen={isOpen}
        modalTitle={modalTitle}
        onClose={closeModal}
        footer={
          <div className='slds-grid slds-grid_align-end'>
            <Button
              className='slds-col test'
              iconName='save'
              iconCategory='utility'
              variant='brand'
              iconPosition='left'
              onClick={onSubmittedHandler}
              label='Save'
            />
          </div>
        }
      >
        <div className='slds-p-vertical_xx-small slds-text-heading_medium'>
          Edit Bank Account
        </div>
        <FormWrapper>
          <div className='slds-p-around_x-small slds-grid slds-wrap'>
            <div className='slds-col slds-size_1-of-2'>
              <div className='input-header'>
                <ParagraphWrapper>
                  <p className='input-text'>Bank Account Name</p>
                </ParagraphWrapper>
              </div>
              <div className='input-header '>
                <ParagraphWrapper>
                  <p className='input-text'>BSB</p>
                </ParagraphWrapper>
              </div>
              <div className='input-header '>
                <ParagraphWrapper>
                  <p className='input-text'>Account Number</p>
                </ParagraphWrapper>
              </div>
            </div>
            <div className='slds-col slds-size_1-of-2'>{formDisplay}</div>
          </div>
        </FormWrapper>
      </StandardModal>
    </>
  )
}

EditBankAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  bankAccountData: PropTypes.object,
  onSubmitted: PropTypes.func,
  onClose: PropTypes.func
}

export default memo(EditBankAccountModal)
