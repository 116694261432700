import React from 'react'
import PropTypes from 'prop-types'
import { Icon, PageHeader } from '@salesforce/design-system-react'

const AdminPageHeader = ({ pageHeaderText }) => {
  return (
    <PageHeader
      className='slds-m-bottom_small'
      icon={
        <Icon
          assistiveText={{ label: 'Job' }}
          category='utility'
          name='setup'
        />
      }
      title={pageHeaderText}
      variant='record-home'
    />
  )
}

AdminPageHeader.propTypes = {
  pageHeaderText: PropTypes.string
}

export default AdminPageHeader
