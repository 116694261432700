import React, { memo, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getUploadedFilesStatus, resetUploadedFilesStatus } from '../../../redux/slices/job'

import PropTypes from 'prop-types'
import { fileMaxSize } from '../../../constants/file'

import {
  getAcceptedFileTypes,
  getFileErrorText
} from '../../../helpers/submitJob'

import { StandardModal, FileDropInput } from '../../common'
import { Button } from '@salesforce/design-system-react'

const JobUploadFileModal = ({
  isOpen,
  modalTitle,
  jobId,
  onClose,
  onUploadFileAction
}) => {
  const dispatch = useDispatch()

  const [jobInstructionFile, setJobInstructionFile] = useState([])
  const [jobInstructionFileError, setJobInstructionFileError] = useState(false)
  const [isUploadError, setIsUploadError] = useState(false)
  const uploadedFileStatus = useSelector(getUploadedFilesStatus)

  const onInstructionFileChangedHandler = useCallback(files => {
    const targetFile = files[0]

    if (targetFile) {
      setJobInstructionFile(files)
      setJobInstructionFileError(false)
      setIsUploadError(false)
    } else {
      setJobInstructionFile([])
      setJobInstructionFileError(true)
    }
  }, [])

  const onJobInstructionFileRemovedHandler = useCallback(() => {
    setJobInstructionFile([])
  }, [])

  const onSaveButtonClickedHandler = useCallback(
    async e => {
      e.preventDefault()
      const jobInstructionFileValid = !jobInstructionFileError
      if (jobInstructionFileValid && jobInstructionFile.length !== 0) {
        onUploadFileAction({ jobInstructionFile })
      } else {
        setIsUploadError(true)
      }
    },
    [jobInstructionFile, jobInstructionFileError, jobId]
  )

  useEffect(() => {
    setJobInstructionFile([])
    setIsUploadError(false)
    setJobInstructionFileError(false)
    dispatch(resetUploadedFilesStatus())
  }, [isOpen])

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            iconName='save'
            iconCategory='utility'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Save'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>
        <FileDropInput
          multiple={false}
          value={jobInstructionFile}
          label={'Upload File to Job'}
          onFileChange={onInstructionFileChangedHandler}
          maxSize={fileMaxSize}
          errorText={jobInstructionFileError ? getFileErrorText('other') : null}
          accept={getAcceptedFileTypes('other')}
          onRemoveFile={onJobInstructionFileRemovedHandler}
          uploadedFileStatus={uploadedFileStatus}
          required
        />
        {isUploadError ? (
          <p className='slds-text-color_destructive'>
            Please upload file with the correct file format.
          </p>
        ) : null}
      </div>
    </StandardModal>
  )
}

JobUploadFileModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  jobId: PropTypes.string,
  onClose: PropTypes.func,
  onUploadFileAction: PropTypes.func
}

export default memo(JobUploadFileModal)
