import {
  buildCurrencyColumn,
  buildDateTimeColumn,
  buildSimpleColumn
} from '../../../../helpers/dataTable'

export default {
  workOrders: [
    buildSimpleColumn('Ref #', 'lineNumber', true),
    buildSimpleColumn('Type', 'type', false)
  ],
  securities: [
    buildSimpleColumn('Name', 'description', true),
    buildSimpleColumn('Identifier', 'identifier', false)
  ],
  promiseToPays: [
    buildDateTimeColumn('Created Date', 'createdDate', false, {
      withTime: false
    }),
    buildDateTimeColumn('Payment Date', 'paymentDate', false, {
      withTime: false
    }),
    buildCurrencyColumn('Amount', 'paymentAmount', false, {
      decimalPoint: 2,
      thousandSeparator: true
    }),
    buildSimpleColumn('Status', 'status', false)
  ],
  paymentsAddedDeducted: [
    buildSimpleColumn('Ref #', 'paymentReference', true),
    buildDateTimeColumn('Payment Date', 'paymentDate', false, {
      withTime: false
    }),
    buildCurrencyColumn('Amount', 'paymentAmount', false, {
      decimalPoint: 2,
      thousandSeparator: true
    }),
    buildSimpleColumn('Payment Type', 'paymentType', false),
  ],
  extraCharges: [
    buildSimpleColumn('Description', 'description', true),
    buildDateTimeColumn('Payment Date', 'paymentDate', false, {
      withTime: false
    }),
    buildCurrencyColumn('Amount', 'paymentAmount', false, {
      decimalPoint: 2,
      thousandSeparator: true
    })
  ]
}
