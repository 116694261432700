import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../common'
import { Button } from '@salesforce/design-system-react'

import { PAGE_SIZE } from '../../../constants'

import { useDispatch, useSelector } from 'react-redux'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm,
  applyValidation
} from '../../../helpers/form'

import {
  updateCloseJobStatus,
  getCloseJobCommentResult,
  fetchJobCommentsListById,
  resetJobCloseResult
} from '../../../redux/slices/job'

import jobStatusCloseForm from './jobStatusCloseForm'

import useAlert from '../../../hooks/useAlert'

import { createSuccessAlert, createErrorAlert } from '../../../helpers/alert'
import { getFieldValidator } from '../../../redux/slices/global'

const JobStatusCloseModal = ({ isOpen, modalTitle, jobId, onClose }) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState(jobStatusCloseForm)
  const dispatch = useDispatch()
  const fireAlert = useAlert()
  const closeJobResult = useSelector(getCloseJobCommentResult)
  const validator = useSelector(getFieldValidator)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => updateForm(prev, name, value))
  })

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSaveAndSendPasswordHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()

      if (formValid) {
        const data = extractForm(formData)
        dispatch(updateCloseJobStatus({ jobId: jobId, data }))
        onClose()
        setFormData(prev => {
          const clearedForm = clearForm(prev)
          return clearedForm
        })
      }
    }
  }, [formData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    if (closeJobResult !== '') {
      if (closeJobResult === 201) {
        fireAlert(
          createSuccessAlert(
            'Your request to close this jobs has been succesfully sent.',
            1500
          )
        )
      } else {
        fireAlert(createErrorAlert(closeJobResult, 1500))
      }
      dispatch(resetJobCloseResult())
      dispatch(
        fetchJobCommentsListById({ jobId: jobId, page: 1, size: PAGE_SIZE })
      )
    }
  }, [closeJobResult])

  useEffect(() => {
    setFormData(prev => applyValidation(prev, validator?.postComment))
  }, [validator])

  useEffect(() => {
    setFormData(prev => {
      const clearedForm = clearForm(prev)
      return clearedForm
    })
  }, [isOpen])

  return (
    <StandardModal
      hasDatePicker={false}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            iconName='save'
            iconCategory='utility'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Request Job Closure'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>
        <form ref={formRef} onSubmit={onSaveAndSendPasswordHandler}>
          {formDisplay}
        </form>
      </div>
    </StandardModal>
  )
}

JobStatusCloseModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  jobId: PropTypes.string,
  onClose: PropTypes.func
}

export default memo(JobStatusCloseModal)
