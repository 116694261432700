import React from 'react'
import CreateQuotePanel from '../../containers/CreateQuotePanel/CreateQuotePanel'
import {
  CreateQuoteContentWrapper,
  CreateQuotePageWrapper
} from './StyledCreateQuotePage'

const CreateQuotePage = () => {
  return (
    <CreateQuotePageWrapper>
      <CreateQuoteContentWrapper>
        <CreateQuotePanel />
      </CreateQuoteContentWrapper>
    </CreateQuotePageWrapper>
  )
}

export default CreateQuotePage
