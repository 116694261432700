import React from 'react'
import PropTypes from 'prop-types'

import { TabsPanel } from '@salesforce/design-system-react'
import { ScrollableTabs } from '../../common'
import JobRecordFileList from './JobRecordFileList/JobRecordFileList'
import JobRecordComments from './JobRecordComments/JobRecordComments'
import { JobRelatedListsContent } from '../../../containers/JobDetailContent/JobRelatedListsContent'

const JobRecordAdditionalData = ({
  currentTabIndex,
  onTabChanged,
  jobId,
  isMobile,
  files,

  comments,
  commentTotals,
  onCommentSubmitted,
  onLoadMoreCommentsClicked,

  onLoadMoreFileList,
  onFileChanged,
  onAddPaymentClicked
}) => {
  const colClassName = `slds-col slds-size_1-of-1 slds-large-size_8-of-12 ${
    isMobile ? 'slds-m-top_small' : ''
  }`
  return (
    <div className={colClassName}>
      <ScrollableTabs
        id='jobRecordDetailTabs'
        className=''
        selectedTab={currentTabIndex}
        onTabSelect={onTabChanged}
      >
        <TabsPanel label='Related'>
          <JobRelatedListsContent
            jobId={jobId}
            isCurrentTab={currentTabIndex === 0}
            onAddPaymentClicked={onAddPaymentClicked}
          />
        </TabsPanel>
        <TabsPanel label='Comment'>
          <JobRecordComments
            comments={comments}
            commentTotals={commentTotals}
            onCommentSubmitted={onCommentSubmitted}
            onLoadMoreCommentsClicked={onLoadMoreCommentsClicked}
            isMobile={isMobile}
          />
        </TabsPanel>
        <TabsPanel label='Files'>
          <JobRecordFileList
            fileList={files}
            onLoadMoreFile={onLoadMoreFileList}
            isCurrentTab={currentTabIndex === 2}
            onFileChanged={onFileChanged}
          />
        </TabsPanel>
      </ScrollableTabs>
    </div>
  )
}

JobRecordAdditionalData.propTypes = {
  jobId: PropTypes.string,
  isMobile: PropTypes.bool,
  relatedLists: PropTypes.object,
  comments: PropTypes.array,
  commentTotals: PropTypes.number,
  files: PropTypes.array,
  onLoadMoreRelatedList: PropTypes.func,
  onLoadMoreFileList: PropTypes.func,
  currentTabIndex: PropTypes.number,
  onTabChanged: PropTypes.func,
  onCommentSubmitted: PropTypes.func,
  onLoadMoreCommentsClicked: PropTypes.func,
  onAddPaymentClicked: PropTypes.func,
  onFileChanged: PropTypes.func
}

export default JobRecordAdditionalData
