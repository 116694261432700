import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  getUserByUserId,
  patchUserByUserData,
  createNewUser,
  getUserList
} from '../../api/user'
import { extractPagination, getAPIRequestError } from '../../helpers/service'
import {
  createReduxRecordListState,
  updateReduxRecords
} from '../../helpers/redux'

const initialState = {
  userData: {},
  fetchUserLoading: false,
  selectedUserId: '',
  editUserResult: '',
  createUserResult: '',
  userList: createReduxRecordListState()
}

export const fetchUserData = createAsyncThunk(
  'fetchUserData',
  async (userId, thunkAPI) => {
    try {
      const response = await getUserByUserId(userId)

      return { data: response.data, userId }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchUserList = createAsyncThunk(
  'fetchUserList',
  async ({ page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getUserList(page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const saveEditUser = createAsyncThunk(
  'saveEditUser',
  async (newData, thunkAPI) => {
    try {
      const data = {
        id: newData.userId,
        firstName: newData.data?.firstName,
        lastName: newData.data?.lastName,
        phone: newData.data?.phone,
        status: newData.data?.status?.[0]?.label
      }
      const response = await patchUserByUserData(data)
      return response.status
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const createUser = createAsyncThunk(
  'createUser',
  async (userData, thunkAPI) => {
    try {
      const data = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        username: userData.email,
        phone: userData.phone,
        accessType: 'USER'
      }
      const response = await createNewUser(data)

      return response.status
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetAdminUserResult: state => {
      state.editUserResult = initialState.editUserResult
      state.createUserResult = initialState.createUserResult
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserData.pending, state => {
      state.fetchUserLoading = true
    })
    builder.addCase(fetchUserData.fulfilled, (state, { payload }) => {
      state.userData = payload?.data
      state.selectedUserId = payload?.userId
      state.fetchUserLoading = false
    })
    builder.addCase(fetchUserData.rejected, state => {
      state.fetchUserLoading = false
    })

    builder.addCase(saveEditUser.pending, state => {
      state.fetchUserLoading = true
    })
    builder.addCase(saveEditUser.fulfilled, (state, { payload }) => {
      state.editUserResult = payload
      state.fetchUserLoading = false
    })
    builder.addCase(saveEditUser.rejected, (state, { payload }) => {
      state.fetchUserLoading = false
      state.editUserResult = payload?.error.message
    })

    builder.addCase(createUser.pending, state => {
      state.fetchUserLoading = true
    })
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.createUserResult = payload
      state.fetchUserLoading = false
    })
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.fetchUserLoading = false
      state.createUserResult = payload?.error.message
    })

    builder.addCase(fetchUserList.pending, state => {
      // state.fetchUserLoading = true
      state.userList.loading = true
    })
    builder.addCase(fetchUserList.fulfilled, (state, { payload }) => {
      // state.fetchUserLoading = false

      state.userList.loading = false
      state.userList.records = updateReduxRecords(
        state.userList.records,
        payload.data.map(item => {
          return {
            ...item,
            name: `${item.firstName} ${item.lastName}`
          }
        }),
        payload.pagination?.page
      )
      state.userList.page = payload.pagination?.page
      state.userList.size = payload.pagination?.size
      state.userList.total = payload.pagination?.total
      state.userList.totalPages = payload.pagination?.totalPages
      state.userList.error = null
    })
    builder.addCase(fetchUserList.rejected, (state, { payload }) => {
      // state.fetchUserLoading = false
      state.userList.records = []
      state.userList.error = payload?.error.message
    })
  }
})

export const { resetAdminUserResult } = userSlice.actions

export default userSlice.reducer

export const getUserDetail = createSelector(
  state => state.user.userData,
  userData => userData
)

export const getUserDetailLoading = createSelector(
  state => state.user.fetchUserLoading,
  fetchUserLoading => fetchUserLoading
)

export const getSaveEditUserResult = createSelector(
  state => state.user.editUserResult,
  editUserResult => editUserResult
)

export const getCreateUserResult = createSelector(
  state => state.user.createUserResult,
  createUserResult => createUserResult
)

export const getUserListData = createSelector(
  state => state.user.userList,
  userList => userList
)
