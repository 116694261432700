import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { ValueDisplay } from '../../../common'

const QuoteDetails = ({
  quoteNumber,
  quoteSuccessCharge,
  quoteDeclinedCharge,
  isExpired
}) => {
  const quotePriceEqual = quoteSuccessCharge === quoteDeclinedCharge

  let quoteSuccessChargeDisplay = null
  let quoteDeclinedChargeDisplay = null
  let quoteCommonChargeDisplay = null

  if (quoteSuccessCharge && !isExpired && !quotePriceEqual) {
    quoteSuccessChargeDisplay = (
      <ValueDisplay
        value={quoteSuccessCharge}
        valueType='currency'
        label='Charge If Successful'
      />
    )
  }

  if (quoteDeclinedCharge && !isExpired && !quotePriceEqual) {
    quoteDeclinedChargeDisplay = (
      <ValueDisplay
        value={quoteDeclinedCharge}
        valueType='currency'
        label='Charge If Unsuccessful'
      />
    )
  }

  if (quotePriceEqual && !isExpired) {
    quoteCommonChargeDisplay = (
      <ValueDisplay
        value={quoteSuccessCharge}
        valueType='currency'
        label='Charge'
      />
    )
  }

  return (
    <div className='slds-grid slds-gutters_x-small slds-wrap'>
      <div className='slds-col slds-size_4-of-12'>
        <ValueDisplay value={quoteNumber} label='Quote Number' />
      </div>

      <div className='slds-col slds-size_4-of-12'>
        {quotePriceEqual ? quoteCommonChargeDisplay : quoteSuccessChargeDisplay}
      </div>
      <div className='slds-col slds-size_4-of-12'>
        {quotePriceEqual ? null : quoteDeclinedChargeDisplay}
      </div>

      {isExpired ? (
        <div className='slds-col slds-size_12-of-12 slds-text-color_error slds-text-align_center slds-p-top_large'>
          Quote has expired, please generate a new quote to proceed with this
          Job Request
        </div>
      ) : null}
    </div>
  )
}

QuoteDetails.propTypes = {
  quoteNumber: PropTypes.string,
  quoteSuccessCharge: PropTypes.number,
  quoteDeclinedCharge: PropTypes.number,
  isExpired: PropTypes.bool
}

export default memo(QuoteDetails)
