import React from 'react'
import PropTypes from 'prop-types'
import { StandardTextArea } from '../../common'
import { Button } from '@salesforce/design-system-react'

const CommentInput = ({
  isMobile,
  inputName,
  value,
  onInputChanged,
  onCommentSubmitted,
  placeholder,
  maxLength
}) => {
  return (
    <form className='slds-grid slds-wrap' onSubmit={onCommentSubmitted}>
      <div
        className={`slds-col slds-size_1-of-1 slds-large-size_9-of-12 ${
          isMobile ? 'slds-p-around_none' : ''
        }`}
      >
        <StandardTextArea
          name={inputName}
          value={value}
          placeholder={placeholder}
          onChange={onInputChanged}
          maxLength={maxLength}
          required
        />
      </div>
      <Button
        label='Add Comment'
        type='submit'
        variant='brand'
        className={`slds-col slds-size_1-of-2 slds-large-size_3-of-12 ${
          isMobile ? 'slds-m-top_small' : ''
        }`}
      />
    </form>
  )
}

CommentInput.propTypes = {
  isMobile: PropTypes.bool,
  inputName: PropTypes.string,
  value: PropTypes.string,
  onInputChanged: PropTypes.func,
  onCommentSubmitted: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number
}

export default CommentInput
