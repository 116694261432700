import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CommentItem } from '../CommentItem'
import { Button, Spinner } from '@salesforce/design-system-react'
import styled from 'styled-components'

const ScrollableDiv = styled.div`
  overflow-y: auto;
  max-height: 100vh;
`

const CommentHistory = ({ comments, onLoadMoreClicked, commentTotals }) => {
  const [loading, setLoading] = useState(false)

  const onLoadMoreClickedHandler = useCallback(() => {
    setLoading(true)
    onLoadMoreClicked()
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [comments])

  const commentDisplay = comments.map(comment => {
    return (
      <CommentItem
        key={comment.id}
        commentText={comment.comment}
        commenterName={comment.displayName}
        commentDate={comment.createdDate}
        commenterProfileImageSrc={comment.profileImage}
        commenterColor={comment.iconColour}
        commenterDisplayInitials={comment.displayInitials}
      />
    )
  })
  const itemCount = comments?.length

  let loadMoreButton = null
  if (itemCount < commentTotals) {
    let spinner = loading ? (
      <Spinner
        hasContainer
        size='small'
        variant='base'
        assistiveText={{ label: 'Loading...' }}
      />
    ) : null

    loadMoreButton = (
      <div
        className='slds-p-around_small slds-text-align_center'
        style={{ position: 'relative' }}
      >
        {spinner}
        <Button onClick={onLoadMoreClickedHandler}>Load More</Button>
      </div>
    )
  }

  return (
    <ScrollableDiv className='slds-p-around_small'>
      {commentDisplay}
      {loadMoreButton}
    </ScrollableDiv>
  )
}

CommentHistory.propTypes = {
  onLoadMoreClicked: PropTypes.func,
  comments: PropTypes.array,
  commentTotals: PropTypes.number,
  hasMore: PropTypes.bool
}

export default CommentHistory
