import React from 'react'
import PropTypes from 'prop-types'

const AdminInfoFormTabWrapper = ({
  companyInfoFormDisplay,
  clientSpecificInfoFormDisplay,
  relationshipContactFormDisplay,
  secondRelationshipContactFormDisplay,
  operationalContactFormDisplay
}) => {
  const halfPageContentClassNames =
    'slds-grid slds-wrap slds-col slds-size_1-of-1 slds-large-size_1-of-2 slds-p-around_none'
  return (
    <div className='slds-grid slds-wrap slds-gutters'>
      <div className={halfPageContentClassNames}>
        <div className='slds-col slds-size_1-of-1'>
          {companyInfoFormDisplay}
        </div>
        <div className='slds-col slds-size_1-of-1'>
          {clientSpecificInfoFormDisplay}
        </div>
      </div>
      <div className={halfPageContentClassNames}>
        <div className='slds-col slds-size_1-of-1'>
          {relationshipContactFormDisplay}
        </div>
        <div className='slds-col slds-size_1-of-1'>
          {secondRelationshipContactFormDisplay}
        </div>
        <div className='slds-col slds-size_1-of-1'>
          {operationalContactFormDisplay}
        </div>
      </div>
    </div>
  )
}

AdminInfoFormTabWrapper.propTypes = {
  companyInfoFormDisplay: PropTypes.node,
  clientSpecificInfoFormDisplay: PropTypes.node,
  relationshipContactFormDisplay: PropTypes.node,
  secondRelationshipContactFormDisplay: PropTypes.node,
  operationalContactFormDisplay: PropTypes.node
}

export default AdminInfoFormTabWrapper
