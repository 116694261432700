import {
  buildLinkColumn,
  buildDateTimeColumn,
  buildCheckboxColumn,
  buildActiveStatusBadgeColumn,
  buildSimpleColumn
} from '../../helpers/dataTable'

export const getAdminUserListColumns = (onClick) => [
  buildLinkColumn('Name', 'name', true, { baseUrl: '/' , onClick}),
  buildCheckboxColumn('Admin', 'isAdmin', false),
  buildActiveStatusBadgeColumn('Status', 'status', false),
  buildDateTimeColumn('Last Login', 'lastLogin', false, {
    withTime: true
  })
]

export const getBankAccountListColumns = (
  // onClick
) => [
    buildSimpleColumn('Name', 'name', true,
      // { baseUrl: '/', onClick }
    )
  ]

export const pricingListColumns = [
  buildSimpleColumn('Job Type', 'jobType', true),
  buildSimpleColumn('Proximity', 'proximity', false),
  buildSimpleColumn('Price Range', 'priceRange', false)
]
