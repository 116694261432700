import React from 'react'
import {
  LoginSingleGridContainer,
  LoginGridItem,
  LoginContainer,
  LoginImageContainer
} from '../LoginPage/StyledLoginItems'
import { Image } from '../../components'
import { ResetPasswordSessionExpiredPanel } from '../../containers'
import { getGlobalSettingLoadingStatus, getPortalLogoUrl } from '../../redux/slices/global'
import { useSelector } from 'react-redux'

const ResetPasswordExpiredLinkPage = () => {
  const appLogo = useSelector(getPortalLogoUrl)
  const globalLoading = useSelector(getGlobalSettingLoadingStatus)

  return (
    <>
      <LoginSingleGridContainer>
        <LoginGridItem>
          <LoginContainer>
            <LoginImageContainer>
              <Image
                width='100%'
                height='100%'
                src={appLogo}
                alt='Login'
                objectFit='contain'
                loading={globalLoading}
              />
            </LoginImageContainer>
            <ResetPasswordSessionExpiredPanel />
          </LoginContainer>
        </LoginGridItem>
      </LoginSingleGridContainer>
    </>
  )
}

export default ResetPasswordExpiredLinkPage
