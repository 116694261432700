import React, { useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Button,
  ButtonGroup,
  Card,
  Spinner
} from '@salesforce/design-system-react'
import {
  FileDropInput,
  RecordSectionHead,
  StandardCheckbox,
  StandardCombobox,
  StandardInput,
  SubmitJobFileHeader,
  StandardTextArea,
  SubmitJobSuccessModal
} from '../../components'

import { useDispatch, useSelector } from 'react-redux'

import {
  getNewJobPageTitle,
  getNewJobPageSubTitle,
  getSubmitJobFileHelperText,
  getSubmitSupportingDocsHelperText,
  getPortalName,
  getFieldValidator
} from '../../redux/slices/global'

import styled from 'styled-components'

import useAlert from '../../hooks/useAlert'
import { createErrorAlert, createSuccessAlert } from '../../helpers/alert'
import {
  fetchOCRFileTypes,
  getOCRFileTypeData,
  getSubmitJobStatus,
  getTargetJob,
  getUploadedFilesStatus,
  resetSubmitJobPageState,
  submitJobAndUpdateStatus
} from '../../redux/slices/submitJob'
import { isArray, sumBy, unionBy } from 'lodash'
import {
  fileMaxSize,
  FILE_PROVISION_TYPE_OTHER,
  FILE_PROVISION_TYPE_TEMPLATE,
  sumMaxSize
} from '../../constants/file'
import {
  buildSubmitJobPayload,
  getAcceptedFileTypes,
  getFileErrorText,
  getSelectedPaymentType,
  validateSubmitJobFileData
} from '../../helpers/submitJob'
import { setAppTitle } from '../../helpers/appState'
import { getUserRemittanceType } from '../../redux/slices/auth'
import {
  fetchPaymentBankAccount,
  getPaymentBankAccountData,
  resetPaymentBankAccountState
} from '../../redux/slices/quote'
import {
  ABA_REMITTANCE,
  BOTH_REMITTANCE,
  BPAY_REMITTANCE,
  INPUT_TYPE_TEXT
} from '../../constants'
import { AddBankAccountModal } from '../BankAccount'
import {
  createNewBankAccount,
  getCreateBankAccountResult,
  resetBankAccountResult
} from '../../redux/slices/bankAccount'

const CardWrapper = styled.div`
  margin: auto;
`

const paymentOptions = [
  { id: ABA_REMITTANCE, label: 'Direct Transfer' },
  { id: BPAY_REMITTANCE, label: BPAY_REMITTANCE }
]

const SubmitJobFileContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const navigate = useNavigate()
  const fireAlert = useAlert()

  const portalName = useSelector(getPortalName)
  const remittanceType = useSelector(getUserRemittanceType)
  const newQuote = useSelector(getTargetJob)
  const ocrFileTypes = useSelector(getOCRFileTypeData)
  const paymentBankAccounts = useSelector(getPaymentBankAccountData)

  const newJobPageTitle = useSelector(getNewJobPageTitle)
  const newJobPageSubTitle = useSelector(getNewJobPageSubTitle)
  const submitJobFileHelperText = useSelector(getSubmitJobFileHelperText)
  const submitSupportingDocsHelperText = useSelector(
    getSubmitSupportingDocsHelperText
  )

  const createBankAccountResult = useSelector(getCreateBankAccountResult)

  const validator = useSelector(getFieldValidator)

  const targetQuote = location.state?.quote ?? newQuote ?? null

  const uploadedFileStatus = useSelector(getUploadedFilesStatus)
  const submitJobStatus = useSelector(getSubmitJobStatus)

  // Form States
  const [selectedProvisionType, setSelectedProvisionType] = useState('template')
  const [templateFormat, setTemplateFormat] = useState([])

  const [jobInstructionFile, setJobInstructionFile] = useState([])
  const [jobInstructionFileError, setJobInstructionFileError] = useState(false)

  const [additionalJobNote, setAdditionalJobNote] = useState('')

  const [supportingFiles, setSupportingFiles] = useState([])
  const [supportingFilesError, setSupportingFilesError] = useState(false)

  const [selectedRemittanceType, setSelectedRemittanceType] = useState([])

  const [remittanceBankAccount, setRemittanceBankAccount] = useState([])
  const [billerCode, setBillerCode] = useState('')
  const [referenceNumber, setReferenceNumber] = useState('')
  const [skipBpayDetails, setSkipBpayDetails] = useState(null)
  const [bpayDetailsError, setBpayDetailsError] = useState(false)
  const [submitSuccessModalOpen, setSubmitSuccessModalOpen] = useState(false)

  const [createBankAccountModalOpen, setCreateBankAccountModalOpen] =
    useState(false)

  const selectedRemittanceTypeName = getSelectedPaymentType(
    selectedRemittanceType
  )
  const paymentBankAccountOptions = [
    ...(paymentBankAccounts?.records ?? []),
    { id: 'New', label: '+ Bank Account' }
  ]

  const onButtonClickedHandler = useCallback(
    buttonName => () => {
      setSelectedProvisionType(buttonName)
      if (buttonName === FILE_PROVISION_TYPE_TEMPLATE) {
        setJobInstructionFile([])
        setJobInstructionFileError(false)
      }
    },
    []
  )

  const onCancelClickedHandler = useCallback(() => {
    navigate('/')
  }, [])

  const onSubmitButtonClickedHandler = useCallback(() => {
    setBpayDetailsError(
      selectedRemittanceTypeName === BPAY_REMITTANCE &&
        (!billerCode || !referenceNumber) &&
        !skipBpayDetails
    )
  }, [selectedRemittanceTypeName, billerCode, referenceNumber, skipBpayDetails])

  const onFormSubmittedHandler = useCallback(
    async e => {
      e.preventDefault()

      const jobInstructionFileValid = !jobInstructionFileError
      const supportingFilesValid = !supportingFilesError

      const isRemittanceTypeABA = selectedRemittanceTypeName === ABA_REMITTANCE
      const isRemittanceTypeBPAY =
        selectedRemittanceTypeName === BPAY_REMITTANCE

      if (
        validateSubmitJobFileData(
          selectedProvisionType,
          templateFormat,
          jobInstructionFile,
          supportingFiles
        ) &&
        jobInstructionFileValid &&
        supportingFilesValid
      ) {
        const templateFormatId = templateFormat?.[0]?.id ?? null
        const remittanceBankAccountId = remittanceBankAccount?.[0]?.id ?? null

        const buildedData = await buildSubmitJobPayload(
          selectedProvisionType,
          templateFormatId,
          jobInstructionFile,
          supportingFiles,
          targetQuote?.id,
          isRemittanceTypeABA ? !remittanceBankAccountId : undefined,
          isRemittanceTypeABA ? remittanceBankAccountId : undefined,
          isRemittanceTypeBPAY ? billerCode : undefined,
          isRemittanceTypeBPAY ? referenceNumber : undefined,
          isRemittanceTypeBPAY ? !!skipBpayDetails : undefined,
          selectedRemittanceTypeName,
          additionalJobNote
        )

        dispatch(submitJobAndUpdateStatus(buildedData))
      } else {
        fireAlert(
          createErrorAlert(
            'Please check the errors on the page before proceed.'
          )
        )
      }
    },
    [
      targetQuote,
      selectedProvisionType,
      templateFormat,
      jobInstructionFile,
      jobInstructionFileError,
      supportingFiles,
      supportingFilesError,
      remittanceBankAccount,
      billerCode,
      referenceNumber,
      skipBpayDetails,
      selectedRemittanceTypeName,
      additionalJobNote
    ]
  )

  const onCreateBankAccountModalOpenedHandler = useCallback(() => {
    setCreateBankAccountModalOpen(true)
  }, [])

  const onCreateBankAccountModalClosedHandler = useCallback(() => {
    setCreateBankAccountModalOpen(false)
  }, [])

  const onCreateBankAccountSubmittedHandler = useCallback(data => {
    dispatch(createNewBankAccount(data))
  }, [])

  const onAdditionalJobNoteChangedHandler = useCallback(
    value => {
      setAdditionalJobNote(value)
    },
    [additionalJobNote]
  )

  const onDropdownChangedHandler = useCallback(value => {
    setTemplateFormat(value.selection)
  }, [])

  const onBankAccountDropdownChangedHandler = useCallback(value => {
    const isCreateNew = value.selection?.[0].id === 'New'

    if (isCreateNew) {
      onCreateBankAccountModalOpenedHandler()
    } else {
      setRemittanceBankAccount(value.selection)
    }
  }, [])

  const onPaymentOptionDropdownChangedHandler = useCallback(value => {
    setSelectedRemittanceType(value.selection)
  }, [])

  const onBillerCodeChangedHandler = useCallback(value => {
    setBpayDetailsError(false)
    setBillerCode(value)
  }, [])

  const onReferenceNumberChangedHandler = useCallback(value => {
    setBpayDetailsError(false)
    setReferenceNumber(value)
  }, [])

  const onSkipBpayDetailsChangedHandler = useCallback(value => {
    setSkipBpayDetails(value)
    setBpayDetailsError(false)

    if (value) {
      setBillerCode('')
      setReferenceNumber('')
    }
  }, [])

  const onInstructionFileChangedHandler = useCallback(files => {
    const targetFile = files[0]

    if (targetFile) {
      setJobInstructionFile(files)
      setJobInstructionFileError(false)
    } else {
      setJobInstructionFile([])
      setJobInstructionFileError(true)
    }
  }, [])

  const onJobInstructionFileRemovedHandler = useCallback(() => {
    setJobInstructionFile([])
  }, [])

  const onSupportingFileChangedHandler = useCallback(
    files => {
      const allFiles = unionBy(supportingFiles, files, 'name')

      setSupportingFilesError(sumBy(allFiles, 'size') > sumMaxSize)
      setSupportingFiles(allFiles)
    },
    [supportingFiles]
  )

  const onSupportingFileRemovedHandler = useCallback(targetFile => {
    setSupportingFiles(prev => {
      const updatedFileList =
        prev?.filter(file => file.path !== targetFile.path) ?? []
      setSupportingFilesError(sumBy(updatedFileList, 'size') > sumMaxSize)
      return updatedFileList
    })
  }, [])

  const onBackToHomePageClickedHandler = useCallback(() => {
    navigate('/')
  }, [])

  useEffect(() => {
    const ocrFileTypeCount = ocrFileTypes?.length ?? 0
    const firstFileType = ocrFileTypes?.[0] ?? null

    setTemplateFormat(ocrFileTypeCount && firstFileType ? [firstFileType] : [])
    setSelectedProvisionType(
      ocrFileTypeCount > 0
        ? FILE_PROVISION_TYPE_TEMPLATE
        : FILE_PROVISION_TYPE_OTHER
    )
  }, [ocrFileTypes])

  useEffect(() => {
    const defaultBankAccount =
      paymentBankAccounts?.records?.filter(item => item.defaultAccount) ?? []

    if (defaultBankAccount.length === 1) {
      setRemittanceBankAccount(defaultBankAccount)
    }
    return () => {
      setRemittanceBankAccount([])
    }
  }, [paymentBankAccounts.records])

  useEffect(() => {
    if (submitJobStatus.statusCode === 200) {
      fireAlert(
        createSuccessAlert(
          submitJobStatus.message ?? 'Job files are successfully submitted.'
        )
      )

      setSubmitSuccessModalOpen(true)

      dispatch(resetPaymentBankAccountState())
    } else if (submitJobStatus.statusCode) {
      fireAlert(createErrorAlert(submitJobStatus.error))
    }
  }, [submitJobStatus])

  useEffect(() => {
    setAppTitle(portalName, 'Submit Job File')
    dispatch(fetchOCRFileTypes())

    const defaultRemittanceType =
      remittanceType === BOTH_REMITTANCE ? ABA_REMITTANCE : remittanceType

    setSelectedRemittanceType(
      paymentOptions.filter(item => item.id === defaultRemittanceType)
    )

    return () => {
      dispatch(resetSubmitJobPageState())
    }
  }, [remittanceType])

  useEffect(() => {
    if (selectedRemittanceTypeName === ABA_REMITTANCE) {
      dispatch(fetchPaymentBankAccount())
    }
  }, [selectedRemittanceTypeName])

  useEffect(() => {
    if (createBankAccountResult !== null) {
      if (createBankAccountResult.status === 201) {
        const bankAccountData = createBankAccountResult.data

        setRemittanceBankAccount([
          { ...bankAccountData, label: bankAccountData?.name }
        ])
        fireAlert(createSuccessAlert('Bank account has been created', 3000))
        onCreateBankAccountModalClosedHandler()
      } else {
        if (isArray(createBankAccountResult)) {
          for (let i = 0; i < createBankAccountResult.length; i++) {
            fireAlert(createErrorAlert(createBankAccountResult[i], 3000))
          }
        } else {
          fireAlert(createErrorAlert(createBankAccountResult, 3000))
        }
      }
      dispatch(resetBankAccountResult())
    }
  }, [createBankAccountResult])

  useEffect(() => {
    return () => {
      setSubmitSuccessModalOpen(false)
    }
  }, [])

  const showTemplateButton = ocrFileTypes?.length > 0
  const selectedFileTemplateType =
    selectedProvisionType === FILE_PROVISION_TYPE_TEMPLATE
  const formValid = validateSubmitJobFileData(
    selectedProvisionType,
    templateFormat,
    jobInstructionFile,
    supportingFiles
  )

  return (
    <>
      <AddBankAccountModal
        isOpen={createBankAccountModalOpen}
        modalTitle='Add a Bank Account'
        onClose={onCreateBankAccountModalClosedHandler}
        onSubmitted={onCreateBankAccountSubmittedHandler}
      />
      <SubmitJobFileHeader
        quoteNo={targetQuote?.quoteNo ?? null}
        title={newJobPageTitle}
        subTitle={newJobPageSubTitle}
      />
      <SubmitJobSuccessModal
        isOpen={submitSuccessModalOpen}
        onSaveButtonClickedHandler={onBackToHomePageClickedHandler}
      />
      <CardWrapper className='slds-p-around_x-small slds-medium-size_1-of-2 cardBox'>
        <Card hasNoHeader bodyClassName='slds-p-around_x-small slds-m-top_none'>
          <form onSubmit={onFormSubmittedHandler}>
            <RecordSectionHead sectionName={'Job File'} />
            <p className='slds-text-align_center'>{submitJobFileHelperText}</p>
            <ButtonGroup
              id='submitJobFileProvisionTypeButtonGroup'
              labels={{ label: 'Job File Provision Type' }}
              variant='list'
            >
              {showTemplateButton ? (
                <Button
                  label='Job File Template'
                  onClick={onButtonClickedHandler(FILE_PROVISION_TYPE_TEMPLATE)}
                  variant={selectedFileTemplateType ? 'brand' : 'neutral'}
                />
              ) : null}
              <Button
                label='Other'
                onClick={onButtonClickedHandler(FILE_PROVISION_TYPE_OTHER)}
                variant={
                  selectedProvisionType === FILE_PROVISION_TYPE_OTHER
                    ? 'brand'
                    : 'neutral'
                }
              />
            </ButtonGroup>

            {selectedFileTemplateType && showTemplateButton ? (
              <StandardCombobox
                label={'Which template format are you providing?'}
                multiple={false}
                name={'templateFormat'}
                onSelect={onDropdownChangedHandler}
                value={templateFormat}
                options={ocrFileTypes ?? []}
                singleInputDisabled={ocrFileTypes?.length <= 1}
                readOnly
                required
              />
            ) : null}
            <FileDropInput
              multiple={false}
              value={jobInstructionFile}
              label={'Upload Single Job Instructions File'}
              onFileChange={onInstructionFileChangedHandler}
              maxSize={fileMaxSize}
              errorText={
                jobInstructionFileError
                  ? getFileErrorText(selectedProvisionType)
                  : null
              }
              accept={getAcceptedFileTypes(selectedProvisionType)}
              onRemoveFile={onJobInstructionFileRemovedHandler}
              uploadedFileStatus={uploadedFileStatus}
              required
            />
            <StandardTextArea
              label={'Please add any further comments regarding this job'}
              name={'additionalJobNote'}
              value={additionalJobNote}
              onChange={onAdditionalJobNoteChangedHandler}
              maxLength={validator?.case?.additionalJobNotes?.length ?? null}
            />
            <RecordSectionHead sectionName={'Support Documents'} />
            <p className='slds-text-align_center'>
              {submitSupportingDocsHelperText}
            </p>
            <FileDropInput
              value={supportingFiles}
              label={'Upload one or more Supporting Documents'}
              onFileChange={onSupportingFileChangedHandler}
              errorText={
                supportingFilesError ? getFileErrorText('template') : null
              }
              maxSize={fileMaxSize}
              accept={getAcceptedFileTypes('template')}
              onRemoveFile={onSupportingFileRemovedHandler}
              uploadedFileStatus={uploadedFileStatus}
            />

            {remittanceType === BOTH_REMITTANCE ? (
              <>
                <RecordSectionHead
                  sectionName={'How would you like funds remitted?'}
                />
                <StandardCombobox
                  label={''}
                  multiple={false}
                  name={'paymentOption'}
                  onSelect={onPaymentOptionDropdownChangedHandler}
                  value={selectedRemittanceType}
                  options={paymentOptions}
                  readOnly
                />
              </>
            ) : null}

            {selectedRemittanceTypeName === ABA_REMITTANCE ? (
              <>
                <RecordSectionHead sectionName={'Payment Details'} />
                <StandardCombobox
                  label={'Bank Account'}
                  multiple={false}
                  name={'remittanceBankAccount'}
                  onSelect={onBankAccountDropdownChangedHandler}
                  value={remittanceBankAccount}
                  options={paymentBankAccountOptions ?? []}
                  singleInputDisabled={paymentBankAccountOptions?.length < 1}
                  readOnly
                />
              </>
            ) : null}

            {selectedRemittanceTypeName === BPAY_REMITTANCE ? (
              <>
                <RecordSectionHead sectionName={'Payment Details'} />
                <StandardInput
                  key={'billerCode'}
                  type={INPUT_TYPE_TEXT}
                  name={'billerCode'}
                  value={billerCode ?? ''}
                  label={'Biller Code'}
                  onChange={onBillerCodeChangedHandler}
                  disabled={skipBpayDetails}
                  required={!skipBpayDetails}
                  maxLength={validator?.case?.billerCode?.length ?? null}
                />
                <StandardInput
                  key={'referenceNumber'}
                  type={INPUT_TYPE_TEXT}
                  name={'referenceNumber'}
                  value={referenceNumber ?? ''}
                  label={'Reference Number'}
                  onChange={onReferenceNumberChangedHandler}
                  disabled={skipBpayDetails}
                  required={!skipBpayDetails}
                  maxLength={validator?.case?.referenceNumber?.length ?? null}
                />
                <StandardCheckbox
                  className={'slds-m-top_small'}
                  key={'skipBpayDetails'}
                  name={'skipBpayDetails'}
                  value={skipBpayDetails ?? false}
                  label={'Skip BPAY Details'}
                  onChange={onSkipBpayDetailsChangedHandler}
                />
                {bpayDetailsError ? (
                  <div className={'slds-text-color_error'}>
                    Please provide the job&apos;s BPAY details for remittance of
                    funds.
                  </div>
                ) : null}
              </>
            ) : null}

            <div className='slds-grid slds-wrap'>
              <Button
                type={'submit'}
                onClick={onSubmitButtonClickedHandler}
                className='slds-col slds-size_1-of-1 slds-m-top_small'
                label={'Accept & Submit Job'}
                variant='brand'
                disabled={!formValid}
              />
              <Button
                className='slds-col slds-size_1-of-1 slds-m-left_none slds-m-top_small'
                label={'Cancel'}
                onClick={onCancelClickedHandler}
              />
            </div>
          </form>
        </Card>
      </CardWrapper>
      {submitJobStatus.loading ? <Spinner hasContainer /> : null}
    </>
  )
}

export default SubmitJobFileContainer
