import React, { memo, useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal, StandardInput } from '../../components'
import { Button } from '@salesforce/design-system-react'

import newPaymentForm from './newPaymentForm'
import {
  clearForm,
  extractForm,
  getFormDisplay,
  updateForm,
  getFormValidStatus,
  applyDataToForm,
  applyValidation
} from '../../helpers/form'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'

import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getFieldValidator } from '../../redux/slices/global'

const AddNewPaymentModal = ({
  isOpen,
  modalTitle,
  parentJobData,
  onSubmitted,
  onClose,
  isActionUpdate
}) => {
  const formRef = useRef(null)
  const isMobile = useCheckMobileScreen()
  const [formData, setFormData] = useState(newPaymentForm)
  const validator = useSelector(getFieldValidator)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => updateForm(prev, name, value))
  }, [])

  const onSavePaymentHandler = useCallback(
    isSaveAndNew => {
      const form = formRef.current
      if (form) {
        const formValid = form.reportValidity()
        const checkValidForm = getFormValidStatus(formData)

        if (formValid && checkValidForm.formValid) {
          const data = extractForm(formData)

          onSubmitted({ ...data, jobId: parentJobData.id })

          if (!isSaveAndNew) {
            closeModal()
          }
          setFormData(prev => {
            const clearedForm = clearForm(prev)
            const updatedForm = applyDataToForm(clearedForm, {
              jobName: parentJobData?.customerName,
              dateReceived: dayjs()
            })
            return updatedForm
          })
        } else {
          setFormData(checkValidForm.validatedForm)
        }
      }
    },
    [formData, parentJobData]
  )

  const onSaveHandler = useCallback(async () => {
    onSavePaymentHandler(false)
  }, [formData])

  const closeModal = () => {
    onClose()
    setFormData(prev => {
      const clearedForm = clearForm(prev)
      const updatedForm = applyDataToForm(clearedForm, {
        jobName: parentJobData?.customerName,
        dateReceived: dayjs()
      })
      return updatedForm
    })
  }

  const onAddAnotherHandler = useCallback(async () => {
    onSavePaymentHandler(true)
  }, [formData])

  useEffect(() => {
    setFormData(prev => {
      return applyValidation(prev, validator?.payment)
    })
  }, [validator])

  useEffect(() => {
    setFormData(prev => {
      return updateForm(prev, 'jobName', parentJobData?.customerName)
    })
    // return () => {
    //   updateForm(prev => clearForm(prev))
    // }
  }, [parentJobData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={closeModal}
      footer={
        <div className='slds-grid slds-grid_align-spread slds-wrap'>
          {!isActionUpdate ? (
            <Button
              className={`slds-col ${isMobile ? 'slds-size_1-of-1' : ''}`}
              variant='brand'
              onClick={onAddAnotherHandler}
              label='Save & Add Another'
              name={'save and addanother'}
            />
          ) : (
            <div
              className={`slds-col ${isMobile ? 'slds-size_1-of-1' : ''}`}
            ></div>
          )}
          <Button
            name={'save'}
            className={`slds-col ${
              isMobile
                ? 'slds-size_1-of-1 slds-m-top_x-small slds-m-left_none'
                : ''
            }`}
            variant='brand'
            onClick={onSaveHandler}
            label='Save'
          />
        </div>
      }
    >
      <StandardInput
        label={'Job Name'}
        value={parentJobData?.customerName}
        readOnly={true}
        type={'text'}
        onChange={onInputChangedHandler}
      />
      <form ref={formRef}>{formDisplay}</form>
    </StandardModal>
  )
}

AddNewPaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  parentJobData: PropTypes.object,
  onSubmitted: PropTypes.func,
  onClose: PropTypes.func,
  isActionUpdate: PropTypes.bool
}

export default memo(AddNewPaymentModal)
