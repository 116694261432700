import React from 'react'
import styled from 'styled-components'

import { InvoiceListContent } from '../../containers'

const InvoiceListPageWrapper = styled.div({ padding: '0.5rem' })

const InvoiceListPage = () => {
  return (
    <InvoiceListPageWrapper>
      <InvoiceListContent />
    </InvoiceListPageWrapper>
  )
}

export default InvoiceListPage
