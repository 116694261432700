import {
  INPUT_TYPE_TEXT,
  INPUT_TYPE_EMAIL
} from '../../constants'

export default {
  firstName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'First Name',
      type: INPUT_TYPE_TEXT,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  lastName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Last Name',
      type: INPUT_TYPE_TEXT,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  email: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      label: 'Email',
      type: INPUT_TYPE_EMAIL,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  phone: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Phone',
      type: INPUT_TYPE_TEXT,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  }
}
