import React from 'react'
import PropTypes from 'prop-types'

import { BulletinBanner } from '../../common'

const BulletinList = ({ bulletins, onBulletinClosedHandler }) => {
  const bulletinDisplay = bulletins.map(item => (
    <BulletinBanner
      key={item.id}
      id={item.id}
      duration={item.duration}
      dismissible
      message={item.message}
      variant={item.variant}
      onClose={onBulletinClosedHandler}
    />
  ))

  return bulletinDisplay
}

BulletinList.propTypes = {
  bulletins: PropTypes.array
}

export default BulletinList
