import {
  buildCurrencyColumn,
  buildDateTimeColumn,
  buildLinkColumn,
  buildSimpleColumn
} from '../../../../helpers/dataTable'

export default ({ sortColumn, sortDirectionMemo }) => [
  buildLinkColumn(
    'Debtor Name',
    'debtorName',
    false,
    { baseUrl: '/jobs' },
    { sortable: true, sortColumn, sortDirectionMemo }
  ),
  buildSimpleColumn('Client Ref #', 'clientRef', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn('Security', 'security', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildCurrencyColumn(
    'Current Arrears',
    'currentArrears',
    false,
    {
      thousandSeparator: true,
      decimalPoint: 2
    },
    { sortable: true, sortColumn, sortDirectionMemo }
  ),
  buildDateTimeColumn(
    'Created Date',
    'createdDate',
    false,
    { withTime: false },
    { sortable: true, sortColumn, sortDirectionMemo }
  )
]
