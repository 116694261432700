import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom'
import { setAppTitle } from '../../helpers/appState'

import { Spinner } from '@salesforce/design-system-react'

import {
  LoginContentContainer,
  LoginHeading
} from '../../pages/LoginPage/StyledLoginItems'
import {
  getLoginErrorMessage,
  getLoginLoadingStatus,
  getMFAAuthenticationLink,
  getMFAAuthStatus,
  getMFAQRData,
  getPasswordLoginStatus,
  clearMFAAuthState,
  getAccessToken
} from '../../redux/slices/auth'
import { getLoginContactPhone, getPortalName } from '../../redux/slices/global'
import { MultiFactorForm } from '../../components'

const pageTitle = 'Portal Login'

const MultiFactorAuthPanel = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  const [otp, setOtp] = useState(null)

  const loginLoading = useSelector(getLoginLoadingStatus)
  const errorText = useSelector(getLoginErrorMessage)
  const portalName = useSelector(getPortalName)
  const qrValue = useSelector(getMFAQRData)
  const alreadyConfigMFA = useSelector(getMFAAuthStatus)
  const loginStatus = useSelector(getPasswordLoginStatus)
  const contactPhone = useSelector(getLoginContactPhone)

  const onInputChangedHandler = useCallback(value => {
    setOtp(value)
  }, [])

  const onSubmittedHandler = useCallback(
    event => {
      event.preventDefault()

      if (otp) {
        dispatch(getAccessToken(otp))
      }
    },
    [otp]
  )

  const onCancelClickedHandler = useCallback(() => {
    dispatch(clearMFAAuthState())
    navigate('/login')
  }, [])

  useEffect(() => {
    const fromLogin = location.state?.fromLogin

    if (!fromLogin || !loginStatus) {
      onCancelClickedHandler()
    }
  }, [location.state, loginStatus])

  useEffect(() => {
    setAppTitle(portalName, pageTitle)
  }, [portalName])

  useEffect(() => {
    if (!alreadyConfigMFA) {
      dispatch(getMFAAuthenticationLink())
    }
  }, [alreadyConfigMFA])

  useEffect(() => {
    return () => {
      dispatch(clearMFAAuthState())
    }
  }, [])

  const headingText = alreadyConfigMFA
    ? 'Enter Verification Code'
    : 'Enable Google Authenticator'

  return (
    <>
      {loginLoading ? <Spinner /> : null}
      <LoginHeading>{headingText}</LoginHeading>
      <LoginContentContainer style={{ padding: '1rem' }}>
        <MultiFactorForm
          isSetup={!alreadyConfigMFA}
          qrValue={qrValue}
          contactPhone={contactPhone}
          otp={otp}
          errorText={errorText}
          onSubmitted={onSubmittedHandler}
          onInputChanged={onInputChangedHandler}
          onCancelClicked={onCancelClickedHandler}
        />
      </LoginContentContainer>
    </>
  )
}

export default MultiFactorAuthPanel
