import React, { memo, useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../../components'
import { Button } from '@salesforce/design-system-react'

import { userForm } from './userRecordForm'
import {
  clearForm,
  extractForm,
  getFormDisplay,
  updateForm,
  getFormValidStatus,
  applyDataToForm,
  applyValidation
} from '../../../helpers/form'
import { isEmpty } from 'lodash'
import { getFieldValidator } from '../../../redux/slices/global'
import { useSelector } from 'react-redux'

const UserRecordModal = ({
  isOpen,
  modalTitle,
  userData,
  onSubmitted,
  onClose
}) => {
  const formRef = useRef()
  const [formData, setFormData] = useState(userForm)

  const validator = useSelector(getFieldValidator)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => {
      return updateForm(prev, name, value)
    })
  }, [])

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)

      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData, true)

        if (!isEmpty(data)) {
          onSubmitted(data)
        } else {
          closeModal()
        }
      }
    }
  }, [formData])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  const closeModal = () => {
    onClose()
    setFormData(prev => clearForm(prev))
  }

  useEffect(() => {
    setFormData(prev => applyDataToForm(prev, userData))
    return () => {
      setFormData(prev => clearForm(prev))
    }
  }, [userData])

  useEffect(() => {
    setFormData(prev => applyValidation(prev, validator?.contact))
  }, [validator])

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={closeModal}
      footer={
        <div className='slds-grid slds-grid_align-end slds-wrap'>
          <Button
            className={`slds-col`}
            variant='brand'
            onClick={onSaveButtonClickedHandler}
            label='Save'
          />
        </div>
      }
    >
      <form ref={formRef} onSubmit={onSubmittedHandler}>
        {formDisplay}
      </form>
    </StandardModal>
  )
}

UserRecordModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  userData: PropTypes.object,
  onSubmitted: PropTypes.func,
  onClose: PropTypes.func
}

export default memo(UserRecordModal)
