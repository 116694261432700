import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../common'
import { Button } from '@salesforce/design-system-react'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm,
  getFormValidStatus,
  applyDataToForm
} from '../../../helpers/form'

import jobCostApproveModalform from './jobCostApproveModalform'
import {
  INPUT_TYPE_TEXT,
  JOB_APPROVAL_SUB_TYPE_CHANGE_ADDRESS
} from '../../../constants'

const JobActionUpdateModal = ({
  isOpen,
  modalTitle,
  jobId,
  jobData,
  onClose,
  onSaveActionUpdate
}) => {
  const formRef = useRef(null)
  const [formData, setFormData] = useState(jobCostApproveModalform)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => {
      const updatedForm = updateForm(prev, name, value)

      return updatedForm
    })
  })

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)

      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData)
        const formedData = {
          costApprovalOutcome: data.costApprovalOutcome?.[0]?.id,
          approvalSubType: data.approvalSubType
        }

        onSaveActionUpdate({ ...formedData })
      } else {
        setFormData(checkValidForm.validatedForm)
      }
    }
  }, [formData, jobId])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    setFormData(prev => {
      let currentForm = prev

      if (jobData.approvalSubType === JOB_APPROVAL_SUB_TYPE_CHANGE_ADDRESS) {
        currentForm = {
          approvalSubType: {
            inputType: INPUT_TYPE_TEXT,
            inputProps: {
              label: 'Approval Sub Type',
              type: INPUT_TYPE_TEXT,
              readOnly: true,
              hidden: true
            },
            value: '',
            valid: true
          },
          initialJobLocationAddress: {
            inputType: INPUT_TYPE_TEXT,
            inputProps: {
              label: 'Current Address',
              type: INPUT_TYPE_TEXT,
              readOnly: true
            },
            value: '',
            valid: true
          },
          nextJobLocationAddress: {
            inputType: INPUT_TYPE_TEXT,
            inputProps: {
              label: 'Next Address',
              type: INPUT_TYPE_TEXT,
              readOnly: true
            },
            value: '',
            valid: true
          },
          ...currentForm
        }
      }

      return applyDataToForm(currentForm, jobData)
    })
    return () => {
      setFormData(prev => {
        const clearedForm = clearForm(prev)
        return clearedForm
      })
    }
  }, [isOpen, jobData])

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            iconName='save'
            iconCategory='utility'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Save'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>
        <form ref={formRef} onSubmit={onSubmittedHandler}>
          {formDisplay}
        </form>
      </div>
    </StandardModal>
  )
}

JobActionUpdateModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  jobId: PropTypes.string,
  jobData: PropTypes.object,
  onClose: PropTypes.func,
  onSaveActionUpdate: PropTypes.func
}

export default memo(JobActionUpdateModal)
