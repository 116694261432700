import React, { useEffect } from 'react'
import { FAQTitle, FAQQuestions } from '../../components/FAQ'
import { FAQContentWrapper } from './StyledFAQPageContent.'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFAQs, getFaqList, resetFaqPage } from '../../redux/slices/faq'
import {
  fetchGlobalSetting,
  getFaqPageTitle,
  getPortalName
} from '../../redux/slices/global'
import { setAppTitle } from '../../helpers/appState'

const FAQPageContent = () => {
  const dispatch = useDispatch()

  const portalName = useSelector(getPortalName)
  const faqList = useSelector(getFaqList)
  const faqPageTitle = useSelector(getFaqPageTitle)

  useEffect(() => {
    setAppTitle(portalName, 'FAQ')
    dispatch(fetchFAQs())
    dispatch(fetchGlobalSetting())

    return () => {
      dispatch(resetFaqPage())
    }
  }, [])

  return (
    <FAQContentWrapper>
      <FAQTitle title={faqPageTitle} />
      {faqList.records.length > 0
        ? faqList.records.map((faq, index) => {
            return (
              <div key={index}>
                <FAQQuestions question={faq.question} answer={faq.answer} />
              </div>
            )
          })
        : null}
    </FAQContentWrapper>
  )
}

export default FAQPageContent
