import React, { useState, useCallback, useEffect } from 'react'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { isNil } from 'lodash'

import resetForm, { passwordValidation, validatePassword } from './resetForm'
import { extractForm, updateForm } from '../../helpers/form'

import { ResetPasswordForm, ResetPasswordResult } from '../../components'
import {
  LoginContentContainer,
  LoginHeading
} from '../../pages/LoginPage/StyledLoginItems'
import {
  getLoginErrorMessage,
  getRefreshPasswordStatus,
  getRefreshPasswordTokenValid,
  getRefreshPasswordUsername,
  resetPassword,
  resetAuthState,
  validateRefreshToken,
  getLoginLoadingStatus
} from '../../redux/slices/auth'
import { getPortalName } from '../../redux/slices/global'
import { setAppTitle } from '../../helpers/appState'
import { Spinner } from '@salesforce/design-system-react'

const pageTitle = 'Reset Password'

const ResetPasswordPanel = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [resetPasswordForm, setResetPasswordForm] = useState(resetForm)

  const [failedAttempts, setFailedAttempts] = useState(0)
  const [passwordValid, setPasswordValid] = useState({})

  const portalName = useSelector(getPortalName)

  const tokenValid = useSelector(getRefreshPasswordTokenValid)
  const errorMessage = useSelector(getLoginErrorMessage)
  const resetCompleted = useSelector(getRefreshPasswordStatus)
  const username = useSelector(getRefreshPasswordUsername)
  const authLoading = useSelector(getLoginLoadingStatus)

  const token = searchParams.get('token')
  const isWelcome = location.pathname === '/welcome'

  const onInputChangedHandler = useCallback((value, name) => {
    setResetPasswordForm(prev => {
      const updatedForm = updateForm(prev, name, value)
      const data = extractForm(updatedForm)

      if (name === 'newPassword') {
        const { validations, allValid } = validatePassword(value)

        if (!allValid) {
          updatedForm[name].inputProps.errorText =
            'Password does not meet necessary criteria'
        } else {
          updatedForm[name].inputProps.errorText = null
        }
        setPasswordValid({ ...validations })
      }

      if (data.newPassword !== data.confirmPassword) {
        updatedForm.confirmPassword.inputProps.errorText =
          "Those passwords don't match"
        setPasswordValid(prevValid => ({
          ...prevValid,
          confirmPassMatch: false
        }))
      } else {
        updatedForm.confirmPassword.inputProps.errorText = null
        setPasswordValid(prevValid => ({
          ...prevValid,
          confirmPassMatch: true
        }))
      }

      return updatedForm
    })
  }, [])

  const onSubmittedHandler = useCallback(
    e => {
      e.preventDefault()
      const { newPassword, confirmPassword } = extractForm(resetPasswordForm)

      if (newPassword === confirmPassword) {
        dispatch(resetPassword({ token, password: newPassword }))
      } else {
        setFailedAttempts(prev => {
          return prev + 1
        })
      }
    },
    [resetPasswordForm, token]
  )

  const onReturnClickedHandler = useCallback(() => {
    navigate('/login')
  }, [])

  useEffect(() => {
    setAppTitle(portalName, pageTitle)
  }, [portalName])

  useEffect(() => {
    dispatch(validateRefreshToken(token))
  }, [token])

  useEffect(() => {
    if (!isNil(tokenValid) && !tokenValid) {
      navigate('/reset_link_expired')
    }
  }, [tokenValid])

  useEffect(() => {
    return () => {
      dispatch(resetAuthState())
    }
  }, [])

  const headingText = resetCompleted
    ? 'Password Changed'
    : 'Change Your Password'

  const tooMuchAttempts = failedAttempts > 4
  const passwordValidationFailed = Object.entries(passwordValid).some(
    ([, value]) => !value
  )
  const passwordIsEmpty =
    !resetPasswordForm.newPassword.value ||
    !resetPasswordForm.confirmPassword.value

  const display = resetCompleted ? (
    <ResetPasswordResult onReturnClickedHandler={onReturnClickedHandler} />
  ) : (
    <ResetPasswordForm
      username={username}
      resetPasswordFormText={
        <p>
          {isWelcome
            ? 'Finish account setup by setting a new password for'
            : 'Enter a new password for'}{' '}
          <b>{username ?? ''}</b>. Make sure to include at least:
        </p>
      }
      form={authLoading ? {} : resetPasswordForm}
      passwordValid={passwordValid}
      passwordValidation={passwordValidation}
      errorText={errorMessage}
      onSubmitted={onSubmittedHandler}
      onInputChanged={onInputChangedHandler}
      submitDisabled={
        tooMuchAttempts || passwordValidationFailed || passwordIsEmpty
      }
    />
  )

  return (
    <>
      <LoginHeading>{headingText}</LoginHeading>
      <LoginContentContainer style={{ padding: '1rem' }}>
        {authLoading ? <Spinner /> : null}
        {display}
      </LoginContentContainer>
    </>
  )
}

export default ResetPasswordPanel
