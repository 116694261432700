import { HomePageContent } from '../../containers'
import { HomeContentWrapper, HomePageWrapper } from './StyledHomePageItems'

const HomePage = () => {
  return (
    <HomePageWrapper>
      <HomeContentWrapper>
        <HomePageContent />
      </HomeContentWrapper>
    </HomePageWrapper>
  )
}

export default HomePage
