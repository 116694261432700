import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getAuthenticatorLink,
  loginByUsernamePassword,
  logoutFromPortal,
  requestResetPasswordWithEmail,
  resetPasswordWithNewPassword,
  validateResetPasswordToken,
  verifyMFAPassword
} from '../../api/auth'
import { getAPIRequestError } from '../../helpers/service'
import jwtDecode from 'jwt-decode'

const initialState = {
  tempAuthToken: null,
  isMFAAuthenticated: null,
  mfaQRCodeData: null,

  accessToken: null,
  authLoading: false,
  errorMessage: null,
  role: null,
  timezone: null,
  remittanceType: null,

  resetPasswordRequestSuccess: false,

  resetPasswordUsername: null,
  resetPasswordTokenValid: null,
  resetPasswordSuccess: false
}

export const login = createAsyncThunk('login', async (credential, thunkAPI) => {
  try {
    const { username, password } = credential
    const response = await loginByUsernamePassword(username, password)
    const {
      accessToken: tempAuthToken,
      isMfaAuthenticated: isMFAAuthenticated
    } = response

    return { tempAuthToken, isMFAAuthenticated }
  } catch (error) {
    const errorData = getAPIRequestError(error)

    // console.log('errorData', errorData)

    return thunkAPI.rejectWithValue({ error: errorData })
  }
})

export const getMFAAuthenticationLink = createAsyncThunk('getMFAAuthenticationLink', async (_, thunkAPI) => {
  try {
    const response = await getAuthenticatorLink()
    const authUrl = response.authUrl

    return { qrData: authUrl }
  } catch (error) {
    const errorData = getAPIRequestError(error)

    // console.log('errorData', errorData)

    return thunkAPI.rejectWithValue({ error: errorData })
  }
})

export const getAccessToken = createAsyncThunk(
  'getAccessToken',
  async (otp, thunkAPI) => {
    try {
      const response = await verifyMFAPassword(otp)
      const accessToken = response.accessToken
      const timezone = response?.tzCode ?? null
      const decodedToken = jwtDecode(accessToken)

      return { accessToken, userRole: decodedToken.role, timezone, remittanceType: decodedToken.remittanceType }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const requestResetPassword = createAsyncThunk(
  'forgotPassword',
  async (username, thunkAPI) => {
    try {
      const response = await requestResetPasswordWithEmail(username)

      return response
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const validateRefreshToken = createAsyncThunk(
  'validateRefreshPasswordToken',
  async (token, thunkAPI) => {
    try {
      const response = await validateResetPasswordToken(token)

      return response
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (data, thunkAPI) => {
    try {
      const { token, password } = data
      const response = await resetPasswordWithNewPassword(token, password)

      return response
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const createLogoutAudit = createAsyncThunk('createLogoutAudit', async (_, thunkAPI) => {
  try {
    await logoutFromPortal()

  } catch (error) {
    const errorData = getAPIRequestError(error)

    console.log('errorData', errorData)
  }

  thunkAPI.dispatch(logout())
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.accessToken = initialState.accessToken
      state.authLoading = initialState.authLoading
      state.errorMessage = initialState.errorMessage
    },
    resetAuthState: state => {
      state.resetPasswordTokenValid = initialState.resetPasswordTokenValid
      state.resetPasswordUsername = initialState.resetPasswordUsername
      state.resetPasswordSuccess = initialState.resetPasswordSuccess
      state.resetPasswordRequestSuccess =
        initialState.resetPasswordRequestSuccess
      state.authLoading = initialState.authLoading
      state.errorMessage = initialState.errorMessage
    },
    clearMFAAuthState: state => {
      state.tempAuthToken = initialState.tempAuthToken
      state.mfaQRCodeData = initialState.mfaQRCodeData
      state.isMFAAuthenticated = initialState.isMFAAuthenticated
      state.authLoading = initialState.authLoading
      state.errorMessage = initialState.errorMessage
    },
    setLoginErrorMessage: (state, { payload }) => {
      state.errorMessage = payload.errorMessage
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.authLoading = true
    })
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.tempAuthToken = payload?.tempAuthToken
      state.isMFAAuthenticated = payload?.isMFAAuthenticated
      state.mfaQRCodeData = payload?.qrData
      state.accessToken = null
      state.authLoading = false
      state.errorMessage = null
    })
    builder.addCase(login.rejected, (state, { payload }) => {
      state.tempAuthToken = null
      state.isMFAAuthenticated = null
      state.mfaQRCodeData = null
      state.accessToken = null
      state.authLoading = false
      state.errorMessage = payload?.error.message
    })

    builder.addCase(getMFAAuthenticationLink.pending, state => {
      state.authLoading = true
    })
    builder.addCase(getMFAAuthenticationLink.fulfilled, (state, { payload }) => {
      state.mfaQRCodeData = payload?.qrData
      state.authLoading = false
      state.errorMessage = null
    })
    builder.addCase(getMFAAuthenticationLink.rejected, (state, { payload }) => {
      state.mfaQRCodeData = null
      state.authLoading = false
      state.errorMessage = payload?.error.message
    })

    builder.addCase(getAccessToken.pending, state => {
      state.authLoading = true
    })
    builder.addCase(getAccessToken.fulfilled, (state, { payload }) => {
      state.accessToken = payload?.accessToken
      state.role = payload.userRole
      state.timezone = payload.timezone
      state.remittanceType = payload.remittanceType

      state.tempAuthToken = null
      state.mfaQRCodeData = null

      state.authLoading = false
      state.errorMessage = null
    })
    builder.addCase(getAccessToken.rejected, (state, { payload }) => {
      state.accessToken = null
      state.authLoading = false
      state.errorMessage = payload?.error.message
    })

    builder.addCase(requestResetPassword.pending, state => {
      state.authLoading = true
    })
    builder.addCase(requestResetPassword.fulfilled, state => {
      state.authLoading = false
      state.resetPasswordRequestSuccess = true
      state.errorMessage = null
    })
    builder.addCase(requestResetPassword.rejected, (state, { payload }) => {
      state.authLoading = false
      state.resetPasswordRequestSuccess = false
      state.errorMessage = payload?.error.message
    })

    builder.addCase(validateRefreshToken.pending, state => {
      state.authLoading = true
    })
    builder.addCase(validateRefreshToken.fulfilled, (state, { payload }) => {
      state.authLoading = false
      state.resetPasswordTokenValid = payload.valid
      state.resetPasswordUsername = payload.username ?? ''
      state.errorMessage = null
    })
    builder.addCase(validateRefreshToken.rejected, (state, { payload }) => {
      state.authLoading = false
      state.resetPasswordTokenValid = false
      state.resetPasswordUsername = null
      state.errorMessage = payload?.error.message
    })

    builder.addCase(resetPassword.pending, state => {
      state.authLoading = true
    })
    builder.addCase(resetPassword.fulfilled, state => {
      state.authLoading = false
      state.resetPasswordSuccess = true
      state.errorMessage = null
    })
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.authLoading = false
      state.resetPasswordSuccess = false
      state.errorMessage = payload?.error.message
    })
  }
})

// Action creators are generated for each case reducer function
export const { logout, resetAuthState, setLoginErrorMessage, clearMFAAuthState } =
  authSlice.actions

export default authSlice.reducer

export const getPasswordLoginStatus = createSelector(
  state => state.auth.tempAuthToken,
  tempAuthToken => !!tempAuthToken
)

export const getMFAAuthStatus = createSelector(
  state => state.auth.isMFAAuthenticated,
  isMFAAuthenticated => isMFAAuthenticated
)

export const getMFAQRData = createSelector(
  state => state.auth.mfaQRCodeData,
  mfaQRCodeData => mfaQRCodeData
)

export const getLoginStatus = createSelector(
  state => state.auth.accessToken,
  accessToken => !!accessToken
)

export const getUserRole = createSelector(
  state => state.auth.role,
  role => role
)

export const getUserRemittanceType = createSelector(
  state => state.auth.remittanceType,
  remittanceType => remittanceType?.toUpperCase()
)

export const getLoginLoadingStatus = createSelector(
  state => state.auth.authLoading,
  authLoading => authLoading
)

export const getLoginErrorMessage = createSelector(
  state => state.auth.errorMessage,
  errorMessage => errorMessage
)

export const getRequestResetPasswordStatus = createSelector(
  state => state.auth.resetPasswordRequestSuccess,
  resetPasswordRequestSuccess => resetPasswordRequestSuccess
)

export const getRefreshPasswordTokenValid = createSelector(
  state => state.auth.resetPasswordTokenValid,
  resetPasswordTokenValid => resetPasswordTokenValid
)

export const getRefreshPasswordUsername = createSelector(
  state => state.auth.resetPasswordUsername,
  resetPasswordUsername => resetPasswordUsername
)

export const getRefreshPasswordStatus = createSelector(
  state => state.auth.resetPasswordSuccess,
  resetPasswordSuccess => resetPasswordSuccess
)
