import {
  INPUT_TYPE_DATE,
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TIME
} from '../../constants'

//for edit modal
export const companyDetailForm = {
  companyName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Company Name',
      type: INPUT_TYPE_TEXT,
      disabled: true
    },
    value: '',
    touched: false
  },
  billingCity: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Billing City',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  billingCountry: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Billing Country',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  billingPostCode: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Billing PostCode',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  billingState: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Billing State',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  billingStreet: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Billing Street',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  abn: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'ABN',
      type: INPUT_TYPE_TEXT,
      disabled: true
    },
    value: '',
    touched: false
  },
  phoneNumber: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Phone Number',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  reportingThreshold: {
    inputType: INPUT_TYPE_DROPDOWN,
    inputProps: {
      label:
        'Does your organisation meet reporting thresholds under the "modern slavery act"?',
      required: true
    },
    options: [
      { label: 'Yes', id: 'Yes' },
      { label: 'No', id: 'No' }
    ],
    value: '',
    touched: false
  },
  remittanceEmail: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      label: 'Remittance Email',
      type: INPUT_TYPE_EMAIL,
      required: true
    },
    value: '',
    touched: false
  },
  accountsEmail: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      label: 'Accounts / Invoice email address',
      type: INPUT_TYPE_EMAIL,
      required: true
    },
    value: '',
    touched: false
  },
  contactTimeStart: {
    inputType: INPUT_TYPE_TIME,
    inputProps: {
      label: 'Allowed Contact Time (Start)',
      disabled: true
    },
    value: null,
    touched: false
  },
  contactTimeEnd: {
    inputType: INPUT_TYPE_TIME,
    inputProps: {
      label: 'Allowed Contact Time (End)',
      disabled: true
    },
    value: null,
    touched: false
  }
}

//for display read only detail
export const companyDetailFormReadOnly = {
  companyName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      type: INPUT_TYPE_TEXT
    },
    value: ''
  },
  companyAddress: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      type: INPUT_TYPE_TEXT
    },
    value: ''
  },
  abn: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'ABN',
      type: INPUT_TYPE_TEXT
    },
    value: ''
  },
  phoneNumber: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      type: INPUT_TYPE_TEXT
    },
    value: ''
  },
  reportingThreshold: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      type: INPUT_TYPE_TEXT,
      label:
        'Does your organisation meet reporting thresholds under the "modern slavery act"?'
    },
    value: ''
  },
  remittanceEmail: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      type: INPUT_TYPE_TEXT
    },
    value: ''
  },
  accountsEmail: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Accounts / Invoice email address',
      type: INPUT_TYPE_TEXT
    },
    value: ''
  },
  firstFileReferredDate: {
    inputType: INPUT_TYPE_DATE,
    inputProps: {
      label: 'First file referred date (anticipated)'
    },
    value: null
  },
  contactTimeStart: {
    inputType: INPUT_TYPE_TIME,
    inputProps: {
      label: 'Allowed Contact Time (Start)'
    },
    value: null
  },
  contactTimeEnd: {
    inputType: INPUT_TYPE_TIME,
    inputProps: {
      label: 'Allowed Contact Time (End)'
    },
    value: null
  }
}

export const clientSpecificInfoForm = {
  numberForDebtorContact: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Number for Debtors to contact you on',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  emailForDebtorContact: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      label: 'Email for Debtors to contact you on',
      type: INPUT_TYPE_EMAIL,
      required: true
    },
    value: '',
    touched: false
  },
  furtherRequirements: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Please Identify any further requirements you have',
      type: INPUT_TYPE_TEXT
    },
    value: '',
    touched: false
  }
}

export const relationshipContactForm = {
  relationshipContactName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Relationship Contact Name',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  relationshipContactPhone: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Relationship Contact Phone',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  relationshipContactEmail: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      label: 'Relationship Contact Email',
      type: INPUT_TYPE_EMAIL,
      required: true
    },
    value: '',
    touched: false
  },
  relationshipContactJobTitle: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Relationship Contact Job Title',
      type: INPUT_TYPE_TEXT
    },
    value: '',
    touched: false
  }
}

export const secondRelationshipContactForm = {
  secondRelationshipContactName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Second Relationship Contact Name',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  secondRelationshipContactPhone: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Second Relationship Contact Phone',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  secondRelationshipContactEmail: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Second Relationship Contact Email',
      type: INPUT_TYPE_TEXT,
      required: true
    },
    value: '',
    touched: false
  },
  secondRelationshipContactJobTitle: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Second Relationship Contact Job Title',
      type: INPUT_TYPE_TEXT
    },
    value: '',
    touched: false
  }
}

export const operationalContactForm = {
  operationalContactPhone: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      type: INPUT_TYPE_TEXT
    },
    value: '',
    touched: false
  },
  operationalContactEmail: {
    inputType: INPUT_TYPE_EMAIL,
    inputProps: {
      type: INPUT_TYPE_EMAIL
    },
    value: '',
    touched: false
  }
}
