import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { toLower } from 'lodash'
import { getBulletinList, getJobList, getTaskList } from '../../api/home'
import {
  JOB_ACTIVE_STATUS,
  JOB_PENDING_STATUS,
  JOB_SUSPENDED_STATUS,
  JOB_UPGRADE_STATUS,
  JOB_CLOSED_STATUS
} from '../../constants/job'
import {
  createReduxRecordListState,
  updateReduxRecords
} from '../../helpers/redux'
import { extractPagination, getAPIRequestError } from '../../helpers/service'

const initialState = {
  outstandingTasks: createReduxRecordListState(),
  activeJobs: createReduxRecordListState(),
  suspendedJobs: createReduxRecordListState(),
  upgradeJobs: createReduxRecordListState(),
  closedJobs: createReduxRecordListState(),
  bulletins: createReduxRecordListState(null)
}

export const fetchBulletins = createAsyncThunk(
  'fetchBulletins',
  async (_, thunkAPI) => {
    try {
      const response = await getBulletinList(1, 1000)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchActiveJobs = createAsyncThunk(
  'fetchActiveJobs',
  async ({ page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getJobList(JOB_ACTIVE_STATUS, page, size)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchSuspendedJobs = createAsyncThunk(
  'fetchSuspendedJobs',
  async ({ page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getJobList(JOB_SUSPENDED_STATUS, page, size)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchUpgradeJobs = createAsyncThunk(
  'fetchUpgradeJobs',
  async ({ page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getJobList(JOB_UPGRADE_STATUS, page, size)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchClosedJobs = createAsyncThunk(
  'fetchClosedJobs',
  async ({ page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getJobList(JOB_CLOSED_STATUS, page, size)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchOutstandingTasks = createAsyncThunk(
  'fetchOutstandingTasks',
  async ({ page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getTaskList(JOB_PENDING_STATUS, page, size)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const home = createSlice({
  name: 'home',
  initialState,
  reducers: {
    resetHomePageState: () => initialState,
    hideBulletinById: (state, { payload }) => {
      state.bulletins.records = state.bulletins.records?.filter(
        item => item.id !== payload.bulletinId
      )
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBulletins.pending, state => {
      state.bulletins.loading = true
    })
    builder.addCase(fetchBulletins.fulfilled, (state, { payload }) => {
      state.bulletins.loading = false
      state.bulletins.records = payload.data?.map(item => ({
        ...item,
        variant:
          item.displayType === 'Informational'
            ? 'info'
            : toLower(item.displayType)
      }))
      state.bulletins.error = null
    })
    builder.addCase(fetchBulletins.rejected, (state, { payload }) => {
      state.bulletins.loading = false
      state.bulletins.error = payload?.error.message
    })

    builder.addCase(fetchActiveJobs.pending, state => {
      state.activeJobs.loading = true
    })
    builder.addCase(fetchActiveJobs.fulfilled, (state, { payload }) => {
      state.activeJobs.loading = false
      state.activeJobs.records = updateReduxRecords(
        state.activeJobs.records,
        payload.data,
        payload.pagination?.page
      )

      state.activeJobs.page = payload.pagination?.page
      state.activeJobs.size = payload.pagination?.size
      state.activeJobs.total = payload.pagination?.total
      state.activeJobs.totalPages = payload.pagination?.totalPages
      state.activeJobs.error = null
    })
    builder.addCase(fetchActiveJobs.rejected, (state, { payload }) => {
      state.activeJobs.loading = false
      state.activeJobs.records = []
      state.activeJobs.error = payload?.error.message
    })

    builder.addCase(fetchSuspendedJobs.pending, state => {
      state.suspendedJobs.loading = true
    })
    builder.addCase(fetchSuspendedJobs.fulfilled, (state, { payload }) => {
      state.suspendedJobs.loading = false
      state.suspendedJobs.records = updateReduxRecords(
        state.suspendedJobs.records,
        payload.data,
        payload.pagination?.page
      )
      state.suspendedJobs.page = payload.pagination?.page
      state.suspendedJobs.size = payload.pagination?.size
      state.suspendedJobs.total = payload.pagination?.total
      state.suspendedJobs.totalPages = payload.pagination?.totalPages
      state.suspendedJobs.error = null
    })
    builder.addCase(fetchSuspendedJobs.rejected, (state, { payload }) => {
      state.suspendedJobs.loading = false
      state.suspendedJobs.records = []
      state.suspendedJobs.error = payload?.error.message
    })

    builder.addCase(fetchUpgradeJobs.pending, state => {
      state.upgradeJobs.loading = true
    })
    builder.addCase(fetchUpgradeJobs.fulfilled, (state, { payload }) => {
      state.upgradeJobs.loading = false
      state.upgradeJobs.records = updateReduxRecords(
        state.upgradeJobs.records,
        payload.data,
        payload.pagination?.page
      )
      state.upgradeJobs.page = payload.pagination?.page
      state.upgradeJobs.size = payload.pagination?.size
      state.upgradeJobs.total = payload.pagination?.total
      state.upgradeJobs.totalPages = payload.pagination?.totalPages
      state.upgradeJobs.error = null
    })
    builder.addCase(fetchUpgradeJobs.rejected, (state, { payload }) => {
      state.upgradeJobs.loading = false
      state.upgradeJobs.records = []
      state.upgradeJobs.error = payload?.error.message
    })

    builder.addCase(fetchClosedJobs.pending, state => {
      state.closedJobs.loading = true
    })
    builder.addCase(fetchClosedJobs.fulfilled, (state, { payload }) => {
      state.closedJobs.loading = false
      state.closedJobs.records = updateReduxRecords(
        state.closedJobs.records,
        payload.data,
        payload.pagination?.page
      )
      state.closedJobs.page = payload.pagination?.page
      state.closedJobs.size = payload.pagination?.size
      state.closedJobs.total = payload.pagination?.total
      state.closedJobs.totalPages = payload.pagination?.totalPages
      state.closedJobs.error = null
    })
    builder.addCase(fetchClosedJobs.rejected, (state, { payload }) => {
      state.closedJobs.loading = false
      state.closedJobs.records = []
      state.closedJobs.error = payload?.error.message
    })

    builder.addCase(fetchOutstandingTasks.pending, state => {
      state.outstandingTasks.loading = true
    })
    builder.addCase(fetchOutstandingTasks.fulfilled, (state, { payload }) => {
      state.outstandingTasks.loading = false
      state.outstandingTasks.records = updateReduxRecords(
        state.outstandingTasks.records,
        payload.data,
        payload.pagination?.page
      )
      state.outstandingTasks.page = payload.pagination?.page
      state.outstandingTasks.size = payload.pagination?.size
      state.outstandingTasks.total = payload.pagination?.total
      state.outstandingTasks.totalPages = payload.pagination?.totalPages
      state.outstandingTasks.error = null
    })
    builder.addCase(fetchOutstandingTasks.rejected, (state, { payload }) => {
      state.outstandingTasks.loading = false
      state.outstandingTasks.records = []
      state.outstandingTasks.error = payload?.error.message
    })
  }
})

export const { resetHomePageState, hideBulletinById } = home.actions
export default home.reducer

export const getBulletinRecords = createSelector(
  state => state.home.bulletins,
  bulletins => bulletins.records
)

export const getActiveJobsData = createSelector(
  state => state.home.activeJobs,
  jobData => jobData
)

export const getSuspendedJobsData = createSelector(
  state => state.home.suspendedJobs,
  jobData => jobData
)

export const getUpgradeJobsData = createSelector(
  state => state.home.upgradeJobs,
  jobData => jobData
)

export const getClosedJobsData = createSelector(
  state => state.home.closedJobs,
  jobData => jobData
)

export const getOutstandingTasksData = createSelector(
  state => state.home.outstandingTasks,
  jobData => jobData
)
