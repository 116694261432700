import React from 'react'
import PropTypes from 'prop-types'

import Dropzone from 'react-dropzone'

import { Button, ButtonGroup } from '@salesforce/design-system-react'

import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen'
import styled from 'styled-components'
import { isArray, isEmpty, uniqueId } from 'lodash'

const StyledSection = styled.section({
  border: '1px dotted #CDCDCD',
  padding: '0.125rem',
  maxWidth: 235,
  borderRadius: '0.375rem',

  '@media(max-width: 768px)': {
    maxWidth: 'fit-content'
  }
})

const StyledButton = styled(Button)({ height: '2.25rem' })

const FileButton = styled(Button)`
  height: 2rem;

  &.has-error:focus-visible,
  &.has-error:focus,
  &.has-error:active,
  &.has-error:hover {
    cursor: default;
    color: #ea001e;
    border: 1px solid #c9c9c9;
    box-shadow: none;
  }

  &.is-loading:focus-visible,
  &.is-loading:focus,
  &.is-loading:active,
  &.is-loading:hover {
    cursor: default;
    border: 1px solid #c9c9c9;
    color: #747474;
    box-shadow: none;
  }

  &.is-success:focus-visible,
  &.is-success:focus,
  &.is-success:active,
  &.is-success:hover {
    cursor: default;
    border: 1px solid #c9c9c9;
    color: #2e844a;
    box-shadow: none;
  }
`

const FileDropInput = ({
  label,
  onFileChange,
  value,
  required,
  disabled,
  maxFiles,
  multiple,
  accept,
  errorText,
  onRemoveFile,
  uploadedFileStatus,
  ...props
}) => {
  const isMobile = useCheckMobileScreen(value)
  const componentId = uniqueId('fileDropInput-')

  const dropFileTextDisplay = isMobile ? null : (
    <span className='slds-col slds-p-horizontal_x-small'>or Drop Files</span>
  )

  const fileListDisplay =
    isArray(value) && !isEmpty(value) ? (
      <ul className='slds-grid slds-wrap slds-grid-pull_left'>
        {value.map((item, index) => (
          <li
            key={`${item.name}-${uniqueId('fileUploading-')}`}
            className={`slds-m-around_xx-small ${
              value.length - 1 === index ? 'slds-col_bump-left' : ''
            }`}
          >
            <ButtonGroup>
              {uploadedFileStatus?.[item.name] === 'error' ? (
                <FileButton
                  className={'slds-truncate slds-text-color_error has-error'}
                  iconCategory='utility'
                  iconName='warning'
                  iconVariant='border'
                  variant='icon'
                  title={"This file can't be uploaded."}
                />
              ) : null}

              {uploadedFileStatus?.[item.name] === 'loading' ? (
                <FileButton
                  className={'slds-truncate is-loading'}
                  iconCategory='utility'
                  iconName='spinner'
                  iconVariant='border'
                  variant='icon'
                />
              ) : null}

              {uploadedFileStatus?.[item.name] === 'success' ? (
                <FileButton
                  className={'slds-truncate slds-text-color_success is-success'}
                  iconCategory='utility'
                  iconName='check'
                  iconVariant='border'
                  variant='icon'
                  title={'This file has been uploaded successfully.'}
                />
              ) : null}

              <FileButton
                className={'slds-truncate'}
                label={`${item.name}`}
                iconPosition='left'
                iconName='pdf'
                iconCategory='doctype'
              />
              {onRemoveFile ? (
                <FileButton
                  iconCategory='utility'
                  iconName='close'
                  iconVariant='border'
                  variant='icon'
                  onClick={() => onRemoveFile(item)}
                />
              ) : (
                <></>
              )}
            </ButtonGroup>
          </li>
        ))}
      </ul>
    ) : null

  const requiredFlag = required ? (
    <abbr className='slds-required' title='required'>
      *
    </abbr>
  ) : null

  return (
    <div className='slds-form-element'>
      <label className='slds-form-element__label' htmlFor={componentId}>
        {requiredFlag}
        {label}
      </label>
      <Dropzone
        onDrop={onFileChange}
        disabled={disabled}
        maxFiles={maxFiles}
        multiple={multiple}
        accept={accept}
        {...props}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <StyledSection>
              <div
                id={componentId}
                className='slds-grid slds-wrap slds-grid_align-space slds-grid_vertical-align-center slds-form-element__control'
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <span className='slds-col'>
                  <StyledButton
                    label='Upload Files'
                    iconName='upload'
                    iconCategory='utility'
                    iconPosition='left'
                  />
                </span>
                {dropFileTextDisplay}
              </div>
            </StyledSection>
            <div className='slds-text-color_error'>{errorText}</div>
            {fileListDisplay}
          </>
        )}
      </Dropzone>
    </div>
  )
}

FileDropInput.propTypes = {
  onFileChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
  onRemoveFile: PropTypes.func,
  uploadedFileStatus: PropTypes.object
}

export default FileDropInput
