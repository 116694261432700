import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { LoginButton } from '../../../pages/LoginPage/StyledLoginItems'

const ResetPasswordResult = ({ onReturnClickedHandler }) => {
  return (
    <>
      <p className='slds-m-around_x-small'>
        Your password has been reset successfully. Please return to the login
        page to login.
      </p>
      <LoginButton
        className='slds-m-top_small'
        type='button'
        variant='brand'
        label='Return to Login'
        onClick={onReturnClickedHandler}
      />
    </>
  )
}

ResetPasswordResult.propTypes = {
  onReturnClickedHandler: PropTypes.func.isRequired
}

export default memo(ResetPasswordResult)
