import styled from 'styled-components'

export const SearchBoxWrapper = styled.div`
  display: inline-block;
  padding-right: 5px;
  @media screen and (max-width: 763px) {
    width: 59%;
  }
`
export const SearchButtonWrapper = styled.div`
  display: inline-flex;
`

export const SearchSectionWrapper = styled.div`
  @media screen and (max-width: 763px) {
    padding-top: 10px;
  }
`
