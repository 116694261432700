import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'

import { DatePicker } from '@salesforce/design-system-react'
import { getDateFormat } from '../../../helpers/date'
import { StandardInput } from '../StandardInput'
import styled from 'styled-components'
import { DEFAULT_DATE_FORMAT } from '../../../constants'

const FullWidthDatePickerContainer = styled.div`
  .full-width {
    width: 100%;
  }
`

const StandardDatePicker = ({
  name,
  label,
  value,
  required,
  dateDisabled = () => false,
  disabled,
  readOnly,
  errorText,
  onChange
}) => {
  const dateValue = value ? dayjs(value).toDate() : null
  const onDateSelectedHandler = useCallback(
    (e, value) => {
      const sourceEvent = { ...e }
      const dateValue = dayjs(value.date)
      onChange(dateValue, name, sourceEvent)
    },
    [onChange, name]
  )

  return (
    <FullWidthDatePickerContainer>
      <DatePicker
        triggerClassName='full-width'
        name={name}
        labels={{ label }}
        onChange={onDateSelectedHandler}
        formatter={date => {
          return date ? dayjs(date).format(DEFAULT_DATE_FORMAT) : ''
        }}
        parser={dateString => {
          return dayjs(dateString, DEFAULT_DATE_FORMAT)
        }}
        disabled={disabled}
        dateDisabled={dateDisabled}
        input={
          <StandardInput
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            value={getDateFormat(value, false) ?? ''}
            onChange={() => null}
          />
        }
        hasError={!!errorText}
        value={dateValue}
      />
    </FullWidthDatePickerContainer>
  )
}

StandardDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errorText: PropTypes.string,
  dateDisabled: PropTypes.func
}

export default StandardDatePicker
