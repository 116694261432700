import { INPUT_TYPE_PASSWORD, INPUT_TYPE_TEXT } from '../../constants'

const allowedSpecialChars = "@%+/\\'!#$^&?:,(){}[\\]~\\-_."

export const passwordValidation = {
  minLength: { label: '$1 Characters', value: 16, regex: '^[\\w\\W]{$1,}$' },
  minUpperCase: {
    label: '$1 Uppercase letter',
    value: 1,
    regex: `^(?=.*[A-Z]{$1,})[\\w\\W]+$`
  },
  minLowerCase: {
    label: '$1 Lowercase letter',
    value: 1,
    regex: `^(?=.*[a-z]{$1,})[\\w\\W]+$`
  },
  minNumber: {
    label: '$1 Number',
    value: 1,
    regex: `^(?=.*[\\d]{$1,})[\\w\\W]+$`
  },
  minSpecialChar: {
    label: '$1 Special character',
    value: 1,
    // regex: `^(?=.*[${allowedSpecialChars}]{$1,})[\\w\\W]+$` // Allow all special chars rather than allowed list
    regex: `^(?=.*[${allowedSpecialChars}]{$1,})[\\w${allowedSpecialChars}]+$` // Only allows only allowed list
  }
}

export const validatePassword = password => {
  let allValid = true
  const validations = Object.entries(passwordValidation).reduce(
    (validated, rule) => {
      const [ruleName, ruleAttr] = rule

      const regexString = ruleAttr.regex.replace('$1', ruleAttr.value)
      const validationRegex = new RegExp(regexString)

      const valid = validationRegex.test(password)

      if (!valid) {
        allValid = false
        return { ...validated, [ruleName]: false }
      }
      return { ...validated, [ruleName]: true }
    },
    {}
  )

  return { validations, allValid }
}

export default {
  newPassword: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'New Password',
      type: INPUT_TYPE_PASSWORD,
      errorText: null,
      required: true,
      styleInput: { height: '2.75rem' },
      minLength: `${passwordValidation.minLength.value}`
    },
    value: ''
  },
  confirmPassword: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Confirm Password',
      type: INPUT_TYPE_PASSWORD,
      errorText: null,
      required: true,
      styleInput: { height: '2.75rem' }
    },
    value: ''
  }
}
