import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { SideMenuToggle } from '../SideMenuToggle'
import {
  StyledActionItem,
  StyledHeader,
  StyledLogoContainer,
  StyledNav
} from './StyledHeader'

import { additionalNavItems, navigationItems } from '../../../routes'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, MenuDropdown } from '@salesforce/design-system-react'
import { useDispatch, useSelector } from 'react-redux'
import { createLogoutAudit, getUserRole } from '../../../redux/slices/auth'
import { getPortalLogoUrl } from '../../../redux/slices/global'
import { ROLE_ADMIN } from '../../../constants'

const buildNavItem = item => ({
  ...item,
  value: item.pathname?.trimLeft('/'),
  leftIcon: {
    name: item.iconName,
    category: item.iconCategory,
    size: item.iconSize
  }
})

const Header = ({
  menuToggleClickHandler,
  newQuoteClickedHandler,
  newJobClickedHandler
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const logoUrl = useSelector(getPortalLogoUrl)
  const role = useSelector(getUserRole)

  const handleMenuClicked = useCallback(event => {
    if (event.value === 'logout') {
      dispatch(createLogoutAudit())
    } else {
      navigate(event?.value ?? location.pathname, { replace: false })
    }
  }, [])

  const dropdownMenuItems =
    role === ROLE_ADMIN
      ? [
          ...additionalNavItems.map(item => buildNavItem(item)),
          { type: 'divider' }
        ]
      : []

  return (
    <StyledHeader>
      <StyledNav>
        <div className='toggle-btn'>
          <SideMenuToggle onClick={menuToggleClickHandler} />
        </div>
        <StyledLogoContainer>
          <Link to='/'>
            <img src={logoUrl} alt='Logo' width={70} />
          </Link>
        </StyledLogoContainer>
        <ul>
          {navigationItems.map(navItem => (
            <Link key={navItem.pathname} to={navItem.pathname}>
              <li>{navItem.label}</li>
            </Link>
          ))}
        </ul>
        <div className='spacer' />
        <StyledActionItem>
          <Button className='toolbar-actions' onClick={newQuoteClickedHandler}>
            New Quote
          </Button>
          <Button className='toolbar-actions' onClick={newJobClickedHandler}>
            New Job
          </Button>
        </StyledActionItem>
        <StyledActionItem>
          <MenuDropdown
            align='right'
            iconCategory='utility'
            iconName='user_role'
            iconVariant='container'
            options={[
              ...dropdownMenuItems,
              { label: 'Logout', value: 'logout' }
            ]}
            onSelect={handleMenuClicked}
          ></MenuDropdown>
        </StyledActionItem>
      </StyledNav>
    </StyledHeader>
  )
}

Header.propTypes = {
  menuToggleClickHandler: PropTypes.func,
  newQuoteClickedHandler: PropTypes.func,
  newJobClickedHandler: PropTypes.func
}

export default Header
