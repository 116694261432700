import React from 'react'
import PropTypes from 'prop-types'

import { LightningDataTable } from '../../../common'
import { generateTableColumns } from '../../../../helpers/dataTable'

import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen'

import { TABLE_HEIGHT_HOME } from '../../../../constants'

const ClosedJobsTable = ({
  onSort,
  jobList,
  onLoadMore,
  hasMore,
  total,
  columnDefinitions,
  isFiltering
}) => {
  const isMobile = useCheckMobileScreen()

  let columnDefs = columnDefinitions
  if (isMobile) {
    columnDefs = columnDefinitions.filter((col, index) => index < 2)
  }
  const columns = generateTableColumns(columnDefs)

  return (
    <LightningDataTable
      columns={columns}
      items={jobList}
      id='closedJobs'
      totalItems={total}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      tableHeight={TABLE_HEIGHT_HOME}
      isFiltering={isFiltering}
      onSort={onSort}
    />
  )
}

ClosedJobsTable.propTypes = {
  onSort: PropTypes.func,
  jobList: PropTypes.array.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  total: PropTypes.number,
  columnDefinitions: PropTypes.array,
  isFiltering: PropTypes.bool
}

export default ClosedJobsTable
