import {
  buildCurrencyColumn,
  buildDateTimeColumn,
  buildSimpleColumn
} from '../../../helpers/dataTable'

export default ({ sortColumn, sortDirectionMemo }) => [
  buildSimpleColumn('Promise to Pay #', 'promiseToPay', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn('Job #', 'jobNo', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn('Client Ref #', 'contractNo', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildCurrencyColumn(
    'Amount',
    'paymentAmount',
    false,
    {
      thousandSeparator: true,
      decimalPoint: 2
    },
    {
      sortable: true,
      sortColumn,
      sortDirectionMemo
    }
  ),
  buildDateTimeColumn(
    'Date',
    'paymentDate',
    false,
    { withTime: false },
    { sortable: true, sortColumn, sortDirectionMemo }
  ),
  buildSimpleColumn('Status', 'status', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  }),
  buildSimpleColumn('Customer Name', 'customerName', false, {
    sortable: true,
    sortColumn,
    sortDirectionMemo
  })

]
