import React from 'react'
import styled from 'styled-components'

import { MyAccountContent } from '../../containers'

const MyAccountPageWrapper = styled.div`
background: white;
padding: 0.5rem;
width: 75%;
margin: auto;
min-height: 100vh;
height: 100%;

  @media screen and (max-width: 763px) {
    width: 100%;
  }
`
const MyAccountContainer = styled.div`
display: flex;
flex-direction: column;

  @media screen and (max-width: 763px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const MyAccountPage = () => {
  return (
    <MyAccountPageWrapper>
      <MyAccountContainer>
        <MyAccountContent />
      </MyAccountContainer>
    </MyAccountPageWrapper>
  )
}

export default MyAccountPage
