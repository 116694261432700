import styled from 'styled-components'

export const CardWrapper = styled.div`
  .slds-grid {
    width: 100%;
  }
  .searchBox {
    right: 0;
    display: inline-block;
  }
  @media screen and (max-width: 763px) {
    .slds-size_1-of-2 {
      padding-top: 20px;
    }
    .searchBox {
      width: 100%;
      right: 0;
      display: block;
    }
  }
`
