import React from 'react'
import PropTypes from 'prop-types'
import { Markup } from 'interweave'
import { MediaObject } from '@salesforce/design-system-react'
import { Divider } from '../../common'
// import { getDateFormat } from '../../../helpers/date'
import styled from 'styled-components'

const ColoredAbbr = styled.abbr`
  background-color: #${props => props.color};
`

const CommentItem = ({
  commentText,
  commenterName,
  // commentDate,
  // commenterProfileImageSrc,
  commenterColor,
  commenterDisplayInitials
}) => {
  let avatar = (
    <ColoredAbbr
      color={commenterColor}
      className='slds-avatar__initials slds-icon-standard-user custom-color-avatar'
      title={commenterDisplayInitials}
    >
      {commenterDisplayInitials}
    </ColoredAbbr>
  )
  return (
    <>
      <MediaObject
        body={
          <div className='slds-m-around_x-small'>
            <div className='slds-text-heading_small slds-text-title_bold'>
              {commenterName}
            </div>
            <div className='slds-p-vertical_small'>
              <Markup content={commentText} />
            </div>
          </div>
        }
        figure={
          <div>
            <span className='slds-avatar slds-avatar_circle slds-avatar_medium'>
              {avatar}
            </span>
          </div>
        }
        verticalCenter
      />
      <Divider />
    </>
  )
}

CommentItem.propTypes = {
  commenterName: PropTypes.string,
  // commentDate: PropTypes.string,
  commentText: PropTypes.string,
  // commenterProfileImageSrc: PropTypes.string,
  commenterColor: PropTypes.string,
  commenterDisplayInitials: PropTypes.string
}

export default CommentItem
