import React, { memo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { StandardModal } from '../../common'
import { extractForm, getFormDisplay, getFormValidStatus } from '../../../helpers/form'
import { Button } from '@salesforce/design-system-react'

import { useSelector } from 'react-redux'
import { getDefaultQuoteCaseRecordTypeId } from '../../../redux/slices/global'

const TextStyledDivWrap = styled.div`
  #description {
    height: 7rem;
  }
`

const CustomJobQuoteModal = ({
  isOpen,
  formData,
  onInputChangedHandler,
  onSubmit,
  onCancel,
  quoteData
}) => {
  const formRef = useRef(null)
  const recordTypeId = useSelector(getDefaultQuoteCaseRecordTypeId)
  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const form = formRef.current
    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)
      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData)
        onSubmit({data, quoteData, recordTypeId:recordTypeId})
      }
    }
  }, [formData, quoteData])

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onCancel}
      modalTitle='Custom Job Quote'
      footer={
        <div className='slds-text-align_right'>
          <Button variant='brand' onClick={onSaveButtonClickedHandler} label='Create Request' />
        </div>
      }
    >
      <TextStyledDivWrap>
      <form ref={formRef} onSubmit={onSubmittedHandler}>
        {formDisplay}
      </form>
      </TextStyledDivWrap>
    </StandardModal>
  )
}

CustomJobQuoteModal.propTypes = {
  isOpen: PropTypes.bool,
  formData: PropTypes.object,
  onInputChangedHandler: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  quoteData: PropTypes.object
}

export default memo(CustomJobQuoteModal)
