import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { getLoginStatus, getUserRole } from '../../redux/slices/auth'
import { ROLE_ADMIN } from '../../constants'

const AdminOnlyRoute = ({ children }) => {
  const isLoggedIn = useSelector(getLoginStatus)
  const userRole = useSelector(getUserRole)

  return isLoggedIn && userRole === ROLE_ADMIN ? (
    children
  ) : (
    <Navigate to='/notFound' />
  )
}

AdminOnlyRoute.propTypes = { children: PropTypes.node }

export default AdminOnlyRoute
