import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Card } from '@salesforce/design-system-react'
import { getFormDisplay } from '../../../helpers/form'
import { LinkButton, StandardInput } from '../../common'
import styled from 'styled-components'
import { Autocomplete } from '@react-google-maps/api'

const CardWrapper = styled.div`
  .cardBox {
    margin: auto;
  }
  .slds-input {
    padding-left: 0.75rem;
  }
`

const CreateQuoteForm = ({
  form,
  lockAddressInput,
  existingQuote,
  onFormInputChanged,
  onFormSubmitted,
  onGetQuoteClicked,
  onGetCustomRequestClicked,
  onGoogleMapLoad,
  onPlaceChanged,
  getQuoteSuccess,
  getQuoteError,
  getQuoteLoading,
  isSkipTraceJob,
  isAssetManagementJob
}) => {
  const formDisplay = getFormDisplay(form, onFormInputChanged)

  const getQuoteButton =
    getQuoteSuccess || getQuoteError ? null : (
      <div className={`slds-text-align_right`}>
        <p className='slds-m-bottom_xx-small'>
          Can&apos;t find your address? Send us a
          <LinkButton
            className='slds-text-link'
            onClick={onGetCustomRequestClicked}
          >
            custom request.
          </LinkButton>
        </p>
        <Button
          onClick={onGetQuoteClicked}
          className='slds-size_1-of-1 slds-large-size_2-of-8'
          variant='brand'
          label='Get Quote'
          disabled={getQuoteLoading}
          iconPosition='left'
          iconName={getQuoteLoading ? 'spinner' : null}
          iconCategory={getQuoteLoading ? 'utility' : null}
        />
      </div>
    )

  const googleMapAutocomplete = !existingQuote && !isAssetManagementJob ? (
    <Autocomplete
      onLoad={onGoogleMapLoad}
      onPlaceChanged={onPlaceChanged}
      fields={[
        'geometry.location',
        'address_components',
        'formatted_address',
        'name'
      ]}
    >
      <StandardInput
        label={isSkipTraceJob ? 'Last Known Address' : 'Address'}
        name={'address'}
        type='text'
        value={form.address?.value ?? ''}
        placeholder='Address'
        onChange={onFormInputChanged}
        disabled={lockAddressInput}
        required
      />
    </Autocomplete>
  ) : null

  return (
    <CardWrapper>
      <div className='slds-p-around_small slds-medium-size_1-of-2 cardBox'>
        <Card heading={''}>
          <form className='slds-p-around_medium' onSubmit={onFormSubmitted}>
            {formDisplay}
            {googleMapAutocomplete}
            {getQuoteButton}
          </form>
        </Card>
      </div>
    </CardWrapper>
  )
}

CreateQuoteForm.propTypes = {
  form: PropTypes.object,
  onFormInputChanged: PropTypes.func,
  onFormSubmitted: PropTypes.func,
  onGetQuoteClicked: PropTypes.func,
  onGetCustomRequestClicked: PropTypes.func,

  existingQuote: PropTypes.object,
  lockAddressInput: PropTypes.bool,
  onGoogleMapLoad: PropTypes.func,
  onPlaceChanged: PropTypes.func,

  getQuoteSuccess: PropTypes.bool,
  getQuoteError: PropTypes.bool,
  getQuoteLoading: PropTypes.bool,

  isSkipTraceJob: PropTypes.bool,
  isAssetManagementJob: PropTypes.bool
}

export default memo(CreateQuoteForm)
