import dayjs from 'dayjs'
// import { getDateFormat } from '../../helpers/date'
import {
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_DATE,
  INPUT_TYPE_TEXTAREA,
  // INPUT_TYPE_TEXT
} from '../../constants'

export default {
  // jobName: {
  //   inputType: INPUT_TYPE_TEXT,
  //   inputProps: {
  //     label: 'Job Name',
  //     type: INPUT_TYPE_TEXT,
  //     errorText: null,
  //     readOnly: true
  //   },
  //   value: '',
  //   valid: false
  // },
  amount: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Amount',
      type: INPUT_TYPE_NUMBER,
      errorText: null,
      required: true,
      fixedTextLeft: '$'
    },
    value: '',
    valid: false
  },
  paymentDate: {
    inputType: INPUT_TYPE_DATE,
    inputProps: {
      label: 'Date Received',
      errorText: null,
      required: true
    },
    value: dayjs().toDate(),
    valid: false
  },
  currentArrearsToDate: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Current Arrears to Date',
      type: INPUT_TYPE_NUMBER,
      errorText: null,
      required: true,
      fixedTextLeft: '$'
    },
    value: '',
    valid: false
  },
  comment: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Comment',
      type: INPUT_TYPE_TEXTAREA,
      errorText: null,
    },
    value: '',
    valid: false
  },
}
