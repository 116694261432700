import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  getJobDetailById,
  getJobLineListById,
  getJobSecurityListById,
  getPromiseToPaysListById,
  getPaymentsListById,
  getExtraChargesListById,
  getJobCommentsListById,
  getFilesListById,
  postJobCommentById,
  postPaymentByData,
  patchActionJobById,
  postCreateJobApproval
} from '../../api/job'

import { patchUpdateJobClientUpload } from '../../api/submitJob'
import { postUploadJobFile } from '../../api/document'

import { extractPagination, getAPIRequestError } from '../../helpers/service'

import {
  createReduxRecordListState,
  createReduxRecordDetailState,
  createReduxRecordPostResultState,
  updateReduxRecords
} from '../../helpers/redux'
import { JOB_APPROVAL_RECORD_TYPE_NEW_ADDRESS } from '../../constants'
import {downloadFileByFileId} from "../../api/document";

const initialState = {
  jobDetail: createReduxRecordDetailState(),
  jobLineList: createReduxRecordListState(),
  jobSecuritiesList: createReduxRecordListState(),
  jobPromiseToPaysList: createReduxRecordListState(),
  jobPaymentsList: createReduxRecordListState(),
  jobExtraChargesList: createReduxRecordListState(),
  jobCommentsList: createReduxRecordListState(),
  jobFilesList: createReduxRecordListState(),
  holdJobRequestResult: createReduxRecordPostResultState(),
  unholdJobRequestResult: createReduxRecordPostResultState(),
  upgradeJobRequestResult: createReduxRecordPostResultState(),
  actionUpdateJobRequestResult: createReduxRecordPostResultState(),
  costApproveRequestResult: createReduxRecordPostResultState(),
  newAddressApproveRequestResult: createReduxRecordPostResultState(),
  fileUploadRequestResult: createReduxRecordPostResultState(),
  jobFileUploadResult: createReduxRecordPostResultState(),
  jobUpdateResult: createReduxRecordPostResultState(),
  closeJobCommentResult: '',
  addJobCommentResult: '',
  jobFileDownloadResultError: null,
  jobDetailLoading: false,
  addNewPaymentResult: createReduxRecordPostResultState(),
  uploadedFilesStatus: {}
}

export const fetchJobDetailById = createAsyncThunk(
  'fetchJobDetailById',
  async ({ jobId }, thunkAPI) => {
    try {
      const response = await getJobDetailById(jobId)
      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchJobLineListById = createAsyncThunk(
  'fetchJobLineListById',
  async ({ jobId, page = 1, size = 1000 }, thunkAPI) => {
    try {
      const response = await getJobLineListById(jobId, page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchJobSecurityListById = createAsyncThunk(
  'fetchJobSecurityListById',
  async ({ jobId, page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getJobSecurityListById(jobId, page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchPromisetoPaysListById = createAsyncThunk(
  'fetchPromisetoPaysListById',
  async ({ jobId, page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getPromiseToPaysListById(jobId, page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchPaymentsListById = createAsyncThunk(
  'fetchPaymentsListById',
  async ({ jobId, page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getPaymentsListById(jobId, page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchExtraChargesListById = createAsyncThunk(
  'fetchExtraChargesListById',
  async ({ jobId, page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getExtraChargesListById(jobId, page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchJobCommentsListById = createAsyncThunk(
  'fetchJobCommentsListById',
  async ({ jobId, page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getJobCommentsListById(jobId, page, size)
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const updateCloseJobStatus = createAsyncThunk(
  'updateCloseJobStatus',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const postData = {
        comment: data.comment,
        visibility: 'Public',
        commentorType: 'Client',
        jobCloseRequested: true,
        jobId: jobId
      }
      const response = await postJobCommentById(postData)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const requestHoldJob = createAsyncThunk(
  'requestHoldJob',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const postData = {
        comment: `${data.comment}`,
        visibility: 'Public',
        commentorType: 'Client',
        jobHoldRequested: true,
        jobId: jobId,
        holdReleaseDate: data.holdReleaseDate
      }

      const response = await postJobCommentById(postData)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const requestUnholdJob = createAsyncThunk(
  'requestUnholdJob',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const postData = {
        comment: `${data.comment}`,
        visibility: 'Public',
        commentorType: 'Client',
        jobReleaseHoldRequested: true,
        jobId: jobId,
        advisedArrears: data.advisedArrears
      }

      const response = await postJobCommentById(postData)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const requestUpgradeJob = createAsyncThunk(
  'requestUpgradeJob',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const response = await patchActionJobById(jobId, data)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const addJobComment = createAsyncThunk(
  'addJobComment',
  async ({ jobId, comment }, thunkAPI) => {
    try {
      const postData = {
        comment: comment,
        visibility: 'Public',
        commentorType: 'Client',
        jobCloseRequested: false,
        jobId: jobId
      }
      const response = await postJobCommentById(postData)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchJobFileListById = createAsyncThunk(
  'fetchJobFileListById',
  async (jobId, thunkAPI) => {
    try {
      const response = await getFilesListById(jobId)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const downloadFileById = createAsyncThunk(
  'downloadFileById',
  async (fileId, thunkAPI) => {
    try {
      return await downloadFileByFileId(fileId);
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const addNewPaymentByData = createAsyncThunk(
  'addNewPaymentByData',
  async (data, thunkAPI) => {
    try {
      const postData = {
        jobId: data.jobId,
        amount: data.amount,
        paymentDate: data.paymentDate,
        comment: data.comment,
        currentArrearsToDate: data.currentArrearsToDate
      }
      const response = await postPaymentByData(postData)

      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const requestActionUpdateJob = createAsyncThunk(
  'requestActionUpdateJob',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const response = await patchActionJobById(jobId, data)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const costApproveAction = createAsyncThunk(
  'costApproveAction',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const response = await patchActionJobById(jobId, data)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const newAddressApproveAction = createAsyncThunk(
  'newAddressApproveAction',
  async ({ jobId, data }, thunkAPI) => {
    try {
      const buildedData = {
        outcome: data.costApprovalOutcome,
        jobId,
        recordTypeName: JOB_APPROVAL_RECORD_TYPE_NEW_ADDRESS
      }

      const response = await postCreateJobApproval(buildedData)

      return { status: response.status, data: response.data }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const updateJobFileUploadStatus = (thunkAPI, file, fileUploadStatus) => {
  thunkAPI.dispatch(toggleUploadFileStatus({ file, fileUploadStatus }))
}

export const uploadFileToJob = createAsyncThunk(
  'uploadFileToJob',
  async (data, thunkAPI) => {
    let jobId = data.jobId
    const file = data.file
    let errorMessage = null
    try {
      updateJobFileUploadStatus(thunkAPI, file, 'loading')

      await postUploadJobFile(jobId, file)

      updateJobFileUploadStatus(thunkAPI, file, 'success')
    } catch (error) {
      errorMessage = 'Upload job file error! Please check and try again.'

      updateJobFileUploadStatus(thunkAPI, file, 'error')
      return thunkAPI.rejectWithValue({
        error: { statusCode: 400, message: errorMessage }
      })
    }
  }
)

export const uploadJobFile = createAsyncThunk(
  'uploadJobFile',
  async (data, thunkAPI) => {
    let { jobId, file } = data
    let errorMessage = null

    try {
      await postUploadJobFile(jobId, file)
    } catch (error) {
      errorMessage = 'Upload job file error! Please check and try again.'

      return thunkAPI.rejectWithValue({
        error: { statusCode: 400, message: errorMessage }
      })
    }
  }
)

export const updateJobClientUploadFile = createAsyncThunk(
  'updateJobClientUploadFile',
  async (data, thunkAPI) => {
    let { jobId, clientUploadFile } = data
    let errorMessage = null

    let payloadData = {
      clientUploadFile: clientUploadFile
    }

    try {
      await patchUpdateJobClientUpload(jobId, payloadData)
    } catch (error) {
      errorMessage = 'Upload job client upload file field error! Please check and try again.'

      return thunkAPI.rejectWithValue({
        error: { statusCode: 400, message: errorMessage }
      })
    }
  }
)

export const job = createSlice({
  name: 'job',
  initialState,
  reducers: {
    toggleUploadFileStatus: (state, { payload }) => {
      if (payload.file?.originalName) {
        state.uploadedFilesStatus = {
          ...state.uploadedFilesStatus,
          [payload.file.originalName]: payload.fileUploadStatus
        }
      }
    },
    resetJobData: state => {
      state.jobDetail = initialState.jobDetail
      state.jobLineList = initialState.jobLineList
      state.jobSecuritiesList = initialState.jobSecuritiesList
      state.jobPromiseToPaysList = initialState.jobPromiseToPaysList
      state.jobPaymentsList = initialState.jobPaymentsList
      state.jobExtraChargesList = initialState.jobExtraChargesList
      state.jobCommentsList = initialState.jobCommentsList
      state.jobFilesList = initialState.jobFilesList
      state.holdJobRequestResult = initialState.holdJobRequestResult
    },
    resetJobCloseResult: state => {
      state.closeJobCommentResult = initialState.closeJobCommentResult
      state.jobCommentsList = initialState.jobCommentsList
    },
    resetHoldJobRequestResult: state => {
      state.holdJobRequestResult = initialState.holdJobRequestResult
      state.jobCommentsList = initialState.jobCommentsList
    },
    resetUnholdJobRequestResult: state => {
      state.unholdJobRequestResult = initialState.unholdJobRequestResult
      state.jobCommentsList = initialState.jobCommentsList
    },
    resetUpgradeJobRequestResult: state => {
      state.upgradeJobRequestResult = initialState.upgradeJobRequestResult
    },
    resetJobCommentList: state => {
      state.jobCommentsList = initialState.jobCommentsList
      state.addJobCommentResult = initialState.addJobCommentResult
    },
    resetJobFileDownlaodError: state => {
      state.jobFileDownloadResultError = initialState.jobFileDownloadResultError
    },
    resetAddNewPaymentResult: state => {
      state.addNewPaymentResult = initialState.addNewPaymentResult
    },
    resetJobPageState: state => {
      state.jobDetail = initialState.jobDetail
    },
    resetFileUploadRequestResult: state => {
      state.fileUploadRequestResult = initialState.fileUploadRequestResult
    },
    resetActionUpdateJobRequestResult: state => {
      state.actionUpdateJobRequestResult =
        initialState.actionUpdateJobRequestResult
    },
    resetCostApproveRequestResult: state => {
      state.costApproveRequestResult = initialState.costApproveRequestResult
    },
    resetNewAddressApproveRequestResult: state => {
      state.newAddressApproveRequestResult =
        initialState.newAddressApproveRequestResult
    },
    resetUploadedFilesStatus: state => {
      state.uploadedFilesStatus = initialState.uploadedFilesStatus
    },
    resetJobFileUploadStatus: state => {
      state.jobFileUploadResult = initialState.jobFileUploadResult
    },
    resetJobUpdateClientUploadFileStatus: state => {
      state.jobUpdateResult = initialState.jobUpdateResult
    }
  },
  extraReducers: builder => {
    //fetch job detail by ID for display on data table
    builder.addCase(fetchJobDetailById.pending, state => {
      state.jobDetail.loading = true
    })
    builder.addCase(fetchJobDetailById.fulfilled, (state, { payload }) => {
      state.jobDetail.loading = false
      state.jobDetail.record = payload
      state.jobDetail.error = null
      state.jobDetail.statusCode = 200
    })
    builder.addCase(fetchJobDetailById.rejected, (state, { payload }) => {
      state.jobDetail.loading = false
      state.jobDetail.statusCode = payload?.error.statusCode
      state.errorMessage = payload?.error.message
    })

    // fetch Job Line List for display on Job Linet data table
    builder.addCase(fetchJobLineListById.pending, state => {
      // state.jobDetailLoading = true
      state.jobLineList.loading = true
    })
    builder.addCase(fetchJobLineListById.fulfilled, (state, { payload }) => {
      // state.jobDetailLoading = false
      state.jobLineList.loading = false
      state.jobLineList.records = updateReduxRecords(
        state.jobLineList.records,
        payload?.data,
        payload?.pagination?.page
      )
      state.jobLineList.page = payload.pagination?.page
      state.jobLineList.size = payload.pagination?.itemCount
      state.jobLineList.total = payload.pagination?.total
      state.jobLineList.totalPages = payload.pagination?.totalPages
      state.jobLineList.error = null
    })
    builder.addCase(fetchJobLineListById.rejected, (state, { payload }) => {
      // state.jobDetailLoading = false
      state.jobLineList.records = []
      state.jobLineList.error = payload?.error.message
    })

    // fetch Job Securities List for display on Job Securities data table
    builder.addCase(fetchJobSecurityListById.pending, state => {
      // state.jobDetailLoading = true
      state.jobSecuritiesList.loading = true
    })
    builder.addCase(
      fetchJobSecurityListById.fulfilled,
      (state, { payload }) => {
        // state.jobDetailLoading = false
        state.jobSecuritiesList.loading = false
        state.jobSecuritiesList.records = updateReduxRecords(
          state.jobSecuritiesList.records,
          payload?.data,
          payload?.pagination?.page
        )
        state.jobSecuritiesList.page = payload.pagination?.page
        state.jobSecuritiesList.size = payload.pagination?.itemCount
        state.jobSecuritiesList.total = payload.pagination?.total
        state.jobSecuritiesList.totalPages = payload.pagination?.totalPages
        state.jobSecuritiesList.error = null
      }
    )
    builder.addCase(fetchJobSecurityListById.rejected, (state, { payload }) => {
      // state.jobDetailLoading = false
      state.jobSecuritiesList.records = []
      state.jobSecuritiesList.error = payload?.error.message
    })

    // fetch Job Promise to Pays List for display on Job Promise to Pays data table
    builder.addCase(fetchPromisetoPaysListById.pending, state => {
      // state.jobDetailLoading = true
      state.jobPromiseToPaysList.loading = true
    })
    builder.addCase(
      fetchPromisetoPaysListById.fulfilled,
      (state, { payload }) => {
        // state.jobDetailLoading = false
        state.jobPromiseToPaysList.loading = false
        state.jobPromiseToPaysList.records = updateReduxRecords(
          state.jobPromiseToPaysList.records,
          payload?.data,
          payload?.pagination?.page
        )
        state.jobPromiseToPaysList.page = payload.pagination?.page
        state.jobPromiseToPaysList.size = payload.pagination?.itemCount
        state.jobPromiseToPaysList.total = payload.pagination?.total
        state.jobPromiseToPaysList.totalPages = payload.pagination?.totalPages
        state.jobPromiseToPaysList.error = null
      }
    )
    builder.addCase(
      fetchPromisetoPaysListById.rejected,
      (state, { payload }) => {
        // state.jobDetailLoading = false
        state.jobPromiseToPaysList.records = []
        state.jobPromiseToPaysList.error = payload?.error.message
      }
    )

    // fetch Job Payments List for display on Job Payments data table
    builder.addCase(fetchPaymentsListById.pending, state => {
      // state.jobDetailLoading = true
      state.jobPaymentsList.loading = true
    })
    builder.addCase(fetchPaymentsListById.fulfilled, (state, { payload }) => {
      // state.jobDetailLoading = false
      state.jobPaymentsList.loading = false
      state.jobPaymentsList.records = updateReduxRecords(
        state.jobPaymentsList.records,
        payload.data.map(item => {
          return {
            ...item,
            paymentReference:
              item.paymentReference === null ? 'Pending' : item.paymentReference
          }
        }),
        payload.pagination?.page
      )
      state.jobPaymentsList.page = payload.pagination?.page
      state.jobPaymentsList.size = payload.pagination?.itemCount
      state.jobPaymentsList.total = payload.pagination?.total
      state.jobPaymentsList.totalPages = payload.pagination?.totalPages
      state.jobPaymentsList.error = null
    })
    builder.addCase(fetchPaymentsListById.rejected, (state, { payload }) => {
      // state.jobDetailLoading = false
      state.jobPaymentsList.records = []
      state.jobPaymentsList.error = payload?.error.message
    })

    // fetch Job Extra Charges List for display on Job Extra Charges data table
    builder.addCase(fetchExtraChargesListById.pending, state => {
      // state.jobDetailLoading = true
      state.jobExtraChargesList.loading = true
    })
    builder.addCase(
      fetchExtraChargesListById.fulfilled,
      (state, { payload }) => {
        // state.jobDetailLoading = false
        state.jobExtraChargesList.loading = false
        state.jobExtraChargesList.records = updateReduxRecords(
          state.jobExtraChargesList.records,
          payload.data,
          payload.pagination?.page
        )
        state.jobExtraChargesList.page = payload.pagination?.page
        state.jobExtraChargesList.size = payload.pagination?.itemCount
        state.jobExtraChargesList.total = payload.pagination?.total
        state.jobExtraChargesList.totalPages = payload.pagination?.totalPages
        state.jobExtraChargesList.error = null
      }
    )
    builder.addCase(
      fetchExtraChargesListById.rejected,
      (state, { payload }) => {
        // state.jobDetailLoading = false
        state.jobExtraChargesList.records = []
        state.jobExtraChargesList.error = payload?.error.message
      }
    )

    // fetch Job Extra Charges List for display on Job Extra Charges data table
    builder.addCase(fetchJobCommentsListById.pending, state => {
      state.jobDetailLoading = true
      state.jobCommentsList.loading = true
    })
    builder.addCase(
      fetchJobCommentsListById.fulfilled,
      (state, { payload }) => {
        // console.log('comment payload', payload)
        state.jobDetailLoading = false
        state.jobCommentsList.loading = false
        state.jobCommentsList.records = updateReduxRecords(
          state.jobCommentsList.records,
          payload.data,
          payload.pagination?.page
        )
        state.jobCommentsList.page = payload.pagination?.page
        state.jobCommentsList.size = payload.pagination?.itemCount
        state.jobCommentsList.total = payload.pagination?.total
        state.jobCommentsList.totalPages = payload.pagination?.totalPages
        state.jobCommentsList.error = null
      }
    )
    builder.addCase(fetchJobCommentsListById.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.jobCommentsList.records = []
      state.jobCommentsList.error = payload?.error.message
    })

    // fetch Job Files List for display on Files data table
    builder.addCase(fetchJobFileListById.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(fetchJobFileListById.fulfilled, (state, { payload }) => {
      state.jobDetailLoading = false
      state.jobFilesList.records = payload
      state.jobFilesList.error = null
      state.jobFilesList.statusCode = 200
    })
    builder.addCase(fetchJobFileListById.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.jobFilesList.records = []
      state.jobFilesList.error = payload?.error.message
      state.jobFilesList.statusCode = payload?.error.statusCode
    })

    // Update Close Job Comment
    builder.addCase(updateCloseJobStatus.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(updateCloseJobStatus.fulfilled, (state, { payload }) => {
      state.jobDetailLoading = false
      state.closeJobCommentResult = payload.status
    })
    builder.addCase(updateCloseJobStatus.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.closeJobCommentResult = payload?.error.message
    })

    // Update Close Job Comment
    builder.addCase(requestHoldJob.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(requestHoldJob.fulfilled, state => {
      state.jobDetailLoading = false
      state.holdJobRequestResult.statusCode = 200
    })
    builder.addCase(requestHoldJob.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.holdJobRequestResult.statusCode = payload?.error?.statusCode
      state.holdJobRequestResult.error = payload?.error.message
    })

    // Update Unhold Job Comment
    builder.addCase(requestUnholdJob.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(requestUnholdJob.fulfilled, state => {
      state.jobDetailLoading = false
      state.unholdJobRequestResult.statusCode = 200
    })
    builder.addCase(requestUnholdJob.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.unholdJobRequestResult.statusCode = payload?.error?.statusCode
      state.unholdJobRequestResult.error = payload?.error.message
    })

    // Update Job Outcome Action Upgrade / Action Update
    builder.addCase(requestUpgradeJob.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(requestUpgradeJob.fulfilled, state => {
      state.jobDetailLoading = false
      state.upgradeJobRequestResult.statusCode = 200
    })
    builder.addCase(requestUpgradeJob.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.upgradeJobRequestResult.statusCode = payload?.error?.statusCode
      state.upgradeJobRequestResult.error = payload?.error.message
    })

    // Update Job Outcome Action Upgrade / Action Update
    builder.addCase(requestActionUpdateJob.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(requestActionUpdateJob.fulfilled, state => {
      state.jobDetailLoading = false
      state.actionUpdateJobRequestResult.statusCode = 200
    })
    builder.addCase(requestActionUpdateJob.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.actionUpdateJobRequestResult.statusCode = payload?.error?.statusCode
      state.actionUpdateJobRequestResult.error = payload?.error.message
    })

    // Update Job Outcome Action Upgrade / Action Update
    builder.addCase(costApproveAction.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(costApproveAction.fulfilled, state => {
      state.jobDetailLoading = false
      state.costApproveRequestResult.statusCode = 200
    })
    builder.addCase(costApproveAction.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.costApproveRequestResult.statusCode = payload?.error?.statusCode
      state.costApproveRequestResult.error = payload?.error.message
    })

    // Create new job approve state
    builder.addCase(newAddressApproveAction.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(newAddressApproveAction.fulfilled, state => {
      state.jobDetailLoading = false
      state.newAddressApproveRequestResult.statusCode = 200
    })
    builder.addCase(newAddressApproveAction.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.newAddressApproveRequestResult.statusCode =
        payload?.error?.statusCode
      state.newAddressApproveRequestResult.error = payload?.error.message
    })

    // Upload File to Job
    builder.addCase(uploadFileToJob.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(uploadFileToJob.fulfilled, state => {
      state.jobDetailLoading = false
      state.fileUploadRequestResult.statusCode = 200
    })
    builder.addCase(uploadFileToJob.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.fileUploadRequestResult.statusCode = payload?.error?.statusCode
      state.fileUploadRequestResult.error = payload?.error.message
    })

    // Update File to Job
    builder.addCase(uploadJobFile.pending, state => {
      state.jobDetailLoading = true
      state.jobFileUploadResult.loading = true
    })
    builder.addCase(uploadJobFile.fulfilled, state => {
      // state.jobDetailLoading = false
      state.jobFileUploadResult.loading = false
      state.jobFileUploadResult.statusCode = 200
    })
    builder.addCase(uploadJobFile.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.jobFileUploadResult.loading = false
      state.jobFileUploadResult.statusCode = payload?.error?.statusCode
      state.jobFileUploadResult.error = payload?.error.message
    })

    //Update Job Client Upload File
    builder.addCase(updateJobClientUploadFile.pending, state => {
      state.jobDetailLoading = true
      state.jobUpdateResult.loading = true
    })
    builder.addCase(updateJobClientUploadFile.fulfilled, state => {
      // state.jobDetailLoading = false
      state.jobUpdateResult.loading = false
      state.jobUpdateResult.statusCode = 200
    })
    builder.addCase(updateJobClientUploadFile.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.jobUpdateResult.loading = false
      state.jobUpdateResult.statusCode = payload?.error?.statusCode
      state.jobUpdateResult.error = payload?.error.message
    })

    //Add Job Comment
    builder.addCase(addJobComment.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(addJobComment.fulfilled, (state, { payload }) => {
      state.jobDetailLoading = false
      state.addJobCommentResult = payload.status
    })
    builder.addCase(addJobComment.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.addJobCommentResult = payload?.error.message
    })

    //Download file by ID
    builder.addCase(downloadFileById.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(downloadFileById.fulfilled, state => {
      state.jobDetailLoading = false
    })
    builder.addCase(downloadFileById.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.jobFileDownloadResultError = payload?.error
    })

    //Add new Payment By data
    builder.addCase(addNewPaymentByData.pending, state => {
      state.jobDetailLoading = true
    })
    builder.addCase(addNewPaymentByData.fulfilled, state => {
      state.jobDetailLoading = false
      state.addNewPaymentResult.statusCode = 200
    })
    builder.addCase(addNewPaymentByData.rejected, (state, { payload }) => {
      state.jobDetailLoading = false
      state.addNewPaymentResult.statusCode = payload?.error.statusCode
      state.addNewPaymentResult.error = payload?.error.message
    })
  }
})

export default job.reducer

export const {
  resetJobData,
  resetJobCloseResult,
  resetJobCommentList,
  resetJobFileDownlaodError,
  resetJobPageState,
  resetAddNewPaymentResult,
  resetHoldJobRequestResult,
  resetUnholdJobRequestResult,
  resetUpgradeJobRequestResult,
  toggleUploadFileStatus,
  resetFileUploadRequestResult,
  resetActionUpdateJobRequestResult,
  resetUploadedFilesStatus,
  resetCostApproveRequestResult,
  resetNewAddressApproveRequestResult,
  resetJobFileUploadStatus,
  resetJobUpdateClientUploadFileStatus
} = job.actions

export const getJobPageLoading = createSelector(
  state => state.job.jobDetailLoading,
  jobDetail => jobDetail
)

export const getJobDetailData = createSelector(
  state => state.job.jobDetail,
  jobDetail => jobDetail
)

export const getJobLineListData = createSelector(
  state => state.job.jobLineList,
  jobLineList => jobLineList
)

export const getJobSecurityListData = createSelector(
  state => state.job.jobSecuritiesList,
  jobSecuritiesList => jobSecuritiesList
)

export const getJobPromiseToPaysListData = createSelector(
  state => state.job.jobPromiseToPaysList,
  jobPromiseToPaysList => jobPromiseToPaysList
)

export const getPaymentsListData = createSelector(
  state => state.job.jobPaymentsList,
  jobPaymentsList => jobPaymentsList
)

export const getExtraChargesListData = createSelector(
  state => state.job.jobExtraChargesList,
  jobExtraChargesList => jobExtraChargesList
)

export const getCommentsListData = createSelector(
  state => state.job.jobCommentsList,
  jobCommentsList => jobCommentsList
)

export const getCloseJobCommentResult = createSelector(
  state => state.job.closeJobCommentResult,
  closeJobCommentResult => closeJobCommentResult
)

export const getAddJobCommentResult = createSelector(
  state => state.job.addJobCommentResult,
  addJobCommentResult => addJobCommentResult
)

export const getHoldJobRequestResult = createSelector(
  state => state.job.holdJobRequestResult,
  holdJobRequestResult => holdJobRequestResult
)

export const getUnholdJobRequestResult = createSelector(
  state => state.job.unholdJobRequestResult,
  unholdJobRequestResult => unholdJobRequestResult
)

export const getUpgradeJobRequestResult = createSelector(
  state => state.job.upgradeJobRequestResult,
  upgradeJobRequestResult => upgradeJobRequestResult
)

export const getJobFilesListData = createSelector(
  state => state.job.jobFilesList,
  jobFilesList => jobFilesList
)

export const getJobFileDownloadResultError = createSelector(
  state => state.job.jobFileDownloadResultError,
  jobFileDownloadResultError => jobFileDownloadResultError
)

export const getAddNewPaymentResult = createSelector(
  state => state.job.addNewPaymentResult,
  addNewPaymentResult => addNewPaymentResult
)

export const getActionUpdateJobRequestResult = createSelector(
  state => state.job.actionUpdateJobRequestResult,
  actionUpdateJobRequestResult => actionUpdateJobRequestResult
)

export const getCostApproveRequestResult = createSelector(
  state => state.job.costApproveRequestResult,
  costApproveRequestResult => costApproveRequestResult
)

export const getNewAddressApproveRequestResult = createSelector(
  state => state.job.newAddressApproveRequestResult,
  newAddressApproveRequestResult => newAddressApproveRequestResult
)

export const getFileUploadRequestResult = createSelector(
  state => state.job.fileUploadRequestResult,
  fileUploadRequestResult => fileUploadRequestResult
)

export const getUploadedFilesStatus = createSelector(
  state => state.submitJob.uploadedFilesStatus,
  uploadedFilesStatus => uploadedFilesStatus
)

export const getJobFileUploadResult = createSelector(
  state => state.job.jobFileUploadResult,
  jobFileUploadResult => jobFileUploadResult
)

export const getJobUpdateResult = createSelector(
  state => state.job.jobUpdateResult,
  jobUpdateResult => jobUpdateResult
)
