import {
  performGetBlobRequest,
  performPostRequest
} from '../helpers/service'


export const downloadFileByFileId = async fileId => {
  const response = await getFileDataByFileId(fileId)
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'downloaded-file';

  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const matches = /filename="([^"]+)"/.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1];
    }
  }

  const { data } = response
  if (data != null) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    var link = document.createElement('a')
    link.href = url
    link.download = fileName
    link.click()
    window.URL.revokeObjectURL(url);
  }
  return fileName;
}
export const getFileDataByFileId = async fileId => {
  const response = await performGetBlobRequest('/documents/' + fileId)
  return response
}


export const postUploadJobFile = async (jobId, data) => {
  const response = await performPostRequest('/documents/upload-file',
      createFormData(jobId, data),
      {
        'Content-Type': 'multipart/form-data'
      })
  return response
}
const createFormData = (jobId, data) => {

  const form = new FormData();
  form.append('fileName', data.fileName);
  form.append('sfEntityId', jobId);
  form.append('file',data.file);
  return form;
}
