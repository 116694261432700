import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { WelcomeHeader } from '../../WelcomeHeader'

const SubmitJobFileHeader = ({ quoteNo, title, subTitle }) => {
  return (
    <div className='slds-text-align_center slds-p-horizontal_xx-small slds-m-bottom_small'>
      <WelcomeHeader headingText={title ?? ''} contentText={subTitle ?? ''} />
      <p className='slds-text-heading_medium slds-p-around_large'>
        {quoteNo
          ? `Upload Job File & Documentation for Quote ${quoteNo}`
          : 'Upload Job File & Documentation for a New Quote.'}
      </p>
    </div>
  )
}

SubmitJobFileHeader.propTypes = {
  quoteNo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string
}

export default memo(SubmitJobFileHeader)
