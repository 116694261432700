import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { DataTable } from '@salesforce/design-system-react'
import styled from 'styled-components'

import { TABLE_HEIGHT_DEFAULT } from '../../../constants'

const TableContainer = styled.div`
  overflow-x: auto;
  height: ${props => props.tableHeight};

  .text-wrap {
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  @media screen and (max-width: 768px) {
    .no-shrink-column {
      min-width: ${props => props.columnsCount * 150}px;
    }
  }
`

TableContainer.propTypes = {
  columnsCount: PropTypes.number
}

const LightningDataTable = ({
  columns,
  items,
  hasMore,
  onLoadMore,
  totalItems = 0,
  tableHeight = TABLE_HEIGHT_DEFAULT,
  isFiltering = false,
  ...tableProps
}) => {
  // if (totalItems === 0) return null
  const itemCount = items?.length
  if (itemCount === 0) return null
  return (
    <TableContainer columnsCount={columns.length} tableHeight={tableHeight}>
      <DataTable
        items={
          items.map((item, index) => ({ ...item, id: `${item.id ?? index}` })) ?? []
        }
        {...tableProps}
        fixedHeader
        fixedLayout
        hasMore={hasMore && itemCount !== 0 && itemCount < totalItems && !isFiltering}
        onLoadMore={onLoadMore}
        onFixedHeaderResize={(event, { headerRefs, scrollerRef }) => {
          setTimeout(() => {
            const canUseDOM = !!window
            var documentScrollLeft = 0

            if (canUseDOM) {
              documentScrollLeft = document.documentElement.scrollLeft
            }

            headerRefs.forEach(function (column) {
              if (column && canUseDOM) {
                var columnLeft =
                  column.getBoundingClientRect().left + documentScrollLeft
                var wrapperLeft = 0

                if (scrollerRef) {
                  wrapperLeft =
                    scrollerRef.getBoundingClientRect().left +
                    documentScrollLeft
                }

                var cellFixed = column.querySelector('.slds-cell-fixed')

                if (cellFixed) {
                  cellFixed.style.left = ''.concat(
                    columnLeft - wrapperLeft,
                    'px'
                  )
                  cellFixed.style.width = ''.concat(column.offsetWidth, 'px')
                }
              }
            })
          }, 1)
        }}
      >
        {columns}
      </DataTable>
    </TableContainer>
  )
}

LightningDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  totalItems: PropTypes.number,
  tableHeight: PropTypes.string,
  isFiltering: PropTypes.bool,
}

export default memo(LightningDataTable)
