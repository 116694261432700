import React from 'react'
import PropTypes from 'prop-types'

import { isFunction, isNumber } from 'lodash'
import { getNumberText } from '../../../../helpers/number'
import { getDateFormat } from '../../../../helpers/date'

import { Link } from 'react-router-dom'
import { DataTableCell, Button, Badge } from '@salesforce/design-system-react'
import StandardCheckbox from '../../StandardCheckbox/StandardCheckbox'

const CustomDataTableCell = ({
  children,
  dataType,
  item,

  // Link props
  baseUrl,
  onClick,
  displayAsIcon,

  // Number / Currency Props
  thousandSeparator = true,
  decimalPoint = 0,
  range1Key,
  range2Key,

  // Datetime Props
  withTime = true,
  applyTimezoneOnDate = false,

  // File Props
  srcKey,
  truncate,

  ...props
}) => {
  const targetItemId = item.id

  // TODO: continue implementing this
  const downloadFile = fileSrc => event => {
    event.preventDefault()
    window.open(fileSrc)
  }

  let range1Data = null
  let range2Data = null

  if (range1Key && range2Key) {
    range1Data = !isNaN(item[range1Key]) ? getNumberText(item[range1Key], decimalPoint, thousandSeparator) : null
    range2Data = !isNaN(item[range2Key]) ? getNumberText(item[range2Key], decimalPoint, thousandSeparator) : null
  }

  const rowClicked = e => {
    e.preventDefault()

    if (isFunction(onClick)) {
      onClick(item)
    }
  }

  let cellDisplay = null
  switch (dataType) {
    case 'link':
      cellDisplay = children

      if (baseUrl) {
        cellDisplay = (
          <Link
            to={onClick ? '' : `${baseUrl}/${targetItemId}`}
            onClick={onClick ? rowClicked : null}
          >
            {children}
          </Link>
        )
      } else if (displayAsIcon && children) {
        cellDisplay = (
          <Button
            iconSize='large'
            iconCategory='doctype'
            iconName='pdf'
            iconVariant='bare'
            onClick={onClick ? rowClicked : null}
            variant='icon'
          />
        )
      }
      break

    case 'number':
      cellDisplay = isNumber(children)
        ? getNumberText(children, decimalPoint, thousandSeparator)
        : children
      break

    case 'currency':
      if (range1Data && range2Data) {
        cellDisplay = `$${range1Data} - $${range2Data}`
      } else if (range1Data && !range2Data) {
        cellDisplay = `$${range1Data}`
      } else if (!range1Data && range2Data) {
        cellDisplay = `$${range2Data}`
      } else {
        cellDisplay = `$${getNumberText(children, decimalPoint, thousandSeparator)}`
      }
      break

    case 'datetime':
      cellDisplay = getDateFormat(children, withTime, applyTimezoneOnDate)
      break

    case 'checkbox':
      cellDisplay = (
        <StandardCheckbox label='' value={children} readOnly={true} />
      )
      break

    case 'activeStatusBadge':
      cellDisplay = (
        <Badge
          id={targetItemId}
          content={children}
          color={children === 'Active' ? 'success' : 'warning'}
        />
      )
      break

    case 'file':
      cellDisplay = (
        <Button
          label={children}
          variant='base'
          onClick={downloadFile(item[srcKey])}
        />
      )
      break

    default:
      cellDisplay = children
      break
  }

  return (
    <DataTableCell title={`${children}`} {...props} fixedLayout={truncate} className={truncate ? '' : 'text-wrap'}>
      {cellDisplay}
    </DataTableCell>
  )
}
CustomDataTableCell.displayName = DataTableCell.displayName

CustomDataTableCell.propTypes = {
  children: PropTypes.any,
  item: PropTypes.any,
  onClick: PropTypes.func,
  dataType: PropTypes.string,
  baseUrl: PropTypes.string,
  decimalPoint: PropTypes.number,
  thousandSeparator: PropTypes.bool,
  range1Key: PropTypes.string,
  range2Key: PropTypes.string,
  withTime: PropTypes.bool,
  applyTimezoneOnDate: PropTypes.bool,
  srcKey: PropTypes.string,
  displayAsIcon: PropTypes.bool,
  truncate: PropTypes.bool
}

export default CustomDataTableCell
