import React from 'react'
import PropTypes from 'prop-types'

const FAQTitle = ({ title }) => {
  return (
    <div className='faq-title slds-text-heading_medium'>
      <div>{title}</div>
    </div>
  )
}

FAQTitle.propTypes = {
  title: PropTypes.string
}

export default FAQTitle
