import {
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_TEXT
} from '../../constants'

export const declineReasonForm = {
  declinedReason: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Reason for declining?',
      placeholder: 'Decline reason'
    },
    value: ''
  }
}

export const customJobForm = {
  description: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Need something else? Enter details below & click Request.',
      placeholder: 'Please tell us more about your job request...',
      required: true
    },
    value: ''
  }
}

export const getQuoteForm = (jobType = [], securityType = []) => {
  return {
    jobType: {
      inputType: INPUT_TYPE_DROPDOWN,
      inputProps: {
        options: jobType,
        required: true
      },
      value: []
    },
    securityType: {
      inputType: INPUT_TYPE_DROPDOWN,
      inputProps: {
        options: securityType,
        required: true
      },
      value: []
    },
    clientRef: {
      inputType: INPUT_TYPE_TEXT,
      inputProps: {
        type: INPUT_TYPE_TEXT,
        label: 'Client Ref #',
        required: true
      },
      value: ''
    }
  }
}

export const getExistingQuoteForm = () => {
  return {
    jobType: {
      inputType: INPUT_TYPE_TEXT,
      inputProps: {
        type: INPUT_TYPE_TEXT,
        label: 'Job Type',
        readOnly: true,
        disabled: true
      },
      value: ''
    },
    securityType: {
      inputType: INPUT_TYPE_TEXT,
      inputProps: {
        type: INPUT_TYPE_TEXT,
        label: 'Security Type',
        readOnly: true,
        disabled: true
      },
      value: ''
    },
    clientRef: {
      inputType: INPUT_TYPE_TEXT,
      inputProps: {
        type: INPUT_TYPE_TEXT,
        label: 'Client Ref #',
        readOnly: true,
        disabled: true
      },
      value: ''
    },
    address: {
      inputType: INPUT_TYPE_TEXTAREA,
      inputProps: {
        placeholder: 'Address',
        disabled: true
      },
      value: ''
    }
  }
}
