import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { PAGE_SIZE } from '../../constants'

import { TabsPanel } from '@salesforce/design-system-react'
import {
  AdminInfoFormTabWrapper,
  AdminPageHeader,
  ScrollableTabs,
  CompanyForm,
  ClientSpecificInfoForm,
  RelationshipContactForm,
  SecondRelationshipContactForm,
  OperationalContactForm,
  AdminDataTable,
  StandardFormModal
} from '../../components'
import { applyDataToForm, applyValidation, clearFormTouchedStatus } from '../../helpers/form'
import { generateTableColumns } from '../../helpers/dataTable'
import {
  clientSpecificInfoForm,
  companyDetailForm,
  companyDetailFormReadOnly,
  operationalContactForm,
  relationshipContactForm,
  secondRelationshipContactForm
} from './adminInformationForms'
import {
  getBankAccountListColumns,
  getAdminUserListColumns,
  pricingListColumns
} from './adminInformationTableColumns'
import { AddBankAccountModal, EditBankAccountModal } from '../BankAccount'
import { AddNewUserModal } from '../AddNewUserModal'

import { UserRecordModal } from '../../components'

import useAlert from '../../hooks/useAlert'

import { createSuccessAlert, createErrorAlert } from '../../helpers/alert'

import { useDispatch, useSelector } from 'react-redux'

import {
  getBankAccountListData,
  fetchBankAccountList,
  resetBankAccountResult,
  // fetchBankAccountById,
  getBankAccountDetail,
  getBankAccountLoading,
  getEditBankAccountResult,
  updateBankAccountByData
} from '../../redux/slices/bankAccount'
import {
  fetchUserData,
  getUserDetail,
  saveEditUser,
  getUserDetailLoading,
  getSaveEditUserResult,
  resetAdminUserResult,
  fetchUserList,
  getUserListData
} from '../../redux/slices/user'

import { Spinner } from '@salesforce/design-system-react'
import {
  fetchAdminData,
  fetchPricing,
  getAdminData,
  resetAdminPageState,
  updateAdminInfo,
  getUpdateInfoData,
  getPricingData
} from '../../redux/slices/admin'
import { setAppTitle } from '../../helpers/appState'
import { getFieldValidator, getPortalName } from '../../redux/slices/global'

// const pricingList = generatePricings(10)
const pricingTableColumns = generateTableColumns(pricingListColumns)

const AdminPageContent = () => {
  const dispatch = useDispatch()
  const fireAlert = useAlert()
  const navigate = useNavigate()

  const portalName = useSelector(getPortalName)
  const adminData = useSelector(getAdminData)
  const upInfoResult = useSelector(getUpdateInfoData)

  const userDataDetail = useSelector(getUserDetail)
  const loading = useSelector(getUserDetailLoading)
  const editUserResult = useSelector(getSaveEditUserResult)

  const bankAccountList = useSelector(getBankAccountListData)
  const userList = useSelector(getUserListData)

  const bankAccountDetail = useSelector(getBankAccountDetail)
  const bankAccountLoading = useSelector(getBankAccountLoading)
  const editBankAccountResult = useSelector(getEditBankAccountResult)

  const validator = useSelector(getFieldValidator)

  const pricing = useSelector(getPricingData)

  const [adminDetail, setAdminDetail] = useState({})
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [companyModalOpen, setCompanyModalOpen] = useState(false)
  const [clientInfoModalOpen, setClientInfoModalOpen] = useState(false)
  const [relateContactModalOpen, setRelateContactModalOpen] = useState(false)

  const [secondRelateModalContactOpen, setSecondRelateContactModalOpen] =
    useState(false)

  const [operationalContactModalOpen, setOperationalContactModalOpen] =
    useState(false)

  const [addAddBankAccountModalOpen, setAddBankAccountModalOpen] =
    useState(false)

  const [addAddNewUserModalOpen, setAddNewUserModalOpen] = useState(false)

  const [companyInfoFormData, setCompanyInfoFormData] =
    useState(companyDetailForm)

  const [companyInfoFormDataReadOnly, setCompanyInfoFormDataReadOnly] =
    useState(companyDetailFormReadOnly)

  const [clientSpecificInfoFormData, setClientSpecificInfoFormData] = useState(
    clientSpecificInfoForm
  )

  const [relationshipContactFormData, setRelationshipContactFormData] =
    useState(relationshipContactForm)

  const [
    secondRelationshipContactFormData,
    setSecondRelationshipContactFormData
  ] = useState(secondRelationshipContactForm)

  const [operationalContactFormData, setOperationalContactFormData] = useState(
    operationalContactForm
  )

  const [editUserRecordModalOpen, setEditUserRecordModalOpen] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState('')

  const onLoadMoreUserHandler = useCallback(() => {
    if (!userList.loading && userList.page < userList.totalPages) {
      dispatch(fetchUserList({ page: userList.page + 1, size: PAGE_SIZE }))
    }
  }, [userList.loading, userList.page, userList.totalPage])

  const onLoadMoreBankAccountHandler = useCallback(() => {
    if (!bankAccountList.loading) {
      dispatch(
        fetchBankAccountList({
          page: bankAccountList.page + 1,
          size: PAGE_SIZE
        })
      )
    }
  }, [bankAccountList.loading, bankAccountList.page])

  const onLoadMorePricingHandler = useCallback(() => {
    if (!pricing.loading) {
      dispatch(fetchPricing({ page: pricing.page + 1, size: PAGE_SIZE }))
    }
  }, [pricing.loading, pricing.page])

  const onTabSelectedHandler = useCallback(index => {
    setCurrentTabIndex(index)
  }, [])

  const onCompanyFormModalOpenedHandler = useCallback(() => {
    setCompanyModalOpen(true)
  }, [])

  const onCompanyFormModalClosedHandler = useCallback(() => {
    setCompanyModalOpen(false)
    setCompanyInfoFormData(prev => clearFormTouchedStatus(prev))
  }, [])

  const onCompanyFormModalSavedHandler = useCallback(
    data => {
      const patchData = {
        ...data,
        reportingThreshold: data.reportingThreshold?.[0]?.id,
        contactTimeEnd: adminDetail?.record?.contactTimeEnd,
        contactTimeStart: adminDetail?.record?.contactTimeStart
      }
      dispatch(updateAdminInfo(patchData))
      setCompanyModalOpen(false)
      setCompanyInfoFormData(prev => clearFormTouchedStatus(prev))
    },
    [adminDetail]
  )

  const onClientInfoFormModalOpenedHandler = useCallback(() => {
    setClientInfoModalOpen(true)
  }, [])

  const onClientInfoFormModalClosedHandler = useCallback(() => {
    setClientInfoModalOpen(false)
    setClientSpecificInfoFormData(prev => clearFormTouchedStatus(prev))
  }, [])

  const onClientInfoFormModalSavedHandler = useCallback(
    data => {
      dispatch(updateAdminInfo(data))
      setClientInfoModalOpen(false)
      setClientSpecificInfoFormData(prev => clearFormTouchedStatus(prev))
    },
    [adminDetail]
  )

  const onRelateContactFormModalOpenedHandler = useCallback(() => {
    setRelateContactModalOpen(true)
  }, [])

  const onRelateContactFormModalClosedHandler = useCallback(() => {
    setRelateContactModalOpen(false)
    setRelationshipContactFormData(prev => clearFormTouchedStatus(prev))
  }, [])

  const onRelateContactFormModalSavedHandler = useCallback(
    data => {
      dispatch(updateAdminInfo(data))
      setRelateContactModalOpen(false)
      setRelationshipContactFormData(prev => clearFormTouchedStatus(prev))
    },
    [adminDetail]
  )
  companyInfoFormData
clientSpecificInfoFormData
relationshipContactFormData
secondRelationshipContactFormData
operationalContactFormData

  const onSecondRelateContactFormModalOpenedHandler = useCallback(() => {
    setSecondRelateContactModalOpen(true)
  }, [])

  const onSecondRelateContactFormModalClosedHandler = useCallback(() => {
    setSecondRelateContactModalOpen(false)
    setSecondRelationshipContactFormData(prev => clearFormTouchedStatus(prev))
  }, [])

  const onSecondRelateContactFormModalSavedHandler = useCallback(
    data => {
      dispatch(updateAdminInfo(data))
      setSecondRelateContactModalOpen(false)
      setSecondRelationshipContactFormData(prev => clearFormTouchedStatus(prev))
    },
    [adminDetail]
  )

  const onOperationalContactFormModalOpenedHandler = useCallback(() => {
    setOperationalContactModalOpen(true)
  }, [])

  const onOperationalContactFormModalClosedHandler = useCallback(() => {
    setOperationalContactModalOpen(false)
    setOperationalContactFormData(prev => clearFormTouchedStatus(prev))
  }, [])

  const onOperationalContactFormModalSavedHandler = useCallback(data => {
    dispatch(updateAdminInfo(data))
    setOperationalContactModalOpen(false)
    setOperationalContactFormData(prev => clearFormTouchedStatus(prev))
  }, [])

  const onAddBankAccountClicked = useCallback(() => {
    setAddBankAccountModalOpen(true)
  }, [])

  const onAddBankAccountClosed = useCallback(() => {
    setAddBankAccountModalOpen(false)
  }, [])

  const onSaveBankAccount = useCallback(() => {
    setAddBankAccountModalOpen(false)
  }, [])

  const onAddNewUserClicked = useCallback(() => {
    setAddNewUserModalOpen(true)
  }, [])

  const onAddNewUserClosed = useCallback(() => {
    setAddNewUserModalOpen(false)
  }, [])

  const onAddNewUserSaveAndSendPassword = useCallback(() => {
    setAddNewUserModalOpen(false)
  }, [])

  const onEditUserRecordClicked = () => setEditUserRecordModalOpen(true)

  const onEditUserRecordSubmitted = data => {
    setEditUserRecordModalOpen(false)
    const newData = { data, userId: selectedUserId }
    dispatch(saveEditUser(newData))
  }

  const onEditUserRecordClosed = useCallback(() => {
    setEditUserRecordModalOpen(false)
  }, [])

  const selectedUserHandler = rowData => {
    setSelectedUserId(rowData.id)
    dispatch(fetchUserData(rowData.id))
    onEditUserRecordClicked()
  }

  const adminUserTableColumns = generateTableColumns(
    getAdminUserListColumns(selectedUserHandler)
  )

  const [editBankAccountModalOpen, setEditBankAccountModalOpen] =
    useState(false)

  const closedEditBankAccountModalHandler = () => {
    setEditBankAccountModalOpen(false)
  }

  const onEditBankAccountSubmitted = useCallback(data => {
    dispatch(updateBankAccountByData(data))
  }, [])

  const bankAccountTableColumns = generateTableColumns(
    getBankAccountListColumns()
  )

  // for trigger response result from patch request users
  useEffect(() => {
    if (editUserResult !== '') {
      if (editUserResult === 200) {
        fireAlert(createSuccessAlert('User has been updated', 3000))
        dispatch(resetAdminUserResult())
      } else {
        fireAlert(createErrorAlert(editUserResult, 3000))
        dispatch(resetAdminUserResult())
      }
      dispatch(fetchUserList({ page: 1, size: PAGE_SIZE }))
    }
  }, [editUserResult])

  useEffect(() => {
    setAppTitle(portalName, 'Admin')
    dispatch(resetAdminUserResult())
    dispatch(resetBankAccountResult())

    dispatch(fetchAdminData())

    return () => {
      dispatch(resetAdminPageState())
    }
  }, [])

  useEffect(() => {
    if (adminData.statusCode === 200 && adminData.record !== null) {
      dispatch(fetchUserList({ page: 1, size: PAGE_SIZE }))
      dispatch(fetchBankAccountList({ page: 1, size: PAGE_SIZE }))
      dispatch(fetchPricing({ page: 1 }))
    } else if (adminData.statusCode === 404) {
      navigate('/notFound')
    }
  }, [adminData.statusCode, adminData.record])

  useEffect(() => {
    if (adminData.record) {
      setCompanyInfoFormData(prev => applyDataToForm(prev, adminData.record))
      setCompanyInfoFormDataReadOnly(prev =>
        applyDataToForm(prev, adminData.record)
      )
      setClientSpecificInfoFormData(prev =>
        applyDataToForm(prev, adminData.record)
      )
      setRelationshipContactFormData(prev =>
        applyDataToForm(prev, adminData.record)
      )
      setSecondRelationshipContactFormData(prev =>
        applyDataToForm(prev, adminData.record)
      )
      setOperationalContactFormData(prev =>
        applyDataToForm(prev, adminData.record)
      )
      setAdminDetail(adminData.record)
    }
  }, [adminData.record])

  useEffect(() => {
    if (editBankAccountResult !== '') {
      if (editBankAccountResult === 200) {
        fireAlert(createSuccessAlert('Bank Account has been updated', 3000))
      } else {
        fireAlert(createErrorAlert(editBankAccountResult, 3000))
      }
      dispatch(resetBankAccountResult())
      dispatch(fetchBankAccountList({ page: 1, size: PAGE_SIZE }))
    }
  }, [editBankAccountResult])

  useEffect(() => {
    if (upInfoResult !== '') {
      if (upInfoResult === 200) {
        fireAlert(createSuccessAlert('Info has been updated', 3000))
      } else {
        fireAlert(createErrorAlert(upInfoResult, 3000))
      }
      dispatch(resetAdminPageState())
      dispatch(fetchAdminData())
    }
  }, [upInfoResult])

  useEffect(() => {
    setCompanyInfoFormData(prev => applyValidation(prev, validator?.account))
    setClientSpecificInfoFormData(prev => applyValidation(prev, validator?.account))
    setRelationshipContactFormData(prev => applyValidation(prev, validator?.account))
    setSecondRelationshipContactFormData(prev => applyValidation(prev, validator?.account))
    setOperationalContactFormData(prev => applyValidation(prev, validator?.account))
  }, [validator])

  return (
    <>
      <AddBankAccountModal
        isOpen={addAddBankAccountModalOpen}
        modalTitle={'Add Bank Account'}
        onClose={onAddBankAccountClosed}
        onSubmitted={onSaveBankAccount}
      />
      <AddNewUserModal
        isOpen={addAddNewUserModalOpen}
        modalTitle={''}
        onClose={onAddNewUserClosed}
        onSubmitted={onAddNewUserSaveAndSendPassword}
      />
      <StandardFormModal
        modalTitle={'Update Company Information'}
        modalOpen={companyModalOpen}
        onClose={onCompanyFormModalClosedHandler}
        onSaveButtonClicked={onCompanyFormModalSavedHandler}
        formData={companyInfoFormData}
        hasDatePicker={false}
        additionalText={
          <>
            To update other company details, please contact us at{' '}
            <a href='mailto:suppport@accessmercantile.com.au'>
              suppport@accessmercantile.com.au
            </a>
          </>
        }
      />

      <StandardFormModal
        modalTitle={'Update Client Specific Information'}
        modalOpen={clientInfoModalOpen}
        onClose={onClientInfoFormModalClosedHandler}
        onSaveButtonClicked={onClientInfoFormModalSavedHandler}
        formData={clientSpecificInfoFormData}
      />

      <StandardFormModal
        modalTitle={'Update Relationship Contact'}
        modalOpen={relateContactModalOpen}
        onClose={onRelateContactFormModalClosedHandler}
        onSaveButtonClicked={onRelateContactFormModalSavedHandler}
        formData={relationshipContactFormData}
      />

      <StandardFormModal
        modalTitle={'Update Secondary Relationship Contact'}
        modalOpen={secondRelateModalContactOpen}
        onClose={onSecondRelateContactFormModalClosedHandler}
        onSaveButtonClicked={onSecondRelateContactFormModalSavedHandler}
        formData={secondRelationshipContactFormData}
      />

      <StandardFormModal
        modalTitle={'Update Operational Contact'}
        modalOpen={operationalContactModalOpen}
        onClose={onOperationalContactFormModalClosedHandler}
        onSaveButtonClicked={onOperationalContactFormModalSavedHandler}
        formData={operationalContactFormData}
      />

      <AdminPageHeader pageHeaderText={'Admin Control Panel'} />
      <ScrollableTabs
        id={'admin_page_tabs'}
        variant={'default'}
        selectedIndex={currentTabIndex}
        onTabSelect={onTabSelectedHandler}
        className=''
      >
        <TabsPanel label='Info'>
          <AdminInfoFormTabWrapper
            companyInfoFormDisplay={
              <CompanyForm
                formData={companyInfoFormDataReadOnly}
                onEditButtonClicked={onCompanyFormModalOpenedHandler}
              />
            }
            clientSpecificInfoFormDisplay={
              <ClientSpecificInfoForm
                formData={clientSpecificInfoFormData}
                onEditButtonClicked={onClientInfoFormModalOpenedHandler}
              />
            }
            relationshipContactFormDisplay={
              <RelationshipContactForm
                formData={relationshipContactFormData}
                onEditButtonClicked={onRelateContactFormModalOpenedHandler}
              />
            }
            secondRelationshipContactFormDisplay={
              <SecondRelationshipContactForm
                formData={secondRelationshipContactFormData}
                onEditButtonClicked={
                  onSecondRelateContactFormModalOpenedHandler
                }
              />
            }
            operationalContactFormDisplay={
              <OperationalContactForm
                formData={operationalContactFormData}
                onEditButtonClicked={onOperationalContactFormModalOpenedHandler}
              />
            }
          />
        </TabsPanel>
        <TabsPanel label='Users'>
          <AdminDataTable
            tableHeading={'User'}
            addButtonClicked={onAddNewUserClicked}
            columns={adminUserTableColumns}
            total={userList.total}
            data={userList.records ?? []}
            hasMore={currentTabIndex === 1}
            onLoadMoreHandler={onLoadMoreUserHandler}
          />
        </TabsPanel>
        <TabsPanel label='Bank Accounts'>
          <AdminDataTable
            tableHeading={'Bank Accounts'}
            addButtonClicked={onAddBankAccountClicked}
            columns={bankAccountTableColumns}
            total={bankAccountList.total}
            data={bankAccountList.records ?? []}
            hasMore={currentTabIndex === 2}
            onLoadMoreHandler={onLoadMoreBankAccountHandler}
            hideAddButton={true}
          />
        </TabsPanel>
        <TabsPanel label='Pricing'>
          <AdminDataTable
            tableHeading={'Pricing Table'}
            columns={pricingTableColumns}
            data={pricing.records ?? []}
            hasMore={currentTabIndex === 3}
            onLoadMoreHandler={onLoadMorePricingHandler}
            hideAddButton={true}
          />
        </TabsPanel>
      </ScrollableTabs>

      <UserRecordModal
        isOpen={editUserRecordModalOpen}
        userData={userDataDetail}
        modalTitle={'Edit User'}
        onClose={onEditUserRecordClosed}
        onSubmitted={onEditUserRecordSubmitted}
      />
      <EditBankAccountModal
        isOpen={editBankAccountModalOpen}
        bankAccountData={bankAccountDetail}
        modalTitle={'Edit Bank Account'}
        onClose={closedEditBankAccountModalHandler}
        onSubmitted={onEditBankAccountSubmitted}
      />
      {bankAccountLoading ? <Spinner /> : null}
      {loading ? <Spinner /> : null}
      {adminData.loading ? <Spinner /> : null}
    </>
  )
}

export default AdminPageContent
