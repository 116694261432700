import React, { useEffect } from 'react'
import { ContactUsText } from '../../components/ContactUs/ContactUsText'
import { ContactUsTextWrapper } from './StyledContactUsContent'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchGlobalSetting,
  getContactUsPageText,
  getContactUsPagePhone,
  getContactUsPageEmail,
  getContactUsPageImageUrl,
  getPortalName
} from '../../redux/slices/global'
import { setAppTitle } from '../../helpers/appState'

const ContactUsContent = () => {
  const dispatch = useDispatch()

  const portalName = useSelector(getPortalName)

  const contactUsContentText = useSelector(getContactUsPageText)
  const contactUsPagePhone = useSelector(getContactUsPagePhone)
  const contactUsPageEmail = useSelector(getContactUsPageEmail)
  const contactUsPageImageUrl = useSelector(getContactUsPageImageUrl)

  useEffect(() => {
    setAppTitle(portalName, 'Contact')
    dispatch(fetchGlobalSetting())
  }, [])

  return (
    <>
      <ContactUsTextWrapper>
        <ContactUsText
          contactUsText={contactUsContentText}
          phone={contactUsPagePhone}
          email={contactUsPageEmail}
          image={contactUsPageImageUrl}
        />
      </ContactUsTextWrapper>
    </>
  )
}

export default ContactUsContent
