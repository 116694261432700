import React from 'react'
import styled from 'styled-components'

import { PromiseToPayListContent } from '../../containers'

const PromiseToPayListPageWrapper = styled.div({ padding: '0.5rem' })

const PromiseToPayListPage = () => {
  return (
    <PromiseToPayListPageWrapper>
      <PromiseToPayListContent />
    </PromiseToPayListPageWrapper>
  )
}

export default PromiseToPayListPage
