import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'

import { Timepicker } from '@salesforce/design-system-react'
import { getTimeFormat } from '../../../helpers/date'

const StandardTimeInput = ({
  name,
  label,
  value,
  required,
  disabled,
  errorText,
  stepInMinutes,
  onChange,
  ...props
}) => {
  const dateValue = value ? dayjs(value).toDate() : null
  const onDateSelectedHandler = useCallback(
    timeValue => {
      onChange(timeValue, name)
    },
    [onChange, name]
  )

  return (
    <>
      <Timepicker
        name={name}
        label={label}
        stepInMinutes={stepInMinutes ?? 30}
        onDateChange={onDateSelectedHandler}
        disabled={disabled}
        value={dateValue}
        strValue={getTimeFormat(dateValue) ?? ''}
        required={required}
        {...props}
      />
      {errorText ? <p className='slsd-text-color_error'>{errorText}</p> : null}
    </>
  )
}

StandardTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  stepInMinutes: PropTypes.number
}

export default StandardTimeInput
