import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@salesforce/design-system-react'
import styled from 'styled-components'

const StyledIconButton = styled(Button)`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const SideMenu = ({ onClick }) => {
  return (
    <StyledIconButton
      iconCategory='utility'
      iconName='rows'
      iconVariant='container'
      iconSize='large'
      variant='icon'
      onClick={onClick}
    />
  )
}

SideMenu.propTypes = {
  onClick: PropTypes.func
}

export default SideMenu
