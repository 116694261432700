import React, { useCallback, useEffect } from 'react'

// import { PAGE_SIZE } from '../../constants'

import { PromiseToPayTable } from '../../components'

import { useDispatch, useSelector } from 'react-redux'

import {
  fetchPromiseToPays,
  getPromiseToPayList
} from '../../redux/slices/promiseToPays'
import { setAppTitle } from '../../helpers/appState'
import { getPortalName } from '../../redux/slices/global'

const PromiseToPayListContent = () => {
  const dispatch = useDispatch()

  const portalName = useSelector(getPortalName)
  const promiseToPayList = useSelector(getPromiseToPayList)

  const onPromiseToPaysLoadMoreHandler = useCallback(() => {
    if (!promiseToPayList.loading) {
      dispatch(
        fetchPromiseToPays({
          page: promiseToPayList.page + 1,
          size: 1000
        })
      )
    }
  }, [promiseToPayList.loading, promiseToPayList.page])

  useEffect(() => {
    setAppTitle(portalName, 'Promise to Pays')
    dispatch(fetchPromiseToPays({ page: 1, size: 1000 }))
  }, [])

  return (
    <div className='slds-p-around_small'>
      <PromiseToPayTable
        data={promiseToPayList.records ?? []}
        onLoadMore={onPromiseToPaysLoadMoreHandler}
        totalItems={promiseToPayList.total}
      />
    </div>
  )
}

export default PromiseToPayListContent
