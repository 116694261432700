import React from 'react'
import { Card, Icon, Button, Badge } from '@salesforce/design-system-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
const InsideCardWrapper = styled.div`
  .fullwidth {
    width: 100%;
  }
  .slds-button {
    margin: 0.5rem;
  }
`
const UserRecordHeader = ({ onEditUserRecordClicked }) => {
  return (
    <InsideCardWrapper>
      <Card
        id='UserRecord'
        header={
          <div className='slds-grid slds-wrap fullwidth'>
            <div className='slds-col slds-medium-size_1-of-5 slds-media'>
              <div>
                <Icon
                  category='standard'
                  colorVariant='base'
                  name='document'
                  size='large'
                />
              </div>
              <div className='slds-p-left_small slds-text-align_left slds-media__body slds-text-heading_small'>
                Lo rem
                <div className='slds-p-top_xxx-small'>
                  <Badge
                    id='badge-base-example-success'
                    color='success'
                    content='Active'
                  />
                </div>
              </div>
            </div>
            <div className='slds-text-align_right slds-col slds-medium-size_4-of-5'>
              <Button
                label='Edit'
                variant='brand'
                onClick={onEditUserRecordClicked}
              />
            </div>
          </div>
        }
      ></Card>
    </InsideCardWrapper>
  )
}

UserRecordHeader.propTypes = {
  onEditUserRecordClicked: PropTypes.func.isRequired
}

export default UserRecordHeader
