import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  getFormValidStatus,
  clearForm
} from '../../../helpers/form'

import { Button } from '@salesforce/design-system-react'

import { StandardModal } from '../StandardModal'
import { isEmpty } from 'lodash'

const StandardFormModal = ({
  modalOpen,
  onClose,
  onSaveButtonClicked,
  formData,
  modalTitle,
  additionalText,
  hasDatePicker,
  size = 'medium'
}) => {
  const formRef = useRef(null)
  const [internalFormData, setInternalFormData] = useState(formData)

  const onInternalFormInputChangedHandler = useCallback((value, name) => {
    setInternalFormData(prev => updateForm(prev, name, value))
  }, [])

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(internalFormData)
      if (formValid && checkValidForm.formValid) {
        const data = extractForm(internalFormData, true)

        if (!isEmpty(data)) {
          onSaveButtonClicked(data)
          setInternalFormData(prev => clearForm(prev))
        } else {
          onClose()
        }

      } else {
        setInternalFormData(checkValidForm.validatedForm)
      }
    }
  }, [internalFormData, onSaveButtonClicked])

  useEffect(() => {
    setInternalFormData(formData)
  }, [formData])

  const additionalTextDisplay = additionalText ? (
    <p className='slds-m-bottom_small slds-text-align_center'>
      {additionalText}
    </p>
  ) : null

  return (
    <StandardModal
      isOpen={modalOpen}
      modalTitle={modalTitle}
      size={size}
      onClose={onClose}
      hasDatePicker={hasDatePicker}
      footer={
        <Button
          label='Save'
          variant='brand'
          onClick={onSaveButtonClickedHandler}
        />
      }
    >
      {additionalTextDisplay}
      <form ref={formRef} onSubmit={onSubmittedHandler}>
        {getFormDisplay(internalFormData, onInternalFormInputChangedHandler)}
      </form>
    </StandardModal>
  )
}

StandardFormModal.propTypes = {
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editButtonClicked: PropTypes.func,
  formData: PropTypes.object,
  modalTitle: PropTypes.string,
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSaveButtonClicked: PropTypes.func,
  hasDatePicker: PropTypes.bool,
  size: PropTypes.string
}

export default StandardFormModal
