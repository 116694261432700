import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { StandardModal } from '../../common'
import { Button } from '@salesforce/design-system-react'

import { useDispatch, useSelector } from 'react-redux'

import {
  extractForm,
  getFormDisplay,
  updateForm,
  clearForm,
  applyValidation,
  getFormValidStatus
} from '../../../helpers/form'

import {
  requestUpgradeJob,
  getUpgradeJobRequestResult,
  resetUpgradeJobRequestResult
} from '../../../redux/slices/job'

import jobUpgradeRequestForm from './jobUpgradeRequestForm'

import useAlert from '../../../hooks/useAlert'

import { createSuccessAlert, createErrorAlert } from '../../../helpers/alert'
import { getFieldValidator } from '../../../redux/slices/global'

const JobUpgradeRequestModal = ({ isOpen, modalTitle, jobId, onClose, costForSuccessful, quoteUpgradeCostApproval }) => {
  const dispatch = useDispatch()
  const fireAlert = useAlert()

  const formRef = useRef(null)
  const [formData, setFormData] = useState(jobUpgradeRequestForm)
  const validator = useSelector(getFieldValidator)

  const upgradeJobResult = useSelector(getUpgradeJobRequestResult)

  const onInputChangedHandler = useCallback((value, name) => {
    setFormData(prev => {
      const updatedForm = updateForm(prev, name, value)

      if (name === 'outcome') {
        if (value?.selection?.[0]?.id === 'Decline & Close') {
          updatedForm.reasonForDecline.inputProps.hidden = false
        } else {
          updatedForm.reasonForDecline.inputProps.hidden = true
          updatedForm.reasonForDecline.value = null
        }
      }

      return updatedForm
    })
  })

  const onSaveButtonClickedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      form.dispatchEvent(new Event('submit'))
    }
  }, [])

  const onSubmittedHandler = useCallback(() => {
    const form = formRef.current

    if (form) {
      const formValid = form.reportValidity()
      const checkValidForm = getFormValidStatus(formData)

      if (formValid && checkValidForm.formValid) {
        const data = extractForm(formData)
        const formedData = {
          ...data,
          outcome: data.outcome?.[0]?.id
        }

        dispatch(requestUpgradeJob({ jobId: jobId, data: formedData }))
        onClose()
      } else {
        setFormData(checkValidForm.validatedForm)
      }
    }
  }, [formData, jobId])

  const formDisplay = getFormDisplay(formData, onInputChangedHandler)

  useEffect(() => {
    if (upgradeJobResult.statusCode) {
      if ([200, 201].includes(upgradeJobResult.statusCode)) {
        fireAlert(
          createSuccessAlert(
            'Your request to upgrade this job has been succesfully sent.',
            1500
          )
        )
      } else {
        fireAlert(createErrorAlert(upgradeJobResult.error, 1500))
      }
      dispatch(resetUpgradeJobRequestResult())
    }
  }, [upgradeJobResult])

  useEffect(() => {
    setFormData(prev => applyValidation(prev, validator?.postComment))
  }, [validator])

  useEffect(() => {
    setFormData(prev => {
      const clearedForm = clearForm(prev)
      
      clearedForm.reasonForDecline.inputProps.hidden = true
      return clearedForm
    })
  }, [isOpen])

  return (
    <StandardModal
      hasDatePicker={true}
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={onClose}
      footer={
        <div className='slds-grid slds-grid_align-end'>
          <Button
            className='slds-col'
            iconName='save'
            iconCategory='utility'
            variant='brand'
            iconPosition='left'
            onClick={onSaveButtonClickedHandler}
            label='Submit Upgrade Request'
          />
        </div>
      }
    >
      <div className='slds-p-around_x-small'>

      {quoteUpgradeCostApproval ?
      <div>
        <p className="slds-p-top_x-small slds-text-align_center slds-text-heading_small ">The requested upgrade requires a new quote. The Cost for Upgrade is</p>
        <p className="slds-p-bottom_x-small slds-text-align_center slds-text-heading_small ">{`$${costForSuccessful.toFixed(2)}` ?? `$${(0.00).toFixed(2)}`}</p> 
      </div>
        : null
      }
        <form ref={formRef} onSubmit={onSubmittedHandler}>
          {formDisplay}
        </form>
      </div>
    </StandardModal>
  )
}

JobUpgradeRequestModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  jobId: PropTypes.string,
  onClose: PropTypes.func,
  costForSuccessful: PropTypes.string,
  quoteUpgradeCostApproval: PropTypes.bool
}

export default memo(JobUpgradeRequestModal)
