import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Button, Card } from '@salesforce/design-system-react'

import { LightningDataTable } from '../..'

const AdminDataTable = ({
  addButtonClicked,
  tableHeading,
  columns,
  data,
  total,
  hasMore,
  onLoadMoreHandler,
  hideAddButton
}) => {
  return (
    <Card
      bodyClassName='slds-p-around_medium'
      heading={<p className='slds-text-heading_medium'>{tableHeading}</p>}
      headerActions={
        hideAddButton ? null : (
          <Button
            iconCategory='utility'
            iconName='add'
            iconPosition='left'
            label='Add'
            onClick={addButtonClicked}
          />
        )
      }
    >
      <LightningDataTable
        id={tableHeading + 'dataTable'}
        columns={columns}
        items={data}
        totalItems={total}
        hasMore={hasMore}
        onLoadMore={onLoadMoreHandler}
        className={'no-shrink-column'}
      />
    </Card>
  )
}

AdminDataTable.propTypes = {
  tableHeading: PropTypes.string,
  addButtonClicked: PropTypes.func,
  columns: PropTypes.node,
  data: PropTypes.array,
  total: PropTypes.number,
  hasMore: PropTypes.bool,
  onLoadMoreHandler: PropTypes.func,
  hideAddButton: PropTypes.bool
}

export default memo(AdminDataTable)
