import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Icon, TabsPanel } from '@salesforce/design-system-react'

import { ActiveJobsTable } from './ActiveJobsTable'
import { SuspendedJobsTable } from './SuspendedJobsTable'
import { UpgradeJobsTable } from './UpgradeJobsTable'
import { ClosedJobsTable } from './ClosedJobsTable'
import { CardSearchHeader, ScrollableTabs } from '../../common'
import { filterRecords, sortRecords } from '../../../helpers/dataTable'
import getActiveJobColumns from './ActiveJobsTable/activeJobColumns'
import getSuspendedJobColumns from './SuspendedJobsTable/suspendedJobColumns'
import getUpgradeJobColumns from './UpgradeJobsTable/upgradeJobColumns'
import getClosedJobColumns from './ClosedJobsTable/closedJobColumns'

const JobTables = ({
  currentTabIndex,
  activeJobs,
  totalActiveJobs,
  suspendedJobs,
  totalSuspendedJobs,
  onTabChanged,
  onLoadMoreActiveJobs,
  onLoadMoreSuspendedJobs,
  upgradeJobs,
  totalUpgradeJobs,
  onLoadMoreUpgradeJobs,
  closedJobs,
  totalClosedJobs,
  onLoadMoreClosedJobs,
}) => {
  const [filtering, setFiltering] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [internalActiveJobList, setInternalActiveJobList] = useState([])
  const [internalSuspendedJobList, setInternalSuspendedJobList] = useState([])
  const [internalUpgradeJobList, setInternalUpgradeJobList] = useState([])
  const [internalClosedJobList, setInternalClosedJobList] = useState([])

  const [currentActiveJobSortColumn, setCurrentActiveJobSortColumn] =
    useState(null)
  const [activeJobSortDirectionMemo, setActiveJobSortDirectionMemo] = useState(
    {}
  )

  const [currentSuspendedJobSortColumn, setCurrentSuspendedJobSortColumn] =
    useState(null)
  const [suspendedJobSortDirectionMemo, setSuspendedJobSortDirectionMemo] =
    useState({})

  const [currentUpgradeJobSortColumn, setCurrentUpgradeJobSortColumn] =
    useState(null)
  const [upgradeJobSortDirectionMemo, setUpgradeJobSortDirectionMemo] =
    useState({})

  const [currentClosedJobSortColumn, setCurrentClosedJobSortColumn] =
    useState(null)
  const [closedJobSortDirectionMemo, setClosedJobSortDirectionMemo] = useState(
    {}
  )

  const activeJobColumns = getActiveJobColumns({
    sortColumn: currentActiveJobSortColumn,
    sortDirectionMemo: activeJobSortDirectionMemo
  })

  const suspendedJobColumns = getSuspendedJobColumns({
    sortColumn: currentSuspendedJobSortColumn,
    sortDirectionMemo: suspendedJobSortDirectionMemo
  })

  const upgradeJobColumns = getUpgradeJobColumns({
    sortColumn: currentUpgradeJobSortColumn,
    sortDirectionMemo: upgradeJobSortDirectionMemo
  })

  const closedJobColumns = getClosedJobColumns({
    sortColumn: currentClosedJobSortColumn,
    sortDirectionMemo: closedJobSortDirectionMemo
  })

  const filterChangedHandler = useCallback(event => {
    setFilterText(event?.target?.value)
  }, [])

  const onSearchHandler = useCallback(() => {
    let filteredActiveJobs = filterRecords(
      filterText,
      activeJobs,
      activeJobColumns
    )

    let filteredSuspendedJobs = filterRecords(
      filterText,
      suspendedJobs,
      suspendedJobColumns
    )

    let filteredUpgradeJobs = filterRecords(
      filterText,
      upgradeJobs,
      upgradeJobColumns
    )

    let filteredClosedJobs = filterRecords(
      filterText,
      closedJobs,
      closedJobColumns
    )

    if (currentActiveJobSortColumn != null) {
      filteredActiveJobs = sortRecords(
        filteredActiveJobs,
        currentActiveJobSortColumn,
        activeJobSortDirectionMemo
      )
    }

    if (currentSuspendedJobSortColumn != null) {
      filteredSuspendedJobs = sortRecords(
        filteredSuspendedJobs,
        currentSuspendedJobSortColumn,
        suspendedJobSortDirectionMemo
      )
    }

    if (currentUpgradeJobSortColumn != null) {
      filteredUpgradeJobs = sortRecords(
        filteredUpgradeJobs,
        currentUpgradeJobSortColumn,
        upgradeJobSortDirectionMemo
      )
    }

    if (currentClosedJobSortColumn != null) {
      filteredClosedJobs = sortRecords(
        filteredClosedJobs,
        currentClosedJobSortColumn,
        closedJobSortDirectionMemo
      )
    }

    setInternalActiveJobList(filteredActiveJobs)
    setInternalSuspendedJobList(filteredSuspendedJobs)
    setInternalUpgradeJobList(filteredUpgradeJobs)
    setInternalClosedJobList(filteredClosedJobs)
    setFiltering(!!filterText)
  }, [
    filterText,
    suspendedJobs,
    activeJobs,
    upgradeJobs,
    closedJobs
  ])

  const onActiveJobSortHandler = useCallback(
    sortColumn => {
      const { sortDirection, property: sortProperty } = sortColumn

      // This method may return null if data provided item is not array
      const sortedItems = sortRecords(
        internalActiveJobList,
        sortProperty,
        sortDirection
      )

      if (sortedItems) {
        setInternalActiveJobList(sortedItems)
        setCurrentActiveJobSortColumn(sortProperty)
        setActiveJobSortDirectionMemo(prev => ({
          ...prev,
          [sortProperty]: sortDirection
        }))
      }
    },
    [internalActiveJobList]
  )

  const onSuspendedJobSortHandler = useCallback(
    sortColumn => {
      const { sortDirection, property: sortProperty } = sortColumn

      // This method may return null if data provided item is not array
      const sortedItems = sortRecords(
        internalSuspendedJobList,
        sortProperty,
        sortDirection
      )

      if (sortedItems) {
        setInternalSuspendedJobList(sortedItems)
        setCurrentSuspendedJobSortColumn(sortProperty)
        setSuspendedJobSortDirectionMemo(prev => ({
          ...prev,
          [sortProperty]: sortDirection
        }))
      }
    },
    [internalSuspendedJobList]
  )

  const onUpgradeJobSortHandler = useCallback(
    sortColumn => {
      const { sortDirection, property: sortProperty } = sortColumn

      // This method may return null if data provided item is not array
      const sortedItems = sortRecords(
        internalUpgradeJobList,
        sortProperty,
        sortDirection
      )

      if (sortedItems) {
        setInternalUpgradeJobList(sortedItems)
        setCurrentUpgradeJobSortColumn(sortProperty)
        setUpgradeJobSortDirectionMemo(prev => ({
          ...prev,
          [sortProperty]: sortDirection
        }))
      }
    },
    [internalUpgradeJobList]
  )

  const onClosedJobSortHandler = useCallback(
    sortColumn => {
      const { sortDirection, property: sortProperty } = sortColumn

      // This method may return null if data provided item is not array
      const sortedItems = sortRecords(
        internalClosedJobList,
        sortProperty,
        sortDirection
      )

      if (sortedItems) {
        setInternalClosedJobList(sortedItems)
        setCurrentClosedJobSortColumn(sortProperty)
        setClosedJobSortDirectionMemo(prev => ({
          ...prev,
          [sortProperty]: sortDirection
        }))
      }
    },
    [internalClosedJobList]
  )

  useEffect(() => {
    onSearchHandler()
    return () => {
      setInternalActiveJobList([])
      setInternalSuspendedJobList([])
      setInternalUpgradeJobList([])
      setInternalClosedJobList([])
    }
  }, [activeJobs, suspendedJobs, upgradeJobs, closedJobs])

  return (
    <Card
      className='slds-text-align_left'
      header={
        <CardSearchHeader
          headerText={`Jobs (${
            totalActiveJobs +
            totalSuspendedJobs +
            totalUpgradeJobs +
            totalClosedJobs
          })`}
          onFilterChange={filterChangedHandler}
          onSearch={onSearchHandler}
          icon={<Icon category='standard' name='work_capacity_usage' />}
        />
      }
    >
      <ScrollableTabs
        selectedTab={currentTabIndex}
        variant='scoped'
        id='jobTables'
        onTabSelect={onTabChanged}
      >
        <TabsPanel label={`Approval Needed (${totalUpgradeJobs})`}>
          <UpgradeJobsTable
            jobList={internalUpgradeJobList}
            total={totalUpgradeJobs}
            hasMore={
              currentTabIndex === 0 && upgradeJobs.length < totalUpgradeJobs
            }
            onLoadMore={onLoadMoreUpgradeJobs}
            onSort={onUpgradeJobSortHandler}
            columnDefinitions={upgradeJobColumns}
            isFiltering={filtering}
          />
        </TabsPanel>
        <TabsPanel label={`Active Jobs (${totalActiveJobs})`}>
          <ActiveJobsTable
            jobList={internalActiveJobList}
            total={totalActiveJobs}
            hasMore={
              currentTabIndex === 1 && activeJobs.length < totalActiveJobs
            }
            onLoadMore={onLoadMoreActiveJobs}
            onSort={onActiveJobSortHandler}
            columnDefinitions={activeJobColumns}
            isFiltering={filtering}
          />
        </TabsPanel>
        <TabsPanel label={`Suspended Jobs (${totalSuspendedJobs})`}>
          <SuspendedJobsTable
            jobList={internalSuspendedJobList}
            total={totalSuspendedJobs}
            hasMore={
              currentTabIndex === 2 && suspendedJobs.length < totalSuspendedJobs
            }
            onLoadMore={onLoadMoreSuspendedJobs}
            onSort={onSuspendedJobSortHandler}
            columnDefinitions={suspendedJobColumns}
            isFiltering={filtering}
          />
        </TabsPanel>
        <TabsPanel label={`Closed Jobs (${totalClosedJobs})`}>
          <ClosedJobsTable
            jobList={internalClosedJobList}
            total={totalClosedJobs}
            hasMore={
              currentTabIndex === 3 && closedJobs.length < totalClosedJobs
            }
            onLoadMore={onLoadMoreClosedJobs}
            onSort={onClosedJobSortHandler}
            columnDefinitions={closedJobColumns}
            isFiltering={filtering}
          />
        </TabsPanel>
      </ScrollableTabs>
    </Card>
  )
}

JobTables.propTypes = {
  currentTabIndex: PropTypes.number,
  onTabChanged: PropTypes.func.isRequired,
  onLoadMoreActiveJobs: PropTypes.func.isRequired,
  onLoadMoreSuspendedJobs: PropTypes.func.isRequired,
  onLoadMoreUpgradeJobs: PropTypes.func.isRequired,
  onLoadMoreClosedJobs: PropTypes.func.isRequired,
  activeJobs: PropTypes.array,
  suspendedJobs: PropTypes.array,
  upgradeJobs: PropTypes.array,
  closedJobs: PropTypes.array,
  totalActiveJobs: PropTypes.number,
  totalSuspendedJobs: PropTypes.number,
  totalUpgradeJobs: PropTypes.number,
  totalClosedJobs: PropTypes.number
}

export default memo(JobTables)
