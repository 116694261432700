import styled from 'styled-components'

export const FormWrapper = styled.div`
  text-align: left;
  padding: 1rem;
`

export const CardWrapper = styled.div`
  text-align: left;
  .fullwidth {
    width: 100%;
  }
`
