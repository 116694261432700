import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  & .slds-form-element {
    padding-top: 20px;
  }
  .input-header {
    padding-top: 20px;
  }
  @media screen and (max-width: 1023px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

export const ParagraphWrapper = styled.div`
  .input-text {
    height: 32px;
    margin-bottom: 0.5rem;
  }
`
