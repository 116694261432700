import { unionBy } from "lodash"

export const createReduxRecordListState = (
  initRecords = [],
  defaultPage = 1,
  defaultSize = 20
) => {
  return {
    records: initRecords,
    page: defaultPage,
    size: defaultSize,
    total: 0,
    totalPages: 0,
    error: null,
    loading: false
  }
}

export const createReduxRecordDetailState = (initData = null) => {
  return { record: initData, loading: false, error: null, statusCode: null }
}

export const updateReduxRecords = (stateRecords, records = [], page = 1) => {
  return page === 1 ? records : unionBy(stateRecords, records, 'id')
}

export const createReduxRecordPostResultState = () => {
  return {
    statusCode: null,
    message: null,
    error: null,
    loading: false
  }
}

export const mapDropdownItemIdFromValue = item => {
  return {
    ...item,
    id: item.value ?? item.id,
    label: item.label ?? item.portalLabel
  }
}
