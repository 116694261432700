import React from 'react'

import styled from 'styled-components'
import { JobDetailContent } from '../../../containers'

const JobPageWrapper = styled.div`
  padding: 1rem;
  background: white;
`

const JobDetailPage = () => {
  return (
    <JobPageWrapper>
      <JobDetailContent />
    </JobPageWrapper>
  )
}

export default JobDetailPage
