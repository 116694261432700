import {
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_TEXTAREA,
  INPUT_TYPE_CHECKBOX
} from '../../../constants'

export default {
  outcome: {
    inputType: INPUT_TYPE_DROPDOWN,
    inputProps: {
      label: 'Outcome',
      errorText: null,
      required: true,
      options: [
        { label: 'Confirmed', id: 'Confirmed' },
        { label: 'Not Confirmed', id: 'Not Confirmed' }
      ]
    },
    value: null,
    valid: false
  },
  notedForJobFile: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Note for Job File',
      type: INPUT_TYPE_TEXTAREA,
      errorText: null,
      required: true,
      multiple: false,
      maxLength: 500
    },
    value: '',
    valid: false
  },
  remainOnHold: {
    inputType: INPUT_TYPE_CHECKBOX,
    inputProps: {
      label: 'Remain on Hold',
      type: INPUT_TYPE_CHECKBOX
    },
    value: false,
    checked: false,
    valid: false
  }
}
