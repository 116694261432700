import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getFormDisplay } from '../../../helpers/form'
import { Card } from '@salesforce/design-system-react'

const FormCard = ({
  icon,
  header,
  form,
  onInputChanged,
  headerActions,
  readOnly,
  bodyText
}) => {
  const formDisplay = getFormDisplay(form, onInputChanged, {
    isAllReadOnly: readOnly
  })

  return (
    <Card
      className='slds-m-top_small'
      heading={header}
      icon={icon}
      headerActions={headerActions}
      bodyClassName='slds-p-around_small'
    >
      {bodyText ? <div className='slds-m-bottom_small'>{bodyText}</div> : null}
      <div>{formDisplay}</div>
    </Card>
  )
}

FormCard.propTypes = {
  form: PropTypes.object,
  onInputChanged: PropTypes.func,
  icon: PropTypes.node,
  header: PropTypes.node,
  headerActions: PropTypes.node,
  readOnly: PropTypes.bool,
  bodyText: PropTypes.string
}

export default memo(FormCard)
