import { DataTableColumn } from '@salesforce/design-system-react'
import { isArray } from 'lodash'
import { CustomDataTableCell } from '../components'
import { INPUT_TYPE_DATETIME } from '../constants'
import { getDateFormat } from './date'

export const generateTableColumns = columnDefinitions => {
  const columns = columnDefinitions.map((colDef, index) => {
    const { property, cellProps, sortingInfo, truncate, ...rest } = colDef

    const { sortable, currentSortColumn, sortDirectionMemo } = sortingInfo
      ? sortingInfo
      : {}

    const isSorted = sortable ? currentSortColumn === property : false

    const cellKey = property + index

    return (
      <DataTableColumn
        key={cellKey}
        id={cellKey}
        property={property}
        sortable={!!sortable}
        isSorted={isSorted}
        sortDirection={isSorted ? sortDirectionMemo[property] : null}
        {...rest}
      >
        <CustomDataTableCell {...cellProps} truncate={truncate} />
      </DataTableColumn>
    )
  })

  return columns
}

export const buildSimpleColumn = (label, key, isPrimary, sortingInfo, truncate = true) => {
  return { label, property: key, primaryColumn: isPrimary, sortingInfo, truncate }
}

export const buildLinkColumn = (
  label,
  key,
  isPrimary,
  { baseUrl, onClick, displayAsIcon = false },
  sortingInfo,
  truncate
) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'link',
    cellProps: { baseUrl, onClick, displayAsIcon }
  }
}

export const buildNumberColumn = (
  label,
  key,
  isPrimary,
  { thousandSeparator = true, decimalPoint = 0 },
  sortingInfo,
  truncate
) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'number',
    cellProps: { thousandSeparator, decimalPoint }
  }
}

export const buildCurrencyColumn = (
  label,
  key,
  isPrimary,
  { thousandSeparator = true, decimalPoint = 0, range1Key, range2Key },
  sortingInfo,
  truncate
) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'currency',
    cellProps: { thousandSeparator, decimalPoint, range1Key, range2Key }
  }
}

export const buildDateTimeColumn = (
  label,
  key,
  isPrimary,
  { withTime = false, applyTimezoneOnDate = false },
  sortingInfo,
  truncate
) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'datetime',
    // width: window.innerWidth > 768 ? null : '10rem', // TODO: enhance this
    cellProps: { withTime, applyTimezoneOnDate }
  }
}

export const buildFileColumn = (
  label,
  key,
  isPrimary,
  { srcKey },
  sortingInfo,
  truncate
) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'file',
    cellProps: { srcKey }
  }
}

export const buildCheckboxColumn = (label, key, isPrimary, sortingInfo, truncate) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'checkbox'
  }
}

export const buildActiveStatusBadgeColumn = (
  label,
  key,
  isPrimary,
  sortingInfo,
  truncate
) => {
  return {
    ...buildSimpleColumn(label, key, isPrimary, sortingInfo, truncate),
    dataType: 'activeStatusBadge'
  }
}

export const sortRecords = (items, sortProperty, sortDirection) => {
  if (!isArray(items)) {
    console.error('Error sorting record: provided records is not an array')
    return []
  }

  const clonedArray = [...items]

  const sortedItems = clonedArray.sort((a, b) => {
    let val = 0
    if (!a[sortProperty]) {
      val = -1
    }
    if (!b[sortProperty]) {
      val = 1
    }
    if (a[sortProperty] > b[sortProperty]) {
      val = 1
    }
    if (a[sortProperty] < b[sortProperty]) {
      val = -1
    }
    if (sortDirection === 'desc') {
      val *= -1
    }
    if (a[sortProperty] === b[sortProperty]) {
      val = 0
    }
    return val
  })

  return sortedItems
}

export const filterRecords = (
  filterValue,
  items = [],
  columnDefinitions = []
) => {
  if (!isArray(items) || !isArray(columnDefinitions)) {
    console.error(
      'Error filtering record: provided records or columnDefinitions is not an array'
    )
    return []
  }

  const clonedArray = [...items]

  const filteredItems = clonedArray.filter(item => {
    const matched = columnDefinitions.some(column => {
      const { property, dataType } = column

      const rawData = item[property]
      let testData = `${rawData}`.toLowerCase()

      if (dataType === INPUT_TYPE_DATETIME) {
        testData = getDateFormat(rawData)
      }

      const regexString = `${filterValue.toLowerCase()}`
      const matcher = new RegExp(regexString)

      return matcher.test(testData)
    })

    return matched
  })

  return filteredItems
}
