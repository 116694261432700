import styled from 'styled-components'

export const FAQContentWrapper = styled.div`
  & .faq-title {
    text-align: center;
  }
  & .question {
    font-size: 16px;
    font-weight: bold;
  }
  & .answer {
    padding-top: 10px;
    font-size: 16px;
  }
  & .faq-container {
    padding: 15px;
  }
`
