import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => (props.margin ? props.margin + ';' : '0.5rem 0;')};

  .border {
    border-bottom: 1px solid
      rgba(
        0,
        0,
        0,
        ${props => (props.borderThickness ? props.borderThickness : 0.2)}
      );
    width: 100%;
  }

  .content {
    padding: 0 10px 0 10px;
  }
`

const Divider = ({ children, margin, borderThickness }) => {
  const renderChildren = children ? (
    <span className='content'>{children}</span>
  ) : null
  return (
    <StyledContainer margin={margin} borderThickness={borderThickness}>
      <div className='border' />
      {renderChildren}
      <div className='border' />
    </StyledContainer>
  )
}

Divider.propTypes = {
  children: PropTypes.node,
  margin: PropTypes.string,
  borderThickness: PropTypes.number
}

export default Divider
