import React from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Button,
  Icon,
  PageHeader,
  PageHeaderControl
} from '@salesforce/design-system-react'
import { toLower } from 'lodash'

const notActiveStatuses = ['on hold', 'closed']
const suspendedStatuses = ['on hold']
const upgradeStatuses = [
  'awaiting skip upgrade confirmation',
  'awaiting collection upgrade approval',
  'awaiting repossession upgrade approval',
  'awaiting collection/repossession upgrade approval'
]

const actionUpdateStatus = ['awaiting client update']

const costApprovalActionStatus = ['cost approval required']

const JobRecordPageHeader = ({
  jobName,
  jobStatus,
  closeJobModalHandler,
  holdJobModalHandler,
  unholdJobModalHandler,
  upgradeJobModalHandler,
  actionUpdateModalHandler,
  approveCostModalHandler
}) => {
  let actions = null
  let badgeVariant = 'inverse'
  let status = toLower(jobStatus)

  if (costApprovalActionStatus.includes(status)) {
    // eslint-disable-next-line
    actions = () => (
      <PageHeaderControl>
        <Button
          label={'Action Cost Approval'}
          variant='brand'
          onClick={approveCostModalHandler}
        />
      </PageHeaderControl>
    )
    badgeVariant = 'warning'
  } else if (actionUpdateStatus.includes(status)) {
    // eslint-disable-next-line
    actions = () => (
      <PageHeaderControl>
        <Button
          label={'Action Update Request'}
          variant='brand'
          onClick={actionUpdateModalHandler}
        />
      </PageHeaderControl>
    )
    badgeVariant = 'warning'
  } else if (upgradeStatuses.includes(status)) {
    // eslint-disable-next-line
    actions = () => (
      <PageHeaderControl>
        <Button
          label={'Action Upgrade'}
          variant='brand'
          onClick={upgradeJobModalHandler}
        />
      </PageHeaderControl>
    )
    badgeVariant = 'warning'
  } else if (suspendedStatuses.includes(status)) {
    badgeVariant = 'error'

    // eslint-disable-next-line
    actions = () => (
      <PageHeaderControl>
        <Button
          label={'Release Hold'}
          variant='brand'
          onClick={unholdJobModalHandler}
        />
        <Button
          label={'Close Job'}
          variant='brand'
          onClick={closeJobModalHandler}
        />
      </PageHeaderControl>
    )
  } else if (!notActiveStatuses.includes(status)) {
    // Active status = status not included in not active statuses
    // eslint-disable-next-line
    actions = () => (
      <PageHeaderControl>
        <Button
          label={'Hold Job'}
          variant='brand'
          onClick={holdJobModalHandler}
        />
        <Button
          label={'Close Job'}
          variant='brand'
          onClick={closeJobModalHandler}
        />
      </PageHeaderControl>
    )
    badgeVariant = 'success'
  }

  return (
    <PageHeader
      className='slds-m-bottom_small'
      icon={
        <Icon
          assistiveText={{ label: 'Job' }}
          category='standard'
          name='work_capacity_usage'
        />
      }
      label={<p className='slds-page-header__title slds-truncate'>{jobName}</p>}
      title={
        <Badge id='status-active' color={badgeVariant} content={jobStatus} />
      }
      variant='record-home'
      onRenderActions={actions}
    />
  )
}

JobRecordPageHeader.propTypes = {
  jobName: PropTypes.string,
  actions: PropTypes.func,
  jobStatus: PropTypes.string,
  closeJobModalHandler: PropTypes.func,
  holdJobModalHandler: PropTypes.func,
  unholdJobModalHandler: PropTypes.func,
  upgradeJobModalHandler: PropTypes.func,
  actionUpdateModalHandler: PropTypes.func,
  approveCostModalHandler: PropTypes.func
}

export default JobRecordPageHeader
