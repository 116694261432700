import {
  performGetRequest,
  performPatchRequest
} from '../helpers/service'

export const getOCRFileTypes = async () => {
  const response = await performGetRequest('/ocr-doc-types')

  return response
}

export const patchUpdateJobFileType = async (jobId, data) => {
  const response = await performPatchRequest(`/cases/${jobId}/submit-job-file`, data)

  return response
}

export const patchUpdateJobClientUpload = async (jobId, data) => {
  const response = await performPatchRequest(`/cases/${jobId}`, data)

  return response
}