import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import initialForm from './loginForm'
import {
  clearForm,
  extractForm,
  getFormDisplay,
  updateForm
} from '../../helpers/form'

import { Divider } from '../../components'
import { LoginButton } from '../../pages/LoginPage/StyledLoginItems'
import { getPortalName } from '../../redux/slices/global'
import {
  getLoginErrorMessage,
  getLoginLoadingStatus,
  getLoginStatus,
  getPasswordLoginStatus,
  login,
  resetAuthState
} from '../../redux/slices/auth'
import { Spinner } from '@salesforce/design-system-react'
import { setAppTitle } from '../../helpers/appState'

const pageTitle = 'Portal Login'

const LoginPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const portalName = useSelector(getPortalName)
  const loginError = useSelector(getLoginErrorMessage)
  const loginStatus = useSelector(getLoginStatus)
  const loginLoading = useSelector(getLoginLoadingStatus)

  const passwordLoginStatus = useSelector(getPasswordLoginStatus)

  const [form, setForm] = useState(initialForm)

  const formInputChangedHandler = useCallback((value, name) => {
    setForm(prev => updateForm(prev, name, value))
  }, [])

  const formSubmittedHandler = useCallback(
    async event => {
      event.preventDefault()

      const formData = extractForm(form)
      dispatch(login(formData))
    },
    [form]
  )

  const errorDisplay = loginError ? (
    <p className='slds-text-color_destructive'>{loginError}</p>
  ) : null

  const formInputDisplay = getFormDisplay(form, formInputChangedHandler)

  useEffect(() => {
    setAppTitle(portalName, pageTitle)
  }, [portalName])

  useEffect(() => {
    setForm(prev => clearForm(prev))
  }, [loginStatus])

  useEffect(() => {
    if (passwordLoginStatus) {
      navigate('/mfa', { state: { fromLogin: true } })
    }
  }, [passwordLoginStatus])

  useEffect(() => {
    return () => {
      dispatch(resetAuthState())
    }
  }, [])

  return (
    <>
      {loginLoading ? <Spinner /> : null}
      {errorDisplay}
      <form className='slds-m-top_x-small' onSubmit={formSubmittedHandler}>
        {formInputDisplay}
        <LoginButton
          className='slds-m-top_small'
          type='submit'
          variant='brand'
          label='Login'
        ></LoginButton>
      </form>
      <Divider margin='0.75rem 0' borderThickness={0.1} />
      <Link to='/forgotpassword'>Forgot Your Password?</Link>
    </>
  )
}

export default LoginPanel
