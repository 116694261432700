import { INPUT_TYPE_TEXT } from '../../../constants'

export default {
  name: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: '',
      type: INPUT_TYPE_TEXT,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  bsb: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: '',
      type: INPUT_TYPE_TEXT,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  },
  accountNumber: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: '',
      type: INPUT_TYPE_TEXT,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  }
}
