import { INPUT_TYPE_DROPDOWN, INPUT_TYPE_TEXTAREA } from '../../../constants'

export default {
  outcome: {
    inputType: INPUT_TYPE_DROPDOWN,
    inputProps: {
      label: 'Outcome',
      errorText: null,
      required: true,
      options: [
        { label: 'Approve', id: 'Approve' },
        { label: 'Decline & Close', id: 'Decline & Close' }
      ]
    },
    value: null,
    valid: false
  },
  reasonForDecline: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Reason for Decline',
      type: INPUT_TYPE_TEXTAREA,
      errorText: null,
      required: true,
      multiple: false,
      hidden: true
    },
    value: '',
    valid: false
  }
}
