import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getBankAccountList,
  createBankAccount,
  getBankAccountById,
  patchBankAccountByData
} from '../../api/bankAccount'
import { extractPagination, getAPIRequestError } from '../../helpers/service'
import {
  createReduxRecordListState,
  updateReduxRecords
} from '../../helpers/redux'

const initialState = {
  bankAccountList: createReduxRecordListState(),
  bankAccount: {},
  bankAccountLoading: false,
  createBankAccountResult: null,
  editBankAccountResult: '',
  errorMessage: ''
}

export const fetchBankAccountList = createAsyncThunk(
  'fetchBankAccount',
  async ({ page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getBankAccountList(page, size)

      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data: response.data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchBankAccountById = createAsyncThunk(
  'fetchBankAccountById',
  async (bankAccountId, thunkAPI) => {
    try {
      const response = await getBankAccountById(bankAccountId)
      return response.data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const updateBankAccountByData = createAsyncThunk(
  'updateBankAccountByData',
  async (newData, thunkAPI) => {
    try {
      const data = {
        id: newData.bankAccountId,
        name: newData.data.name,
        bsb: newData.data.bsb,
        accountNumber: newData.data.accountNumber
      }
      const response = await patchBankAccountByData(data)

      return { data: response.data, status: response.status }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const createNewBankAccount = createAsyncThunk(
  'createBankAccount',
  async (data, thunkAPI) => {
    try {
      const payload = { ...data, aggregatorCustomAccount: true }
      const response = await createBankAccount(payload)
      const updatedResponse = {
        ...response,
        data: { ...response.data, ...payload }
      }

      return updatedResponse
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const bankAccount = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    resetBankAccountResult: state => {
      state.createBankAccountResult = initialState.createBankAccountResult
      state.bankAccountList = initialState.bankAccountList
      state.editBankAccountResult = initialState.editBankAccountResult
    }
  },
  extraReducers: builder => {
    // fetch Bank Account List for display on bank account data table
    builder.addCase(fetchBankAccountList.pending, state => {
      state.bankAccountLoading = true
      state.bankAccountList.loading = true
    })
    builder.addCase(fetchBankAccountList.fulfilled, (state, { payload }) => {
      state.bankAccountLoading = false
      // state.bankAccountList = payload.data
      state.bankAccountList.loading = false
      state.bankAccountList.records = updateReduxRecords(
        state.bankAccountList.records,
        payload.data,
        payload.pagination?.page
      )
      state.bankAccountList.page = payload.pagination?.page
      state.bankAccountList.size = payload.pagination?.size
      state.bankAccountList.total = payload.pagination?.total
      state.bankAccountList.totalPages = payload.pagination?.totalPages
      state.bankAccountList.error = null
    })
    builder.addCase(fetchBankAccountList.rejected, (state, { payload }) => {
      state.bankAccountLoading = false
      state.bankAccountList.records = []
      state.bankAccountList.error = payload?.error.message
    })

    //fetch bank account by ID for display on edit modal
    builder.addCase(fetchBankAccountById.pending, state => {
      state.bankAccountLoading = true
    })
    builder.addCase(fetchBankAccountById.fulfilled, (state, { payload }) => {
      state.bankAccountLoading = false
      state.bankAccount = payload
    })
    builder.addCase(fetchBankAccountById.rejected, (state, { payload }) => {
      state.bankAccountLoading = false
      state.errorMessage = payload?.error.message
    })

    //edit bank account by data from edit modal
    builder.addCase(updateBankAccountByData.pending, state => {
      state.bankAccountLoading = true
    })
    builder.addCase(updateBankAccountByData.fulfilled, (state, { payload }) => {
      state.bankAccountLoading = false
      state.editBankAccountResult = payload.status
    })
    builder.addCase(updateBankAccountByData.rejected, (state, { payload }) => {
      state.bankAccountLoading = false
      state.editBankAccountResult = payload?.error.message
    })

    //create bank account by data from modal
    builder.addCase(createNewBankAccount.pending, state => {
      state.bankAccountLoading = true
    })
    builder.addCase(createNewBankAccount.fulfilled, (state, { payload }) => {
      state.bankAccountLoading = false
      state.createBankAccountResult = payload
    })
    builder.addCase(createNewBankAccount.rejected, (state, { payload }) => {
      state.bankAccountLoading = false
      state.createBankAccountResult = payload?.error.message
    })
  }
})

export const { resetBankAccountResult } = bankAccount.actions

export default bankAccount.reducer

export const getBankAccountListData = createSelector(
  state => state.bankAccount.bankAccountList,
  bankAccountList => bankAccountList
)

export const getBankAccountLoading = createSelector(
  state => state.bankAccount.bankAccountLoading,
  bankAccountLoading => bankAccountLoading
)

export const getCreateBankAccountResult = createSelector(
  state => state.bankAccount.createBankAccountResult,
  createBankAccountResult => createBankAccountResult
)

export const getBankAccountDetail = createSelector(
  state => state.bankAccount.bankAccount,
  bankAccount => bankAccount
)

export const getEditBankAccountResult = createSelector(
  state => state.bankAccount.editBankAccountResult,
  editBankAccountResult => editBankAccountResult
)
