import React from 'react'
import SubmitJobFileContainer from '../../containers/SubmitJobFileContainer/SubmitJobFileContainer'
import {
  SubmitJobFileContentWrapper,
  SubmitJobFilePageWrapper
} from './StyledSubmitJobFilePage'

const SubmitJobFilePage = () => {
  return (
    <SubmitJobFilePageWrapper>
      <SubmitJobFileContentWrapper>
        <SubmitJobFileContainer />
      </SubmitJobFileContentWrapper>
    </SubmitJobFilePageWrapper>
  )
}

export default SubmitJobFilePage
