import dayjs from 'dayjs'

import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import objectSupport from 'dayjs/plugin/objectSupport'

import 'dayjs/locale/en-au'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIME_FORMAT
} from '../constants'
import { isEmpty, toNumber } from 'lodash'

export const setupDayjs = () => {
  dayjs.locale('en-au')
  dayjs.extend(localeData)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(advancedFormat)
  dayjs.extend(objectSupport)
}

export const getDateFormat = (dateObject, withTime = true, applyTimezoneOnDate = false) => {
  if (!dateObject) return null

  let dayJSObject = null

  if (withTime || applyTimezoneOnDate) {
    dayJSObject = dayjs(dateObject).tz()
  } else {
    dayJSObject = dayjs(dateObject).utc(true)
  }

  const dateFormat = withTime ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT

  if (dayJSObject.isValid()) {
    return dayJSObject.format(dateFormat)
  }
  return null
}

export const extractTime = timeString => {
  if (isEmpty(timeString)) return null

  const [hour, minute, second] = timeString.split(':')

  return {
    hour: toNumber(hour),
    minute: toNumber(minute),
    second: toNumber(second)
  }
}

export const getTimeObjectFromTimeString = timeString => {
  if (isEmpty(timeString)) return null

  return dayjs(extractTime(timeString)).utc(true).tz()
}

export const getTimeFormat = dateObject => {
  if (!dateObject) return null

  const dayJSObject = dayjs(dateObject).tz()
  const dateFormat = DEFAULT_TIME_FORMAT

  if (dayJSObject.isValid()) {
    return dayJSObject.format(dateFormat)
  }
  return null
}

export const getMonthNameList = () => {
  return dayjs.months()
}
