import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { getAppSetting } from '../../api/global'
import { getAPIRequestError } from '../../helpers/service'

const initialState = {
  homePageBannerImageUrl: null,
  jobPageBannerImageUrl: null,
  loginPageMainImageUrl: null,
  loginPageContactPhone: null,
  portalLogo: null,
  portalName: null,
  homePageTitle: null,
  homePageSubTitle: null,
  globalLoading: false,
  globalSettingError: null,
  contactUsPageText: null,
  contactUsPagePhone: null,
  contactUsPageEmail: null,
  contactUsPageImageUrl: null,

  defaultQuoteCaseRecordTypeId: null,

  faqPageTitle: null,
  faqBannerImageUrl: null,

  newJobPageSubTitle: null,
  newJobPageTitle: null,

  submitJobFileHelperText: null,
  submitSupportingDocsHelperText: null,

  newQuotePageTitle: null,

  fieldValidator: null
}

export const fetchGlobalSetting = createAsyncThunk(
  'fetchGlobalSetting',
  async (_, thunkAPI) => {
    try {
      const response = await getAppSetting()

      return response
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchGlobalSetting.pending, state => {
      state.globalLoading = true
    })
    builder.addCase(fetchGlobalSetting.fulfilled, (state, { payload }) => {
      state.homePageBannerImageUrl = payload.homePageBannerImageUrl
      state.homePageTitle = payload.homePageTitle
      state.homePageSubTitle = payload.homePageSubTitle
      state.jobPageBannerImageUrl = payload.jobPageBannerImageUrl
      state.loginPageMainImageUrl = payload.loginPageMainImageUrl
      state.loginPageContactPhone = payload.loginPageContactPhone
      state.portalLogo = payload.portalLogo
      state.portalName = payload.portalName
      state.contactUsPageText = payload.contactUsPageText
      state.contactUsPagePhone = payload.contactUsPagePhone
      state.contactUsPageEmail = payload.contactUsPageEmail
      state.contactUsPageImageUrl = payload.contactUsPageImageUrl
      state.faqPageTitle = payload.faqPageTitle
      state.faqBannerImageUrl = payload.faqBannerImageUrl

      state.defaultQuoteCaseRecordTypeId = payload.defaultQuoteCaseRecordTypeId

      state.newJobPageSubTitle = payload.newJobPageSubTitle
      state.newJobPageTitle = payload.newJobPageTitle

      state.submitJobFileHelperText = payload.submitJobFileHelperText
      state.submitSupportingDocsHelperText =
        payload.submitSupportingDocsHelperText

      state.newQuotePageTitle = payload.newQuotePageTitle

      state.globalLoading = false
      state.globalSettingError = null
      try {
        state.fieldValidator = JSON.parse(payload.fieldValidation)
      } catch (error) {
        // console.log(error)
        state.fieldValidator = null
      }
    })
    builder.addCase(fetchGlobalSetting.rejected, (state, { payload }) => {
      state = { ...initialState }
      state.globalLoading = false
      state.globalSettingError = payload?.error.message
    })
  }
})

export default globalSlice.reducer

export const getHomeBannerUrl = createSelector(
  state => state.global.homePageBannerImageUrl,
  homePageBannerImageUrl => homePageBannerImageUrl
)

export const getHomePageTitle = createSelector(
  state => state.global.homePageTitle,
  homePageTitle => homePageTitle
)

export const getHomePageSubTitle = createSelector(
  state => state.global.homePageSubTitle,
  homePageSubTitle => homePageSubTitle
)

export const getJobPageBannerUrl = createSelector(
  state => state.global.jobPageBannerImageUrl,
  jobPageBannerImageUrl => jobPageBannerImageUrl
)

export const getLoginPageMainUrl = createSelector(
  state => state.global.loginPageMainImageUrl,
  loginPageMainImageUrl => loginPageMainImageUrl
)

export const getLoginContactPhone = createSelector(
  state => state.global.loginPageContactPhone,
  loginPageContactPhone => loginPageContactPhone
)

export const getPortalLogoUrl = createSelector(
  state => state.global.portalLogo,
  portalLogo => portalLogo
)

export const getPortalName = createSelector(
  state => state.global.portalName,
  portalName => portalName
)

export const getGlobalSettingLoadingStatus = createSelector(
  state => state.global.globalLoading,
  globalLoading => globalLoading
)

export const getGlobalSettingErrorMessage = createSelector(
  state => state.global.globalSettingError,
  globalSettingError => globalSettingError
)

export const getContactUsPageText = createSelector(
  state => state.global.contactUsPageText,
  contactUsPageText => contactUsPageText
)

export const getContactUsPagePhone = createSelector(
  state => state.global.contactUsPagePhone,
  contactUsPagePhone => contactUsPagePhone
)

export const getContactUsPageEmail = createSelector(
  state => state.global.contactUsPageEmail,
  contactUsPageEmail => contactUsPageEmail
)

export const getContactUsPageImageUrl = createSelector(
  state => state.global.contactUsPageImageUrl,
  contactUsPageImageUrl => contactUsPageImageUrl
)

export const getFaqPageTitle = createSelector(
  state => state.global.faqPageTitle,
  faqPageTitle => faqPageTitle
)

export const getFaqBannerImageUrl = createSelector(
  state => state.global.faqBannerImageUrl,
  faqBannerImageUrl => faqBannerImageUrl
)

export const getDefaultQuoteCaseRecordTypeId = createSelector(
  state => state.global.defaultQuoteCaseRecordTypeId,
  defaultQuoteCaseRecordTypeId => defaultQuoteCaseRecordTypeId
)

export const getNewJobPageSubTitle = createSelector(
  state => state.global.newJobPageSubTitle,
  newJobPageSubTitle => newJobPageSubTitle
)

export const getNewJobPageTitle = createSelector(
  state => state.global.newJobPageTitle,
  newJobPageTitle => newJobPageTitle
)

export const getSubmitJobFileHelperText = createSelector(
  state => state.global.submitJobFileHelperText,
  submitJobFileHelperText => submitJobFileHelperText
)

export const getSubmitSupportingDocsHelperText = createSelector(
  state => state.global.submitSupportingDocsHelperText,
  submitSupportingDocsHelperText => submitSupportingDocsHelperText
)

export const getNewQuotePageTitle = createSelector(
  state => state.global.newQuotePageTitle,
  newQuotePageTitle => newQuotePageTitle
)

export const getFieldValidator = createSelector(
  state => state.global.fieldValidator,
  fieldValidator => fieldValidator
)
