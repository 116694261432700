import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import jobRecordForm from './jobRecordDetailForm'
import { updateForm } from '../../../helpers/form'
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen'

import { useNavigate } from 'react-router-dom'
import {
  JOB_APPROVAL_SUB_TYPE_CHANGE_ADDRESS,
  PAGE_SIZE
} from '../../../constants'
import {
  JobHoldRequestModal,
  JobRecordAdditionalData,
  JobRecordForm,
  JobRecordPageHeader,
  JobStatusCloseModal,
  JobActionUpdateModal,
  JobUploadFileModal,
  JobCostApproveModal,
  JobHoldReleaseModal
} from '../../../components'
// import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { AddNewPaymentModal } from '../../AddNewPaymentModal'

import { useDispatch, useSelector } from 'react-redux'
import {
  getJobPageLoading,
  getJobDetailData,
  fetchJobDetailById,
  fetchJobCommentsListById,
  getCommentsListData,
  addJobComment,
  getAddJobCommentResult,
  fetchJobFileListById,
  getJobFilesListData,
  resetJobCommentList,
  getJobFileDownloadResultError,
  addNewPaymentByData,
  getAddNewPaymentResult,
  resetJobFileDownlaodError,
  resetAddNewPaymentResult,
  fetchPaymentsListById,
  resetJobData,
  uploadFileToJob,
  getFileUploadRequestResult,
  resetFileUploadRequestResult,
  resetActionUpdateJobRequestResult,
  getActionUpdateJobRequestResult,
  requestActionUpdateJob,
  costApproveAction,
  getCostApproveRequestResult,
  resetCostApproveRequestResult,
  newAddressApproveAction,
  getNewAddressApproveRequestResult,
  resetNewAddressApproveRequestResult,
  getJobFileUploadResult,
  resetJobFileUploadStatus,
  uploadJobFile,
  updateJobClientUploadFile,
  getJobUpdateResult,
  resetJobUpdateClientUploadFileStatus
} from '../../../redux/slices/job'

import useAlert from '../../../hooks/useAlert'

import { createErrorAlert, createSuccessAlert } from '../../../helpers/alert'
import { buildFilePayload } from '../../../helpers/file'

import { Spinner } from '@salesforce/design-system-react'
import { getPortalName } from '../../../redux/slices/global'
import { setAppTitle } from '../../../helpers/appState'
import { JobUpgradeRequestModal } from '../../../components/JobDetail/JobUpgradeModal'
import { fileMaxSize } from '../../../constants/file'

const JobDetailContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fireAlert = useAlert()
  const { jobId } = useParams()

  const portalName = useSelector(getPortalName)
  const jobDetail = useSelector(getJobDetailData)
  const jobPageLoading = useSelector(getJobPageLoading)
  const jobFileDownloadResultError = useSelector(getJobFileDownloadResultError)
  const addNewPaymentResult = useSelector(getAddNewPaymentResult)
  const uploadFileResult = useSelector(getFileUploadRequestResult)
  const actionUpdateJobRequestResult = useSelector(
    getActionUpdateJobRequestResult
  )
  const costApproveRequestResult = useSelector(getCostApproveRequestResult)
  const addressChangeApproveRequestResult = useSelector(
    getNewAddressApproveRequestResult
  )
  const jobFileUploadResult = useSelector(getJobFileUploadResult)
  const jobUpdateResult = useSelector(getJobUpdateResult)

  const isMobile = useCheckMobileScreen()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [form, setForm] = useState(jobRecordForm)

  const commentLists = useSelector(getCommentsListData)
  const addJobCommentResult = useSelector(getAddJobCommentResult)

  const jobFilesList = useSelector(getJobFilesListData)

  const [updateCloseJobModalOpen, setUpdateCloseJobModalOpen] = useState(false)

  const [holdJobModalOpen, setHoldJobModalOpen] = useState(false)

  const [unholdJobModalOpen, setUnholdJobModalOpen] = useState(false)

  const [upgradeJobModalOpen, setUpgradeJobModalOpen] = useState(false)

  const [costApproveModalOpen, setCostApproveModalOpen] = useState(false)

  const [updateActionJobModalOpen, setupdateActionJobModalOpen] =
    useState(false)

  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false)

  const [actionUpdateData, setActionUpdateData] = useState({})

  const [jobFileUploadModalOpen, setJobFileUploadModalOpen] = useState(false)

  const onCloseUpdateCloseJobModal = useCallback(() => {
    setUpdateCloseJobModalOpen(false)
  })
  const onUpdateCloseJobModalHandler = useCallback(() => {
    setUpdateCloseJobModalOpen(true)
  })

  const onCloseHoldJobModal = useCallback(() => {
    setHoldJobModalOpen(false)
  })
  const onHoldJobOpenHandler = useCallback(() => {
    setHoldJobModalOpen(true)
  })

  const onCloseUnholdJobModal = useCallback(() => {
    setUnholdJobModalOpen(false)
  })
  const onUnholdJobOpenHandler = useCallback(() => {
    setUnholdJobModalOpen(true)
  })

  const onUpgradeJobModalClosedHandler = useCallback(() => {
    setUpgradeJobModalOpen(false)
  })
  const onUpgradeJobModalOpenHandler = useCallback(() => {
    setUpgradeJobModalOpen(true)
  })

  const onUpdateActionJobModalClosedHandler = useCallback(() => {
    setupdateActionJobModalOpen(false)
  })

  const onUpdateActionJobModalOpenHandler = useCallback(() => {
    setupdateActionJobModalOpen(true)
  })

  const onCostApproveModalClosedHandler = useCallback(() => {
    setCostApproveModalOpen(false)
  })
  const onCostApproveModalOpenHandler = useCallback(() => {
    setCostApproveModalOpen(true)
  })

  const onCostApproveSubmitted = useCallback(
    data => {
      if (data.approvalSubType === JOB_APPROVAL_SUB_TYPE_CHANGE_ADDRESS) {
        dispatch(newAddressApproveAction({ jobId, data }))
      } else {
        dispatch(costApproveAction({ jobId, data }))
      }
    },
    [jobId]
  )

  const onActionUpdateJobSubmitted = useCallback(
    data => {
      const updateType = jobDetail?.record?.clientUpdateType
      const actionUpdatePayload = {
        clientUpdateOutcome: data?.outcome,
        remainOnHold: data?.remainOnHold,
        clientUpdateType: updateType
      }
      setActionUpdateData(data)
      if (data.outcome === 'Not Confirmed') {
        dispatch(requestActionUpdateJob({ jobId, data: actionUpdatePayload }))
        onUpdateActionJobModalClosedHandler()
      } else {
        if (updateType === 'Payment Confirmation') {
          onAddPaymentClicked()
          setAddPaymentModalOpen(true)
        } else if (updateType === 'File Upload') {
          onJobFileUploadModalOpenHandler()
        } else if (
          updateType === 'Other' ||
          updateType === 'Customer Contact (Hardship, etc.)'
        ) {
          dispatch(requestActionUpdateJob({ jobId, data: actionUpdatePayload }))
        } else {
          onUpdateActionJobModalClosedHandler()
        }
      }
    },
    [actionUpdateData, jobDetail]
  )

  const onJobFileUploadModalOpenHandler = useCallback(() => {
    setJobFileUploadModalOpen(true)
  })
  const onJobFileUploadModalCloseHandler = useCallback(() => {
    setJobFileUploadModalOpen(false)
  })
  const onUploadFileAction = useCallback(
    async data => {
      const fileBuilded = await buildFilePayload(
        data.jobInstructionFile[0],
        jobId,
        null
      )
      const uploadFiledata = {
        jobId: jobId,
        file: fileBuilded
      }
      dispatch(uploadFileToJob(uploadFiledata))
    },
    [jobId]
  )

  const onInputChangedHandler = useCallback((value, name) => {
    setForm(prev => updateForm(prev, name, value))
  }, [])

  const onLoadMoreFileListHandler = useCallback(() => {
    // setFileList(prev => [...prev, ...generateFileList(5, prev.length)])
  }, [])

  const onFileChangedHandler = useCallback(
    async files => {
      const file = files?.[0]

      if (!file) {
        return
      }

      if (file?.size > fileMaxSize) {
        fireAlert(
          createErrorAlert(
            `File Size Limit Exceeded (Max: ${fileMaxSize / 1024 / 1024} MB)`
          ),
          3000
        )
      } else {
        const filePayload = await buildFilePayload(file, jobId)
        const uploadFiledata = {
          jobId: jobId,
          file: filePayload
        }

        dispatch(uploadJobFile(uploadFiledata))
      }
    },
    [jobId]
  )

  const onTabChangedHandler = useCallback(index => {
    setCurrentTabIndex(index)
  }, [])

  const onCommentSubmittedHandler = useCallback(newComment => {
    if (isEmpty(newComment)) return null
    dispatch(addJobComment({ jobId, comment: newComment }))
  }, [])

  const onLoadMoreCommentsClickedHandler = useCallback(() => {
    dispatch(
      fetchJobCommentsListById({
        jobId,
        page: commentLists.page + 1,
        size: PAGE_SIZE
      })
    )
  }, [commentLists])

  const onAddPaymentClicked = useCallback(() => {
    setAddPaymentModalOpen(true)
  }, [])

  const onAddPaymentSubmitted = useCallback(data => {
    dispatch(addNewPaymentByData(data))
  }, [])

  const onAddPaymentClosed = useCallback(() => {
    setAddPaymentModalOpen(false)
  }, [])

  useEffect(() => {
    setForm(prev => {
      if (!jobDetail.record) return prev

      const mappedForm = Object.entries(prev).reduce((mapped, formItem) => {
        const [formKey] = formItem

        return {
          ...mapped,
          ...updateForm(prev, formKey, jobDetail?.record?.[formKey])
        }
      }, {})

      return mappedForm
    })
  }, [jobId, jobDetail.record])

  useEffect(() => {
    if (addJobCommentResult !== '') {
      if (addJobCommentResult === 201) {
        setTimeout(() => {
          dispatch(resetJobCommentList())
          dispatch(
            fetchJobCommentsListById({ jobId, page: 1, size: PAGE_SIZE })
          )
        }, 1000)
      }
    }
  }, [addJobCommentResult])

  useEffect(() => {
    dispatch(fetchJobDetailById({ jobId }))
  }, [jobId])

  useEffect(() => {
    if (jobFileDownloadResultError) {
      if (jobFileDownloadResultError?.length && jobFileDownloadResultError?.map) {
        jobFileDownloadResultError.map(item => {
          fireAlert(createErrorAlert(item.message, 3000))
        })
      } else {
        fireAlert(createErrorAlert(jobFileDownloadResultError.message, 3000))
      }
      dispatch(resetJobFileDownlaodError())
    }
  }, [jobFileDownloadResultError])

  useEffect(() => {
    if (jobDetail.statusCode === 200) {
      dispatch(fetchJobCommentsListById({ jobId, page: 1, size: PAGE_SIZE }))
      dispatch(fetchJobFileListById(jobId))

      setAppTitle(portalName, `Job ${jobDetail.record?.jobNo ?? ''}`)
    } else if (jobDetail.statusCode === 404 || jobDetail.statusCode === 500) {
      navigate('/notFound')
    }
  }, [jobDetail.statusCode])

  useEffect(() => {
    if (addNewPaymentResult.statusCode !== null) {
      if (addNewPaymentResult.statusCode === 200) {
        fireAlert(createSuccessAlert('New Payment has been added.', 3000))
        if (updateActionJobModalOpen === true) {
          const actionUpdatePayload = {
            clientUpdateOutcome: actionUpdateData?.outcome,
            remainOnHold: actionUpdateData?.remainOnHold,
            clientUpdateType: jobDetail?.record?.clientUpdateType
          }
          dispatch(requestActionUpdateJob({ jobId, data: actionUpdatePayload }))
        }
      } else {
        fireAlert(createErrorAlert(addNewPaymentResult.error, 3000))
      }
      setAddPaymentModalOpen(false)
      dispatch(resetAddNewPaymentResult())
      dispatch(fetchPaymentsListById({ jobId, page: 1, size: PAGE_SIZE }))
    }
  }, [actionUpdateData, addNewPaymentResult, jobId])

  useEffect(() => {
    if (uploadFileResult.statusCode !== null) {
      if (uploadFileResult.statusCode === 200) {
        const actionUpdatePayload = {
          clientUpdateOutcome: actionUpdateData?.outcome,
          remainOnHold: actionUpdateData?.remainOnHold,
          clientUpdateType: null,
          typeOfDocument: null
        }
        dispatch(requestActionUpdateJob({ jobId, data: actionUpdatePayload }))
        dispatch(fetchJobFileListById(jobId))
        onJobFileUploadModalCloseHandler()
      } else {
        onJobFileUploadModalCloseHandler()
        onUpdateActionJobModalClosedHandler()
        fireAlert(
          createErrorAlert('File upload failed, Please try again.', 3000)
        )
      }
      dispatch(resetFileUploadRequestResult())
    }
  }, [jobId, actionUpdateData, uploadFileResult])

  useEffect(() => {
    if (actionUpdateJobRequestResult.statusCode !== null) {
      if (actionUpdateJobRequestResult.statusCode === 200) {
        fireAlert(
          createSuccessAlert(
            'Your action update request to this job has been succesfully sent.',
            3000
          )
        )
        dispatch(
          addJobComment({ jobId, comment: actionUpdateData.notedForJobFile })
        )
        onUpdateActionJobModalClosedHandler()
      } else {
        fireAlert(createErrorAlert(actionUpdateJobRequestResult.error, 3000))
      }
      dispatch(resetActionUpdateJobRequestResult())
    }
  }, [actionUpdateData, actionUpdateJobRequestResult])

  useEffect(() => {
    if (costApproveRequestResult.statusCode !== null) {
      if (costApproveRequestResult.statusCode === 200) {
        fireAlert(
          createSuccessAlert(
            'Your cost approval of this job has been succesfully sent.',
            3000
          )
        )
        onCostApproveModalClosedHandler()
      } else {
        fireAlert(createErrorAlert(costApproveRequestResult.error, 3000))
      }
      dispatch(resetCostApproveRequestResult())
    }
  }, [costApproveRequestResult])

  useEffect(() => {
    if (addressChangeApproveRequestResult.statusCode !== null) {
      if (addressChangeApproveRequestResult.statusCode === 200) {
        fireAlert(
          createSuccessAlert(
            'Your cost approval of this job has been succesfully sent.',
            3000
          )
        )
        onCostApproveModalClosedHandler()
      } else {
        fireAlert(
          createErrorAlert(addressChangeApproveRequestResult.error, 3000)
        )
      }
      dispatch(resetNewAddressApproveRequestResult())
    }
  }, [addressChangeApproveRequestResult])

  useEffect(() => {
    if (jobFileUploadResult.statusCode !== null) {
      if (jobFileUploadResult.statusCode === 200) {
        fireAlert(
          createSuccessAlert('The file is successfully uploaded.', 3000)
        )
        dispatch(updateJobClientUploadFile({jobId: jobId, clientUploadFile: true}))
        dispatch(fetchJobFileListById(jobId))
      } else {
        fireAlert(createErrorAlert(jobFileUploadResult.error, 3000))
      }
      dispatch(resetJobFileUploadStatus())
    }
  }, [jobId, jobFileUploadResult])

  useEffect(() => {
    if(jobUpdateResult.statusCode !== null) {
      if(jobUpdateResult.statusCode !== 200) {
        fireAlert(createErrorAlert(jobUpdateResult.error, 3000))
      }
      dispatch(resetJobUpdateClientUploadFileStatus())
    }
  }, [jobUpdateResult])

  useEffect(() => {
    return () => dispatch(resetJobData())
  }, [])

  return (
    <>
      <JobCostApproveModal
        isOpen={costApproveModalOpen}
        modalTitle={'Cost Approval'}
        jobId={jobId}
        jobData={jobDetail?.record ?? {}}
        onClose={onCostApproveModalClosedHandler}
        onSaveActionUpdate={onCostApproveSubmitted}
      />
      <JobActionUpdateModal
        isOpen={updateActionJobModalOpen}
        modalTitle={jobDetail?.record?.clientUpdateType}
        jobId={jobId}
        onClose={onUpdateActionJobModalClosedHandler}
        onSaveActionUpdate={onActionUpdateJobSubmitted}
        jobDocumentType={jobDetail?.record?.typeOfDocument}
      />
      <JobUploadFileModal
        isOpen={jobFileUploadModalOpen}
        modalTitle={'Upload File to Job'}
        jobId={jobId}
        onClose={onJobFileUploadModalCloseHandler}
        onUploadFileAction={onUploadFileAction}
      />
      <JobStatusCloseModal
        isOpen={updateCloseJobModalOpen}
        modalTitle={'Close Job'}
        jobId={jobId}
        onClose={onCloseUpdateCloseJobModal}
      />
      <JobHoldRequestModal
        isOpen={holdJobModalOpen}
        modalTitle={'Hold Job'}
        jobId={jobId}
        onClose={onCloseHoldJobModal}
      />
      <JobHoldReleaseModal
        isOpen={unholdJobModalOpen}
        modalTitle={'Release Job'}
        jobId={jobId}
        onClose={onCloseUnholdJobModal}
      />
      <JobUpgradeRequestModal
        isOpen={upgradeJobModalOpen}
        modalTitle={jobDetail?.record?.status}
        jobId={jobId}
        onClose={onUpgradeJobModalClosedHandler}
        costForSuccessful={jobDetail?.record?.costForSuccessful}
        quoteUpgradeCostApproval={jobDetail?.record?.quoteUpgradeCostApproval}
      />
      <AddNewPaymentModal
        isOpen={addPaymentModalOpen}
        modalTitle={'Add a new payment'}
        parentJobData={jobDetail?.record}
        onClose={onAddPaymentClosed}
        onSubmitted={onAddPaymentSubmitted}
        isActionUpdate={updateActionJobModalOpen}
      />
      <JobRecordPageHeader
        jobName={jobDetail?.record?.customerName}
        jobStatus={jobDetail?.record?.status}
        closeJobModalHandler={onUpdateCloseJobModalHandler}
        holdJobModalHandler={onHoldJobOpenHandler}
        unholdJobModalHandler={onUnholdJobOpenHandler}
        upgradeJobModalHandler={onUpgradeJobModalOpenHandler}
        actionUpdateModalHandler={onUpdateActionJobModalOpenHandler}
        approveCostModalHandler={onCostApproveModalOpenHandler}
      />
      <div className='slds-grid slds-wrap slds-gutters'>
        <JobRecordForm form={form} onInputChanged={onInputChangedHandler} />
        <JobRecordAdditionalData
          currentTabIndex={currentTabIndex}
          onTabChanged={onTabChangedHandler}
          isMobile={isMobile}
          files={jobFilesList.records ?? []}
          jobId={jobId}
          comments={commentLists.records ?? []}
          commentTotals={commentLists.total}
          onCommentSubmitted={onCommentSubmittedHandler}
          onLoadMoreCommentsClicked={onLoadMoreCommentsClickedHandler}
          onLoadMoreFileList={onLoadMoreFileListHandler}
          onAddPaymentClicked={onAddPaymentClicked}
          onFileChanged={onFileChangedHandler}
        />
      </div>

      {jobPageLoading ? <Spinner /> : null}
    </>
  )
}

export default JobDetailContent
