import {
  AdminPage,
  CreateQuotePage,
  ForgotPasswordPage,
  HomePage,
  JobDetailPage,
  LoginPage,
  ResetPasswordPage,
  ResetPasswordExpiredLinkPage,
  PasswordChangedSuccessPage,
  FAQPage,
  ContactUsPage,
  SubmitJobFilePage,
  InvoiceListPage,
  MultiFactorAuthPage
  // MyAccountPage,
  // UserRecordPage
} from '../pages'
import PromiseToPayListPage from '../pages/PromiseToPayListPage/PromiseToPayListPage'

export const unauthorizedOnlyPages = [
  { pathname: '/login', component: <LoginPage /> }
]

export const privateRoutes = [
  { pathname: '/jobs/:jobId', component: <JobDetailPage /> },
  { pathname: '/promise_to_pays', component: <PromiseToPayListPage /> },
  { pathname: '/quotes/submit', component: <SubmitJobFilePage /> },
  { pathname: '/quotes/new', component: <CreateQuotePage /> },
  { pathname: '/quotes/:id', component: <CreateQuotePage /> },
  // { pathname: '/users/:id', component: <UserRecordPage /> },
  // { pathname: '/my-account', component: <MyAccountPage /> },
  { pathname: '/faq', component: <FAQPage /> },
  { pathname: '/contact', component: <ContactUsPage /> },
  { pathname: '/', component: <HomePage />, index: true }
]

export const adminOnlyRoutes = [
  { pathname: '/admin', component: <AdminPage />, onlyAdmin: true },
  { pathname: '/invoices', component: <InvoiceListPage />, onlyAdmin: true }
]

export const publicRoutes = [
  { pathname: '/forgotpassword', component: <ForgotPasswordPage /> },
  { pathname: '/resetpassword', component: <ResetPasswordPage /> },
  { pathname: '/welcome', component: <ResetPasswordPage /> },
  {
    pathname: '/reset_link_expired',
    component: <ResetPasswordExpiredLinkPage />
  },
  {
    pathname: '/reset_password_success',
    component: <PasswordChangedSuccessPage />
  },
  {
    pathname: '/mfa',
    component: <MultiFactorAuthPage />
  }
]

export const navigationItems = [
  { pathname: '/', label: 'Home' },
  // { pathname: '/quotes', label: 'Quotes' },
  // { pathname: '/my-account', label: 'My Account' },
  { pathname: '/contact', label: 'Contact Us' },
  { pathname: '/faq', label: 'FAQ' },
  { pathname: '/promise_to_pays', label: 'Promise to Pay' }
]

export const additionalNavItems = [
  {
    pathname: '/admin',
    label: 'Admin',
    iconName: 'setup',
    iconCategory: 'utility',
    iconSize: 'x-small'
  },
  {
    pathname: '/invoices',
    label: 'Invoices',
    iconName: 'form',
    iconCategory: 'utility',
    iconSize: 'x-small'
  }
]
