import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@salesforce/design-system-react'

const QuoteActions = ({ onAccepted, onDeclined, onHolded }) => {
  return (
    <div className='slds-grid slds-wrap slds-m-top_medium'>
      <Button
        className='slds-col slds-size_1-of-1'
        label='Accept Quote & Upload Details'
        variant='brand'
        onClick={onAccepted}
      />
      <Button
        className='slds-col slds-m-left_none slds-size_1-of-1 slds-m-top_x-small'
        label='Decline Quote'
        onClick={onDeclined}
      />
      <Button
        className='slds-col slds-m-left_none slds-size_1-of-1 slds-m-top_x-small'
        label='Hold Quote'
        onClick={onHolded}
      />
    </div>
  )
}

QuoteActions.propTypes = {
  onAccepted: PropTypes.func,
  onDeclined: PropTypes.func,
  onHolded: PropTypes.func
}

export default memo(QuoteActions)
