import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { INPUT_TYPE_TEXT } from '../../../constants'

import { StandardInput } from '../../common'
import {
  LoginUnorderedList,
  LoginButton
} from '../../../pages/LoginPage/StyledLoginItems'
import { QRCodeSVG } from 'qrcode.react'

const MultiFactorAuthForm = ({
  isSetup,
  qrValue,
  contactPhone,

  onInputChanged,
  onCancelClicked,
  onSubmitted,

  otp,
  errorText
}) => {
  const otpValid = otp?.length === 6

  const instructionDisplay = isSetup ? (
    <>
      <LoginUnorderedList className='slds-list_dotted slds-m-vertical_x_small'>
        <li>
          <span>
            <a href='https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid'>Install Google Authenticator</a>
          </span>
          <span> on your phone</span>
        </li>
        <li>Open the Google Authenticator app.</li>
        <li>
          Tap menu, then tap &apos;Set up Account&apos;, then tap &apos;Scan a
          barcode&apos;.
        </li>
        <li>
          Your phone will now be in a &apos;scanning&apos; mode. When you are in
          this mode, scan the barcode below:
        </li>
      </LoginUnorderedList>
      <div className='slds-m-around_large slds-text-align_center'>
        <QRCodeSVG value={qrValue} />
      </div>
      <p className='slds-m-around_x-small'>
        Once you have scanned the barcode, enter the 6-digit code below:
      </p>
    </>
  ) : (
    <p className='slds-m-around_x-small'>
      Enter the 6-digit verification code below:
    </p>
  )

  const helpTextDisplay = !isSetup ? (
    <p className='slds-m-around_x-small'>
      Having trouble or lost your authentication device? Call us on {contactPhone}
    </p>
  ) : null

  return (
    <>
      {instructionDisplay}
      <form className='slds-m-around_x-small' onSubmit={onSubmitted}>
        <StandardInput
          name='otp'
          styleInput={{ height: '2.75rem' }}
          type={INPUT_TYPE_TEXT}
          value={otp ?? ''}
          required
          label='6-Digit Verification Code'
          onChange={onInputChanged}
          errorText={errorText}
        />
        <div className='slds-grid slds-m-top_small'>
          <LoginButton type='button' label='Cancel' onClick={onCancelClicked} />
          <LoginButton
            type='submit'
            variant='brand'
            label='Submit'
            disabled={!otpValid}
          />
        </div>
      </form>
      {helpTextDisplay}
    </>
  )
}

MultiFactorAuthForm.propTypes = {
  isSetup: PropTypes.bool,
  qrValue: PropTypes.string,
  otp: PropTypes.string,
  contactPhone: PropTypes.string,
  errorText: PropTypes.string,
  onSubmitted: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired
}

export default memo(MultiFactorAuthForm)
