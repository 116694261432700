import {
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_TEXT
} from '../../../constants'

export default {
  costToDateExtraCharges: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Costs to Date',
      type: INPUT_TYPE_TEXT,
      readOnly: true
    },
    value: '',
    valid: true
  },
  costOfNextAction: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Costs of Next Action',
      type: INPUT_TYPE_TEXT,
      readOnly: true,
      fixedTextLeft: '$'
    },
    value: '',
    valid: true
  },
  costApprovalOutcome: {
    inputType: INPUT_TYPE_DROPDOWN,
    inputProps: {
      label: 'Outcome',
      errorText: null,
      required: true,
      options: [
        { label: 'Approved', id: 'Approved' },
        { label: 'Declined', id: 'Declined' }
      ]
    },
    value: null,
    valid: false
  },
}
