import { INPUT_TYPE_TEXTAREA } from '../../../constants'

export default {
  comment: {
    inputType: INPUT_TYPE_TEXTAREA,
    inputProps: {
      label: 'Reason for closing Job',
      type: INPUT_TYPE_TEXTAREA,
      errorText: null,
      required: true
    },
    value: '',
    valid: false
  }
}
