import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '@salesforce/design-system-react'
import { getFormDisplay } from '../../../helpers/form'

function JobRecordForm({ form, onInputChanged }) {
  const formDisplay = getFormDisplay(form, onInputChanged)

  return (
    <div className='slds-col slds-size_1-of-1 slds-large-size_4-of-12'>
      <Card heading={''} className='slds-p-horizontal_medium'>
        {formDisplay}
      </Card>
    </div>
  )
}

JobRecordForm.propTypes = {
  form: PropTypes.object,
  onInputChanged: PropTypes.func
}

export default JobRecordForm
