import { performPostRequest } from '../helpers/service'

export const loginByUsernamePassword = async (username, password) => {
  const response = await performPostRequest('auth/login', {
    username,
    password
  })
  return response.data
}

export const verifyMFAPassword = async otp => {
  const response = await performPostRequest('2fa/authentication', {
    code: otp
  })
  return response.data
}

export const getAuthenticatorLink = async () => {
  const response = await performPostRequest('2fa/request-link')
  return response.data
}

export const requestResetPasswordWithEmail = async username => {
  const response = await performPostRequest('/auth/request-reset-password', {
    username
  })

  return response.data
}

export const validateResetPasswordToken = async token => {
  const response = await performPostRequest(
    '/user/validate-reset-password-token',
    {
      token
    }
  )

  return response.data
}

export const resetPasswordWithNewPassword = async (token, password) => {
  const response = await performPostRequest('/user/reset-password', {
    token,
    password
  })

  return response.data
}

export const logoutFromPortal = async () => {
  const response = await performPostRequest('/auth/logout')

  return response.data
}