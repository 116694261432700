import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { createReduxRecordListState } from '../../helpers/redux'
import { getAPIRequestError } from '../../helpers/service'
import { getFaqs } from '../../api/faq'

const initialState = {
  faqs: createReduxRecordListState()
}

export const fetchFAQs = createAsyncThunk('fetchFAQs', async (_, thunkAPI) => {
  try {
    const response = await getFaqs()

    return response.data
  } catch (error) {
    const errorData = getAPIRequestError(error)

    // console.log('errorData', errorData)

    return thunkAPI.rejectWithValue({ error: errorData })
  }
})

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    resetFaqPage: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchFAQs.pending, state => {
      state.faqs.loading = true
    })
    builder.addCase(fetchFAQs.fulfilled, (state, { payload }) => {
      state.faqs.loading = false
      state.faqs.records = payload
    })
    builder.addCase(fetchFAQs.rejected, (state, { payload }) => {
      state.faqs.loading = false
      state.faqs.records = []
      state.faqs.error = payload?.error
    })
  }
})

export default faqSlice.reducer

export const { resetFaqPage } = faqSlice.actions

export const getFaqList = createSelector(
  state => state.faq.faqs,
  faqs => faqs
)
