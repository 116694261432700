import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { checkCSSPercentValue } from '../../../helpers/style'

import placeHolderImg from '../../../assets/images/placeholder-image.png'
import { Spinner } from '@salesforce/design-system-react'

const ImageContainer = styled.div`
  position: relative;
  ${props =>
    `width: ${
      props.width ? checkCSSPercentValue(props.width, props.cssUnit) + ';' : ''
    }`}
  ${props =>
    `height: ${
      props.height
        ? checkCSSPercentValue(props.height, props.cssUnit) + ';'
        : ''
    }`};
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${props => (props.objectFit ? props.objectFit : 'none')};
  object-position: center;
  border-radius: ${props => (props.isCircle ? '50%' : '')};
`

const Image = ({
  src,
  width,
  height,
  alt,
  objectFit,
  cssUnit,
  isCircle,
  style,
  loading
}) => {
  const imageSrc = src ? src : placeHolderImg

  let display = (
    <StyledImg
      src={imageSrc}
      alt={alt}
      objectFit={objectFit}
      isCircle={isCircle}
    />
  )

  if (loading) {
    display = <Spinner hasContainer />
  }

  return (
    <ImageContainer
      width={width}
      height={height}
      cssUnit={cssUnit}
      style={style}
    >
      {display}
    </ImageContainer>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string.isRequired,
  objectFit: PropTypes.oneOf(['cover', 'contain', 'fill']),
  cssUnit: PropTypes.oneOf(['px', 'rem', 'vh', 'vw', 'em']),
  isCircle: PropTypes.bool,
  style: PropTypes.object,
  loading: PropTypes.bool
}

export default Image
