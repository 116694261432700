import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getAdminDetail,
  getPricingList,
  patchAccountsWithData
} from '../../api/admin'
import {
  createReduxRecordDetailState,
  createReduxRecordListState,
  updateReduxRecords
} from '../../helpers/redux'
import { extractPagination, getAPIRequestError } from '../../helpers/service'
// import { getTimeObjectFromTimeString } from '../../helpers/date'

const initialState = {
  adminInfo: createReduxRecordDetailState(),
  pricing: createReduxRecordListState(),
  updatedInfoResult: '',
  updateAdminLoading: false
}

export const fetchAdminData = createAsyncThunk(
  'fetchAdminData',
  async (_, thunkAPI) => {
    try {
      const response = await getAdminDetail()

      let data = response.data

      return data
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const updateAdminInfo = createAsyncThunk(
  'updateAdminInfo',
  async (data, thunkAPI) => {
    try {
      const response = await patchAccountsWithData(data)

      return response.status
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

export const fetchPricing = createAsyncThunk(
  'fetchPricing',
  async ({ page = 1, size = 50 }, thunkAPI) => {
    try {
      const response = await getPricingList(page, size)

      const data = response.data
      const rawHeader = response.headers
      const pagination = extractPagination(rawHeader)

      return { data, pagination }
    } catch (error) {
      const errorData = getAPIRequestError(error)

      // console.log('errorData', errorData)

      return thunkAPI.rejectWithValue({ error: errorData })
    }
  }
)

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetAdminPageState: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchAdminData.pending, state => {
      state.adminInfo.loading = true
    })
    builder.addCase(fetchAdminData.fulfilled, (state, { payload }) => {
      const companyAddress = `${payload?.billingStreet ?? ''} ${
        payload?.billingCity ?? ''
      } ${payload?.billingState ?? ''} ${payload?.billingPostCode ?? ''} ${
        payload?.billingCountry ?? ''
      }`

      state.adminInfo.loading = false
      state.adminInfo.record = {
        ...payload,
        companyAddress
      }
      state.adminInfo.statusCode = 200
    })
    builder.addCase(fetchAdminData.rejected, (state, { payload }) => {
      state.adminInfo.loading = false
      state.adminInfo.record = null
      state.adminInfo.error = payload.error
      state.adminInfo.statusCode = payload.statusCode
    })

    builder.addCase(fetchPricing.pending, state => {
      state.pricing.loading = true
    })
    builder.addCase(fetchPricing.fulfilled, (state, { payload }) => {
      state.pricing.loading = false
      state.pricing.records = updateReduxRecords(
        state.pricing.records,
        payload.data.map(item => {
          return {
            ...item,
            priceLow: parseInt(item.priceRange.split(' ')[0]),
            priceHigh: parseInt(item.priceRange.split(' ')[2])
          }
        }),
        payload.pagination?.page
      )
      state.pricing.page = payload.pagination?.page
      state.pricing.size = payload.pagination?.size
      state.pricing.total = payload.pagination?.total
      state.pricing.totalPages = payload.pagination?.totalPages
      state.pricing.error = null
    })
    builder.addCase(fetchPricing.rejected, (state, { payload }) => {
      state.pricing.loading = false
      state.pricing.records = []
      state.pricing.error = payload?.error.message
    })

    //patch admin info data
    builder.addCase(updateAdminInfo.pending, state => {
      state.updateAdminLoading = true
    })
    builder.addCase(updateAdminInfo.fulfilled, (state, { payload }) => {
      state.updateAdminLoading = true
      state.updatedInfoResult = payload
    })
    builder.addCase(updateAdminInfo.rejected, (state, { payload }) => {
      state.updateAdminLoading = true
      state.updatedInfoResult = payload?.error.message
    })
  }
})

export const { resetAdminPageState } = admin.actions

export default admin.reducer

export const getAdminData = createSelector(
  state => state.admin.adminInfo,
  adminInfo => adminInfo
)

export const getUpdateInfoData = createSelector(
  state => state.admin.updatedInfoResult,
  updatedInfoResult => updatedInfoResult
)

export const getPricingData = createSelector(
  state => state.admin.pricing,
  pricing => pricing
)
