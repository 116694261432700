import {
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_SECTION,
  // INPUT_TYPE_EMAIL,
  // INPUT_TYPE_PASSWORD,
  INPUT_TYPE_TEXT
} from '../../../constants'

export default {
  section_0: {
    label: 'Job Details',
    inputType: INPUT_TYPE_SECTION
  },
  customerName: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Customer Name',
      type: INPUT_TYPE_TEXT,
      readOnly: true
    },
    value: ''
  },
  contractRef: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Contract #',
      type: INPUT_TYPE_TEXT,
      readOnly: true
    },
    value: ''
  },
  activeJobType: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Active Job Type',
      type: INPUT_TYPE_TEXT,
      readOnly: true
    },
    value: ''
  },
  jobTypeUponCreation: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Job Type Upon Creation',
      type: INPUT_TYPE_TEXT,
      readOnly: true
    },
    value: ''
  },
  section_1: {
    label: 'Payments',
    inputType: INPUT_TYPE_SECTION
  },
  payments: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Payment',
      type: INPUT_TYPE_TEXT,
      readOnly: true,
      fixedTextLeft: '$'
    },
    value: ''
  },
  paymentRequiredAtCreation: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Payment Required at Creation',
      type: INPUT_TYPE_TEXT,
      readOnly: true,
      fixedTextLeft: '$'
    },
    value: ''
  },
  currentArrears: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Current Arrears',
      type: INPUT_TYPE_TEXT,
      readOnly: true,
      fixedTextLeft: '$'
    },
    value: ''
  },
  promiseToPayArranged: {
    inputType: INPUT_TYPE_TEXT,
    inputProps: {
      label: 'Promise to Pay Arranged',
      type: INPUT_TYPE_TEXT,
      readOnly: true
    },
    value: ''
  },
  section_2: {
    label: 'Pricing',
    inputType: INPUT_TYPE_SECTION
  },
  costToClientToDate: {
    inputType: INPUT_TYPE_NUMBER,
    inputProps: {
      label: 'Cost to Date',
      type: INPUT_TYPE_TEXT,
      readOnly: true,
      fixedTextLeft: '$'
    },
    value: ''
  }
}
