import {
  GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_COUNTRY,
  GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_POSTCODE,
  GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_ROUTE,
  GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_STATE,
  GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_STREET,
  GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_SUBURB
} from '../constants/googleMap'

const extractAddressComponentData = addressComponents => {
  const addressComponentData = addressComponents.reduce(
    (prev, addressComponent) => {
      const { long_name, types } = addressComponent

      if (types.includes(GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_STREET)) {
        if (prev.street) {
          return prev
        }
        return { ...prev, street: long_name }
      }

      if (types.includes(GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_ROUTE)) {
        let street = prev.street

        if (street) {
          street = `${street} ${long_name}`
        } else {
          street = long_name
        }

        return { ...prev, street }
      }

      if (types.includes(GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_SUBURB)) {
        return { ...prev, suburb: long_name }
      }

      if (types.includes(GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_STATE)) {
        return { ...prev, state: long_name }
      }

      if (types.includes(GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_POSTCODE)) {
        return { ...prev, postcode: long_name }
      }

      if (types.includes(GOOGLE_MAP_ADDRESS_COMPONENT_TYPE_COUNTRY)) {
        return { ...prev, country: long_name }
      }

      return prev
    },
    { street: null, suburb: null, state: null, postcode: null, country: null }
  )

  return addressComponentData
}

export const extractGooglePlaceInfo = (searchResult) => {
  const name = searchResult.name
  const formattedAddress = searchResult.formatted_address
  const latitude = searchResult.geometry.location.lat()
  const longitude = searchResult.geometry.location.lng()

  const addressData = extractAddressComponentData(
    searchResult.address_components
  )

  return {
    name,
    address: formattedAddress,
    addressString: formattedAddress,
    latitude,
    longitude,
    ...addressData
  }
}
