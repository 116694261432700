import React from 'react'
import { useSelector } from 'react-redux'
import { Image } from '../../components'
import { MultiFactorAuthPanel } from '../../containers'
import {
  getGlobalSettingLoadingStatus,
  getPortalLogoUrl
} from '../../redux/slices/global'
import {
  LoginSingleGridContainer,
  LoginGridItem,
  LoginContainer,
  LoginImageContainer
} from '../LoginPage/StyledLoginItems'

const MultiFactorAuthPage = () => {
  const appLogo = useSelector(getPortalLogoUrl)
  const globalLoading = useSelector(getGlobalSettingLoadingStatus)

  return (
    <LoginSingleGridContainer>
      <LoginGridItem>
        <LoginContainer>
          <LoginImageContainer>
            <Image
              width='100%'
              height='100%'
              src={appLogo}
              alt='Login'
              objectFit='contain'
              loading={globalLoading}
            />
          </LoginImageContainer>
          <MultiFactorAuthPanel />
        </LoginContainer>
      </LoginGridItem>
    </LoginSingleGridContainer>
  )
}

export default MultiFactorAuthPage
